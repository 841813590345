import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { first, switchMap, tap, map, take, share, shareReplay, catchError } from 'rxjs/operators';
import { IUserManagerService } from './iUserManager.service';
import { ICurrentUserService } from './currentUserService/icurrentuser.service';
import { User, UserClient } from './nswag/nswagclient';

import { UserRoles } from '../models/userRole';

@Injectable()
export class UserManagerService extends IUserManagerService{
    constructor(private _userClient: UserClient, private _currentUserService:ICurrentUserService) {
        super();
    }
    private _user$:Observable<User>;
    getCurrentUser():Observable<User>{
        if (this._user$) return this._user$; //of(new User("","","","","", false,"",false,false));// this._user$;
        let email = this._currentUserService.getUserEmail();
        if (email == null){
            return of(null);
        }
        this._user$ = this._userClient.addUserIfNeeded(email).pipe(shareReplay(1), catchError(err=>{console.error(err); return throwError(() => err);}));
        return this._user$;
    }
    isUserSuperAdmin():Observable<boolean>{
        return this.getCurrentUser().pipe(map(u=>{
            return u.isGlobalAdmin;
        }));
    }

    getIsEditorOrAdmin():Observable<boolean> {
        return this.getCurrentUser().pipe(map(u=>{
            if (u.isGlobalAdmin) return true;
            if (u.companyRoles) {
                u.companyRoles.forEach(r => {
                    if (r.role === UserRoles.admin || r.role === UserRoles.editor) {
                      return true;
                    }
                });
            }
            return false;
        }));
      }
}