"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var list_1 = require("./list");
var Enumerable = /** @class */function () {
  function Enumerable() {}
  /**
   * Generates a sequence of integral numbers within a specified range.
   */
  Enumerable.Range = function (start, count) {
    var result = new list_1.default();
    while (count--) {
      result.Add(start++);
    }
    return result;
  };
  /**
   * Generates a sequence that contains one repeated value.
   */
  Enumerable.Repeat = function (element, count) {
    var result = new list_1.default();
    while (count--) {
      result.Add(element);
    }
    return result;
  };
  return Enumerable;
}();
exports.default = Enumerable;
