import { Component, Inject, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ICompanyService, IOpsViewerMessagesService  } from 'src/app/services/services';
import { firstValueFrom } from 'rxjs';
import { ConsumerJob, IConsumerJobClient, IVendorJobClient } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-job-linker-admin',
  templateUrl: './job-linker-admin.component.html',
  styleUrls: ['./job-linker-admin.component.css']
})
export class JobLinkerAdminComponent implements OnInit {

  ready = false;
  companySelectItems: SelectItem[] = [];
  vendorJobSelectItems: SelectItem[] = [];
  consumerJobs: ConsumerJob[] = [];

  constructor(
    private _companyService: ICompanyService,
    @Inject("IVendorJobClient")  private _vendorJobService: IVendorJobClient,
    @Inject("IConsumerJobClient") private _consumerJobService: IConsumerJobClient,
    private _messageService: IOpsViewerMessagesService) { }

  async ngOnInit() {
    await this.getCompanies().then(results => { this.companySelectItems = results; });
    await this.getVendorJobs().then(results => { this.vendorJobSelectItems = results; });
    await this.getConsumerJobs().then(results => { this.consumerJobs = results; });
    this.ready = true;
  }

  async getCompanies(): Promise<SelectItem[]> {
    const companySelectItems: SelectItem[] = [];
    try {
      const companiesFound = await firstValueFrom(this._companyService.getCompanyNames());
      if (companiesFound) {
        companiesFound.map(c => {
          companySelectItems.push({ label: c.companyName, value: c.companyId });
        });
      }
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get Companies for Job Linker Administration');
    }
    return companySelectItems;
  }

  async getVendorJobs(): Promise<SelectItem[]> {
    const vendorJobSelectItems: SelectItem[] = [];
    try {
      const jobsFound = await firstValueFrom(this._vendorJobService.getVendorJobs());
      if (jobsFound) {
        jobsFound.map(v => {
          vendorJobSelectItems.push({ label: v.name, value: v.id });
        });
      }
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get Service Provider Jobs for Job Linker Administration');
    }
    return vendorJobSelectItems;
  }

  async getConsumerJobs(): Promise<ConsumerJob[]> {
    let consumerJobs: ConsumerJob[] = [];
    try {
      consumerJobs = await firstValueFrom(this._consumerJobService.getConsumerJobs(null));
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get Operator Jobs for Job Linker Administration');
    }
    return consumerJobs;
  }
}
