import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FilelistComponent } from '../../../components/files/filelist/filelist.component';

@Component({
  selector: 'app-file-pop-up',
  
  standalone: false,
  templateUrl: './file-pop-up.component.html',
  styleUrls: ['./file-pop-up.component.css']
})
export class FilePopUpComponent {
  jobId:string;
  constructor(dialogConfig:DynamicDialogConfig ) { 
    this.jobId = dialogConfig?.data?.jobId;
    console.log(dialogConfig);
  }

}
