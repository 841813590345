import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IUserService,
  ICompanyService,
  IFeaturesService,
  IOpsViewerMessagesService,
  IApplicationContextService
} from '../../../services/services';
//import { CompanyName } from '../../../models/models';
import { Title } from '@angular/platform-browser';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ConfirmationService, Message, SelectItem } from 'primeng/api';
import { CompanyRole, User } from 'src/app/services/nswag/nswagclient';
import { CompanyName, UserRoles } from 'src/app/models/models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'user-detail',
  
  standalone: false,
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  public user: User;
  protected isNew :boolean = false;
  public userEmailValid = true;


  public companies: SelectItem[] = [];
  public selectedCompanies: string[] = [];

  public roles: SelectItem[] = [];


  public messages: Message[] = [];
  public modelInvalid = false;
  public showModelInvalidDialog = false;

  public saving = false;

  protected $rolesToDisplay: Observable<CompanyRole[]>;
  protected $user: Observable<User>;
  private $companies: Observable<SelectItem[]>;
  private $authenticatedUser: Observable<User>;
  protected $canAddCompanyRole: Observable<boolean>;
  protected $isSuperAdmin: Observable<boolean>;
  protected $addRoleAction: ReplaySubject<number> = new ReplaySubject();
  private $companyContext: Observable<CompanyName>;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: IUserService,
    private _userManagerService: IUserManagerService,
    private _companyService: ICompanyService,
    private _featureService: IFeaturesService,
    private _titleService: Title,
    private _messageService: IOpsViewerMessagesService,
    private _applicationContextService: IApplicationContextService,
    private _confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this._applicationContextService.hideApplicationRequestsBar();
    this.getRoles();
    this.$companies = this._companyService.getCompanies().pipe(map(c => {
      return c.map<SelectItem>(r => {
        return {
          label: r.name,
          value: r.id
        }
      }
      );
    }));
    //this.$companies.subscribe(r=>console.warn(r));
    this.$authenticatedUser = this._userManagerService.getCurrentUser();
    this.$companyContext = this._applicationContextService.companyContextObserver;
    this.$user = this.getUser();
    this.$rolesToDisplay = combineLatest([this.$user, this.$authenticatedUser, this.$addRoleAction]).pipe(map(r => {
      return this.filterCompanyRoles(r[1], r[0]);
    }), shareReplay(1));
    this.$addRoleAction.next(234); //inital fire for combine to work above

    this.$canAddCompanyRole = this.$authenticatedUser.pipe(switchMap(u => {
      return this.$companyContext.pipe(map(c => {
        if (c &&
          this.isAuthenticatedUserACompanyAdmin2(u, c.companyId) &&
          (!this.user.companyRoles || !this.user.companyRoles.some(r => r.companyId === c.companyId))
        ) {
          return true;
        }
        return false;
      }));

    }));



    let isEditSelf$ = combineLatest([this.$user, this.$authenticatedUser]).pipe(map(u=>{
      return u[0].id == u[1].id
    }));

    isEditSelf$.pipe(first()).subscribe(e=>{
      if (e){
        this._applicationContextService.showApplicationRequestsBar();
      }
    });


  }

  getUser():Observable<User> {
    return this._route.params.pipe(switchMap(params => {
      let userId = params['id'];
      if (!userId || userId.toUpperCase() === 'NEW') {
        this.isNew = true; //side effect here I don't like
        return this.getNewUserFromCompanyContext();
      } else {
        return this._userService.getUser(userId);
      }
    }), switchMap(u => {
      return this.$companies.pipe(map(companies => {
        UserDetailComponent.removeRolesForInvalidCompanies(u, companies);
        console.warn(u);
        return u;
      }));
    }), tap(u => { 
      this.user = u;
      this.setPageTitle(u.email);
    }), shareReplay(1));
  }

  getNewUserFromCompanyContext(): Observable<User> {
    return this.$companyContext.pipe(map(c => {
      let user = new User();
      user.companyRoles = [];
      //this.isNew //side effect;
      if (c) { //TODO: stuff here
        const newRole = new CompanyRole();
        newRole.companyId = c.companyId;
        newRole.role = UserRoles.reader;
        user.companyRoles.push(newRole);
      }
      return user;
    }));
  }


  ngOnDestroy() {
    this._applicationContextService.hideApplicationRequestsBar();
  }

  setPageTitle(userId: string) {
    if (!userId || userId === 'New' || userId === '') {
      this._titleService.setTitle('New User');
    } else { this._titleService.setTitle(`edit ${userId}`); }
  }


  private static removeRolesForInvalidCompanies(user: User, companies: SelectItem[]) {

    const validCompanyIds = companies.map(c => c.value);
    user.companyRoles = user.companyRoles.filter(r => validCompanyIds.includes(r.companyId));
  }


  //#region Company Role Management
  /*
  Rules for displaying which roles can be seen and edited
    1.  If Authenticated User is viewing self or is Global Admin,
        view all roles in read only mode (doesn't matter company context setting)
    2.  If Authenticated User is Company Admin,
        a. Show only role applicable to current company context.
        b. CompanyId is read only, but role value can be edited.
        c. If role does not exist for current company context, Add new role option is available which follows same
           rules in b once added.
        d. Has ability to remove company role from user.
    3.  Any other Authenticated User cannot view any role information of the user
  */

  getRoles() {
    this.roles.push({ label: UserRoles.admin, value: UserRoles.admin, icon: 'fa fa-shield' });
    this.roles.push({ label: UserRoles.editor, value: UserRoles.editor, icon: 'far fa-edit' });
    this.roles.push({ label: UserRoles.reader, value: UserRoles.reader, icon: 'fa fa-book' });
  }


  isAuthenticatedUserACompanyAdmin2(authenticatedUser: User, companyId: string): boolean {
    //console.warn('asdf',authenticatedUser);
    if (!authenticatedUser.companyRoles) { return false; }
    return authenticatedUser.isGlobalAdmin ||
      authenticatedUser.companyRoles.some(r => r.companyId === companyId && r.role === UserRoles.admin);
  }


  

  addDisplayedCompanyRole() {
    console.warn('asdf');
    //if (this.companyContext) {

    let c = this._applicationContextService.getCompanyContext();

    console.warn(c);
    if (!this.user.companyRoles.some(r => r.companyId === c.companyId)) {
      const newRole = new CompanyRole();
      newRole.companyId = c.companyId;
      newRole.role = UserRoles.reader;
      this.user.companyRoles.push(newRole);

    } else {
      this._messageService.warnMessage('Current selected Company role already exists on this user', '');
    }
    this.$addRoleAction.next(234);



    //}
  }

  deleteRole(role: CompanyRole) {
    if (this.user.companyRoles) {
      this.user.companyRoles = this.user.companyRoles.filter(r => r.companyId !== role.companyId);
    } else {
      this.user.companyRoles = [];
    }
    this.$addRoleAction.next(234);
  }


  //#endregion


  validateEmail() {
    // tslint:disable-next-line:max-line-length
    this.userEmailValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.user.email);
  }

  validateUserModel(): boolean {
    if (!this.user.email || !this.userEmailValid) { return false; }
    if (!this.user.firstName) { return false; }
    if (!this.user.lastName) { return false; }
    // if(!this.user.contactNumber) return false;
    if (this.user.active == null) { return false; }

      if (!this.user.companyRoles) {
        this.user.companyRoles = [];
      }
      this.user.companyRoles.map(r => {
        if (!r.companyId || !r.role) { return false; }
      });
    

    return true;
  }

  saveUser() {
    if (this.user.active === undefined || this.user.active == null) { this.user.active = false; }
    if (this.user.isEmployee === undefined || this.user.isEmployee == null) { this.user.isEmployee = false; }

    this.showModelInvalidDialog = this.modelInvalid = !this.validateUserModel();

    if (!this.showModelInvalidDialog) {
      this.saving = true;
      if (this.isNew) {
        if (this.user.isEmployee == null || this.user.isEmployee === undefined) { this.user.isEmployee = false; }
        if (this.user.hasAllCompany == null || this.user.hasAllCompany === undefined) { this.user.hasAllCompany = false; }
        this._userService.createUser(this.user).subscribe(
          result => {
            this._applicationContextService.alertUserDetailsUpdated();
            this.navigateAfterCreateOrUpdate();
          },
          error => {
            this._messageService.errorMessage(error, 'Could not get user');
            this.saving = false;
          }
        );
      } else {
        this._userService.updateUser(this.user.id, this.user).subscribe(
          result => {
            this._applicationContextService.alertUserDetailsUpdated();
            this.navigateAfterCreateOrUpdate();
          },
          error => {
            this._messageService.errorMessage(error, 'Could not get user');
            this.saving = false;
          }
        );
      }
    }
  }

  closeInvalidModelDialog() {
    this.showModelInvalidDialog = false;
  }

  navigateAfterCreateOrUpdate() {

    this.$authenticatedUser.pipe(first()).subscribe(u=>{
      if (this.user.id === u.id) {
        this.navigateHome();
      } else {
        this.returnToUserList();
      }
    });

    
  }
  returnToUserList() {
    this._router.navigate(['/users/']);
  }

  navigateHome() {
    this._router.navigate(['/home']);
  }

  isSuperAdmin2(): Observable<boolean> {
    return this.$authenticatedUser.pipe(map(u => u.isGlobalAdmin));
  }

  confirmDelete() {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete ' + this.user.firstName + ' ' + this.user.lastName + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteUser(this.user.id); }// ,
        // reject: () => { }
      }
    );
  }

  deleteUser(g) {

    if (this.isNew) {
      this._router.navigate(['/users']);
      return;
    }

    this._userService.deleteUser(this.user.id).subscribe(
      result => {
        this._messageService.infoMessage('User deleted', '');
        this._applicationContextService.alertUserDetailsUpdated();
        this._router.navigate(['/users']);
      },
      error => {
        this._messageService.errorMessage(error, 'Could not get user');
        this.saving = false;
      }
    );
  }

  filterCompanyRoles(authenticatedUser: User, user: User): CompanyRole[] {
    if (!user || !user.companyRoles || !authenticatedUser) {
      return [];
    }

    if (authenticatedUser.isGlobalAdmin || authenticatedUser.id === user.id) {
      return user.companyRoles;
    }

    if (!authenticatedUser.companyRoles) {
      return [];
    }

    return user.companyRoles.map(r => {
      if (authenticatedUser.companyRoles.some(ar => ar.companyId === r.companyId && ar.role === UserRoles.admin)) {
        return r;
      }
    });
  }
}
