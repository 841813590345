import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { PlugTypes } from 'src/app/models/models';
import { PlugSet, Plug } from 'src/app/services/nswag/nswagclient';
import { IImportPlugDataService } from 'src/app/services/services';

@Component({
  selector: 'app-plugs',
  templateUrl: './plugs.component.html',
  styleUrls: ['./plugs.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PlugsComponent),
    multi: true
  }]
})
export class PlugsComponent implements OnInit, ControlValueAccessor {

  private _onTouched: Function;

  public plugTypeOptions: SelectItem[] = [
    { label: PlugTypes.cfp, value: PlugTypes.cfp },
    { label: PlugTypes.dfp, value: PlugTypes.dfp },
    { label: PlugTypes.bridgeKill, value: PlugTypes.bridgeKill }
  ];


  public plugsToAddModel: AddPlugsModel = new AddPlugsModel();
  public addPlugsModelInvalid = false;

  @Input() _plugSets: PlugSet[] = [];
  get plugSets() {
    return this._plugSets;
  }
  set plugSets(val) {
    this._plugSets = val;
    this.propagateChange(this._plugSets);
  }
  @Input() disabled = false;

  private _clipboardContent: string;

  constructor(
    private _confirmationService: ConfirmationService,
    private _importDataService: IImportPlugDataService) { }

  ngOnInit() {
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    if (obj) {
      this.plugSets = obj;
    } else {
      this.plugSets = [];
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /****** Componet UI control methods ******/
  addPlugSet() {
    this.addPlugsModelInvalid = !this.plugsToAddModel.isModelValid();

    if (!this.addPlugsModelInvalid) {
      const plugSetToAdd = new PlugSet();
      plugSetToAdd.manufacturer = this.plugsToAddModel.manufacturer;
      plugSetToAdd.model = this.plugsToAddModel.model;
      plugSetToAdd.type = this.plugsToAddModel.type;

      const totalPlugs = this.getTotalPlugs();
      const plugsToAdd: Plug[] = [];
      for (let i = 1; i <= this.plugsToAddModel.count; i++) {
        const plugToAdd = new Plug();
        plugToAdd.number = totalPlugs + i;
        plugsToAdd.push(plugToAdd);
      }
      plugSetToAdd.plugs = plugsToAdd;

      this.plugSets.push(plugSetToAdd);

      this.plugsToAddModel.reset();
    }
  }

  getTotalPlugs(): number {
    let count = 0;
    this.plugSets.map( p => {
      if (p.plugs) {
        count = count + p.plugs.length;
      }
    });
    return count;
  }

  addPlugToPlugSet(plugSet: PlugSet) {
    const newPlug = new Plug();
    const currentPlugNumber = this.getHighestPlugNumber(plugSet);
    newPlug.number = currentPlugNumber + 1;

    this.plugSets.map(p => {
      if (this._importDataService.getGroupName(p) === this._importDataService.getGroupName(plugSet)) {
        p.plugs.push(newPlug);
      } else {
        p.plugs.map(plug => {
          if (plug.number >= newPlug.number) {
            plug.number ++;
          }
        });
      }
    });

  }

  confirmDeletePlugSet(plugSet: PlugSet) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete Plug Number: ' + this._importDataService.getGroupName(plugSet) + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deletePlugSet(plugSet); }// ,
        // reject: () => { }
      }
    );
  }

  confirmDeletePlug(plugNumber: number) {
    if (!this.disabled) {
      this._confirmationService.confirm(
        {
          key: 'confirmationDialog',
          header: 'Delete Confirmation',
          message: 'Are you sure you want to delete Plug Number: ' + plugNumber + '?',
          icon: 'fa fa-question-circle',
          accept: () => { this.deletePlug(plugNumber); }// ,
          // reject: () => { }
        }
      );
    }
  }

  deletePlugSet(plugSet: PlugSet) {
    // Find which group to remove
    const targetGroupName = this._importDataService.getGroupName(plugSet);
    const plugSetToRemove = this.plugSets.find(p => this._importDataService.getGroupName(p) === targetGroupName);

    if (plugSetToRemove) {
      // Determine range of plug numbers to be removed
      const lowPlugNUmber = this.getLowestPlugNumber(plugSetToRemove);
      const highPlugNumber = this.getHighestPlugNumber(plugSetToRemove);
      const plugsRemovedCount = plugSetToRemove.plugs ? plugSetToRemove.plugs.length : 0;

      // remove targeted Plug Set
      this.plugSets = this.plugSets.filter(p => this._importDataService.getGroupName(p) !== targetGroupName);

      // Updated plug numbers of other PlugSets
      this.plugSets.map(p => {
        p.plugs.map(plug => {
          if (plug.number > lowPlugNUmber) {
            plug.number = plug.number - plugsRemovedCount;
          }
        });
      });
    }
  }

  deletePlug(plugNumber: number) {
    this.plugSets.map(plugSet => {
      // Remove targeted plug
      plugSet.plugs = plugSet.plugs.filter(plug => plug.number !== plugNumber);

      // Renumber plug numbers greater than targeted plug
      plugSet.plugs.map(plug => {
        if (plug.number > plugNumber) {
          plug.number = plug.number - 1;
        }
      });
    });
  }

  getLowestPlugNumber(plugSet: PlugSet): number {
    if (plugSet.plugs && plugSet.plugs.length > 0) {
      let lowestNumber = plugSet.plugs[0].number;
      plugSet.plugs.map(plug => {
        if (plug.number < lowestNumber) {
          lowestNumber = plug.number;
        }
      });
      return lowestNumber;
    } else {
      return 0;
    }
  }

  getHighestPlugNumber(plugSet: PlugSet): number {
    if (plugSet.plugs && plugSet.plugs.length > 0) {
      let highestNumber = plugSet.plugs[0].number;
      plugSet.plugs.map(plug => {
        if (plug.number > highestNumber) {
          highestNumber = plug.number;
        }
      });
      return highestNumber;
    } else {
      return 0;
    }
  }

  importData() {
    this.plugSets = this._importDataService.autoChooseImportMethod(this.plugSets, this.plugsToAddModel.importData);
    this.plugsToAddModel.importData = null;
  }

}

/****** Helper Classes for this component ******/
export class AddPlugsModel {
  type: string;
  manufacturer: string;
  model: string;
  count: number;
  importData: string;

  constructor() {}

  isModelValid(): boolean {
    if (!this.type || this.type === '') {
      return false;
    }
    if (!this.manufacturer || this.manufacturer === '') {
      return false;
    }
    if (!this.model || this.model === '') {
      return false;
    }
    if (!this.count) {
      return false;
    }

    if (this.count <= 0 || this.count > 300) {
      return false;
    }

    return true;
  }

  reset() {
    this.type = null;
    this.manufacturer = null;
    this.model = null;
    this.count = null;
  }
}

