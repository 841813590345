import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { JobLinker } from 'src/app/models/models';
import { SelectItem } from 'primeng/api';
import { ConsumerJob } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-job-linker-edit',
  templateUrl: './job-linker-edit.component.html',
  styleUrls: ['./job-linker-edit.component.css']
})
export class JobLinkerEditComponent implements OnInit {

  @Input() jobLinkerToEdit: JobLinker = null;
  @Input() companySelectItems: SelectItem[] = [];
  @Input() vendorJobSelectItems: SelectItem[] = [];
  @Input() consumerJobs: ConsumerJob[] = [];
  @Input() jobTypes: SelectItem[] = [];
  @Input() statuses: SelectItem[] = [];
  @Output() jobLinkerAdded = new EventEmitter<JobLinker>();
  @Output() jobLinkerUpdated = new EventEmitter<JobLinker>();
  @Output() closeEdit = new EventEmitter();
  isNew = false;
  calendarStartTime: Date = null;
  consumerJobSelectItems: SelectItem[] = [];
  modelInvalid = false;
  ready = false;

  constructor() { }

  ngOnInit() {
    if (!this.jobLinkerToEdit) {
      this.isNew = true;
      this.jobLinkerToEdit = this.createNewJobLinker();
    }
    this.calendarStartTime = this.initializeCalendarDate(this.jobLinkerToEdit.consumerJobStartTime);
    this.consumerJobSelectItems = this.initializeConsumerJobSelectItems(this.consumerJobs);
    this.ready = true;
  }

  createNewJobLinker(): JobLinker {
    return new JobLinker();
  }

  initializeCalendarDate(startTime: Date): Date {
    if (startTime) {
      // Convert from UTC
      return new Date(startTime);
    }
    return new Date(Date.now());
  }

  initializeConsumerJobSelectItems(consumerJobs: ConsumerJob[]): SelectItem[] {
    const selectItems: SelectItem[] = [];
    if (consumerJobs) {
      consumerJobs.map(c => {
        selectItems.push({ label: c.number, value: c.id });
      });
    }
    return selectItems;
  }

  consumerJobSelected(consumerJobIdSelected: string) {
    const selectedConsumerJob = this.consumerJobs.find(c => c.id === consumerJobIdSelected);
    if (selectedConsumerJob) {
      this.calendarStartTime = this.initializeCalendarDate(selectedConsumerJob.startTime);
    }
  }

  saveJobLinker() {
    // Retain date from calendar
    this.jobLinkerToEdit.consumerJobStartTime = this.calendarStartTime;
    // Validate inputs
    this.modelInvalid = !this.isModelValid(this.jobLinkerToEdit);
    // Add or Update
    if (!this.modelInvalid) {
      if (this.isNew) {
        this.jobLinkerAdded.emit(this.jobLinkerToEdit);
      } else {
        this.jobLinkerUpdated.emit(this.jobLinkerToEdit);
      }
    }
  }

  isModelValid(jobLinker: JobLinker) {
    if (!jobLinker.consumerId) { return false; }
    if (!jobLinker.wellName &&  !jobLinker.apiNumber) { return false; }
    if (!jobLinker.vendorJobIds) { return false; }
    if (!jobLinker.jobType) { return false; }
    if (!jobLinker.consumerJobStartTime) { return false; }
    return true;
  }

  cancel() {
    this.closeEdit.emit();
  }

}
