import { SimulationFluidSummary, SimulationActivity, SimulationChemical, SimulationExpense } from "src/app/models/simulation";
import { SimulationCommon, InitialActivityName, OptionalActivityNames } from './simulationCommon';
import { CasingTubings, PlugSet, WellInfo } from "src/app/services/nswag/nswagclient";

export class SimulationCalculations {
    static async calculateFields(fluidSummary: SimulationFluidSummary, wellInfo: WellInfo, seqActivities: SimulationActivity[] = []) : Promise<SimulationActivity[]> {
        let activities = [...seqActivities];
        if(!fluidSummary || !wellInfo) {
            return activities;
        }
        let currentDepth = 0.0;
        let relativeTime = 0.0;
        let prevFlowbackBbls = 0.0;
        let prevPumpedBbls = 0.0;

        activities.forEach(act => {
            act.duration = SimulationCalculations.calculateDuration(act);
            
            act.calcAv = SimulationCalculations.calculateAnnularVelocity(wellInfo, act.returnRate);
            act.calcRe = SimulationCalculations.calculateReynoldsNumber(fluidSummary, wellInfo, act.returnRate);

            if(act.activity === 'Short Trip') {
                act.isDepthStart = true;
                if(act.depthStart && act.pipeSpeed) { //act.depthStart indicates the short trip depth (distance)
                    act.duration = act.depthStart / act.pipeSpeed / 60 * 2;
                    act.calcPumpedBbls = SimulationCalculations.calculateBarrels(act.duration, act.circulationRate, prevPumpedBbls);
                    act.calcFlowbackBbls = SimulationCalculations.calculateBarrels(act.duration, act.returnRate, prevFlowbackBbls);
                }
                else {
                    act.calcPumpedBbls = 0;
                    act.calcFlowbackBbls = 0;
                }
            }
            else {
                currentDepth = SimulationCalculations.getDepth(currentDepth, act.isDepthStart, act.depthStart, wellInfo.plugSets);
                act.calcPumpedBbls = SimulationCalculations.calculateBarrels(act.duration, act.circulationRate, prevPumpedBbls);
                act.calcFlowbackBbls = SimulationCalculations.calculateBarrels(act.duration, act.returnRate, prevFlowbackBbls);
            }

            act.calcDepth = currentDepth = SimulationCalculations.getDepth(currentDepth, act.isDepthEnd, act.depthEnd, wellInfo.plugSets);
            // act.calcPlugNumber = SimulationCommon.getPlugNumberFromDepth(currentDepth, wellInfo.plugSets);
            act.calcPlugNumber = SimulationCalculations.calcPlugs(act, activities);
            act.calcRelativeTime = relativeTime = relativeTime + (act.duration || 0);
            prevPumpedBbls = act.calcPumpedBbls;
            prevFlowbackBbls = act.calcFlowbackBbls;

            if (act.activity === InitialActivityName.PoohLateral) {
                act.depthStart = SimulationCalculations.calcDepthStartForPoohLateral(seqActivities, wellInfo.plugSets);
                act.depthEnd = SimulationCalculations.calcDepthEndForPoohLateral(act);
                act.calcDepth = act.depthEnd;
            }
            if (act.activity === InitialActivityName.PoohVertical) {
                act.depthStart = SimulationCalculations.calcDepthStartForPoohVertical(seqActivities);
                act.depthEnd = 0;
                act.calcDepth = act.depthEnd;
                act.duration = SimulationCalculations.calcDurationForPoohVertical(act);
            }
        });
        
        return activities;
    }

    /*Annular Velocity calculation
        csg_id (Casing Inner Diameter) === Well Information -> Casing -> ID
        ct_od (Coiled Tubing [External] Diameter) === Well Information -> Production Tubing -> OD
        fb === Called Flowback (BPM) */
    static calculateAnnularVelocity(wellInfo: WellInfo, fb: number) : number {
        if(!wellInfo || !fb){
            return 0;
        }
        let csg_id: number = SimulationCalculations.getCasingInnerDiameter(wellInfo.casingTubings);
        let ct_od: number = SimulationCalculations.getTubingOuterDiameter(wellInfo);
        if(!csg_id || !ct_od) {
            return 0;
        }
        const cubicFeetToBbls = 0.18;
        let annularCapacity = (Math.pow(csg_id / 24, 2) - Math.pow(ct_od / 24, 2)) * Math.PI * cubicFeetToBbls;
        if(annularCapacity < 0.0001) {
            return 0;
        }
        return fb / annularCapacity;
    }

    static calculateBarrels(durationHours: number, rateBblsPerMinute: number, initialCount: number = 0) : number {
        if(!durationHours || !rateBblsPerMinute) {
            return initialCount;
        }
        return durationHours * 60 * rateBblsPerMinute + initialCount;
    }

    static getCasingInnerDiameter(casingTubings: CasingTubings) : number {
        if(casingTubings) {
            if(casingTubings.casing1Horizontal) {
                return SimulationCalculations.getInnerDiameterFromCasingType(casingTubings.casing1Horizontal.type) || 0;
            }
        }
        return 0;
    }

    /*Calculate the chemical cost:
        1. Get Initial Depth (provided in the chemicals)
        2. Grab all activities from initial depth forward
        3. Get pumped barrels difference, depending on the System Type:
            SLW = pumped barrels (until the end)) / 10
            After tag = SUM( Tag activities followed by Milling => bbls difference)
            Before Short Trip = SUM( Short trip activities => Depth difference with the previous activity)
        4. Consider Open Loop vs Recirculated loop types
        5. multiply dosage amount * cost * pumped bbls difference
            SLW is divided by 10 because the dosage is expressed in gallons/10 bbls   */
    static calculateChemicalCost(
        chem: SimulationChemical,
        seq: SimulationActivity[],
        isRecirculated: boolean = false,
        recirculationBbls: number = 0,
        plugSets: PlugSet[]): number {
        if (!seq || !chem || !chem.chemicalFluidSystemId || !chem.cost || !chem.dosage) {
            return 0;
        }
        switch(chem.chemicalFluidSystemId) {
            case 'SLW': {
                let barrels = 0;
                barrels = seq
                    .filter(i => !chem.shutdownOnPooh || (chem.shutdownOnPooh && i.activity.indexOf('POOH') < 0))
                    .reduce((acc, j) => acc + (j.calcPumpedBbls || 0), 0);
                if(isRecirculated && recirculationBbls > 0 && recirculationBbls < barrels) {
                    barrels = recirculationBbls;
                }
                barrels = barrels / 10.0;
                if(isRecirculated && recirculationBbls && recirculationBbls < barrels) {
                    barrels = recirculationBbls;
                }
                return chem.dosage * chem.cost * barrels;
            }
            // TODO: How to get plug sets
            case 'SwpAfterTag': {
                const tags = SimulationCalculations.getNumberOfTags(chem.hasDepth, chem.depthOrPlug, seq, plugSets);
                return tags * chem.cost * chem.dosage;
            }
            case 'SwpBeforeShortTrip': {
                const shortTrips = SimulationCalculations.getNumberOfShortTrips(chem.hasDepth, chem.depthOrPlug, seq, plugSets);
                return shortTrips * chem.cost * chem.dosage;
            }
            default: {
                return 0;
            }
        }

    }

    /*Reynolds Number calculation 
        fluid_density === Fluid Information -> Density
        csg_id (Casing Inner Diameter) === Well Information -> Casing -> ID
        ct_od (Coiled Tubing [External] Diameter) === Well Information -> Production Tubing -> OD
        fb === Called Flowback (BPM) */
    static calculateReynoldsNumber(fluidSummary: SimulationFluidSummary, wellInfo: WellInfo, fb: number) : number {
        if(!fluidSummary || !wellInfo || !fb) {
            return 0;
        }
        let fluid_density: number = SimulationCalculations.getFluidDensity(fluidSummary);
        let csg_id: number = SimulationCalculations.getCasingInnerDiameter(wellInfo.casingTubings);
        let ct_od: number = SimulationCalculations.getTubingOuterDiameter(wellInfo);
        let vis: number = SimulationCalculations.getSlwViscosity(fluidSummary);
        if(!fluid_density || !csg_id || !ct_od || !vis || (vis - 26.0) == 0) {
            return 0;
        }
        let p1 = 3978.92 * fb * (csg_id - ct_od);
        let p2 = (csg_id / 2.0) * (csg_id / 2.0);
        let p3 = (ct_od / 2.0) * (ct_od / 2.0);
        if ((fluid_density) * (p2 - p3) == 0) {
            return 0;
        }
        return p1 / ((vis - 26.0) / (fluid_density) * (p2 - p3));
    }

    static calculateServiceCost(exp: SimulationExpense, act: SimulationActivity, plugCount: number = 0) : number {
        if(!exp || !exp.costPerFactor || !exp.expenseCostFactorId || !act) {
            return 0;
        }
        let factor = 0;
        switch(exp.expenseCostFactorId) {
            case 'PerDay': {
                factor = Math.ceil(act.calcRelativeTime / 24.0);
                break;
            }
            case 'PerQuarterDays': {
                factor = Math.ceil(act.calcRelativeTime / 6.0);
                break;
            }
            case 'PerHour': {
                factor = Math.ceil(act.calcRelativeTime);
                break;
            }
            case 'PerWell': {
                factor = 1
                break;
            }
            case 'PerRihFeet': {
                factor = act.calcDepth;
                break;
            }
            case 'PerBarrel': {
                factor = act.calcPumpedBbls;
                break;
            }
            case 'PerPlug': {
                factor = plugCount || 0;
                break;
            }
            default: {
                factor = 1;
            }
        }
        return exp.costPerFactor * factor;
    }

    static getFluidDensity(fluidSummary: SimulationFluidSummary) : number {
        return fluidSummary.fluidDensity || 0;
    }

    static getInnerDiameterFromCasingType(casingType: string): number {
        // Parse out Inner Diameter from string in format:
        // 4.5in OD, 9.5 lbs/ft, 4.09 ID, 3.965 in Drift
        // Number and decimal places of ID can change, so using comma separators
        if (!casingType) { return null; }
            const casingTypeArray = casingType.split(',');
        if (!casingTypeArray[2]) { return null; }
            const innerDiameter = casingTypeArray[2];
        innerDiameter.trim();
        innerDiameter.replace(' ID', '');
        innerDiameter.replace(' id', '');
        innerDiameter.replace('ID', '');
        innerDiameter.replace('id', '');
        return parseFloat(innerDiameter);
    }

    static getDepth(currentDepth: number, isDepth: boolean = true, value: number, plugSets: PlugSet[] = []) : number {
        if(!value || value <= 0) {
            return currentDepth;
        }
        return isDepth ? value : SimulationCommon.getPlugDepth(currentDepth, plugSets, value);
    }

    static getNumberOfTags(
        startAtGivenInDepth: boolean,
        startAtValue: number,
        acitivities: SimulationActivity[],
        plugSets: PlugSet[] = []): number {
        const firstActivityDepth = SimulationCalculations.getDepthToStartCosts(startAtGivenInDepth, startAtValue, plugSets);

        let numberOfTags = 0;
        acitivities.map(a => {
            let currentDepth = a.depthStart;
            if (!a.isDepthStart) {
                currentDepth = SimulationCommon.getStartingDepthForPlugNumber(plugSets, a.depthStart);
            }
            if (currentDepth >= firstActivityDepth && a.activity === 'Tag') {
                numberOfTags ++; }
        });

        return numberOfTags;
    }

    static getNumberOfShortTrips(
        startAtGivenInDepth: boolean,
        startAtValue: number,
        acitivities: SimulationActivity[],
        plugSets: PlugSet[] = []): number {
        const firstActivityDepth = SimulationCalculations.getDepthToStartCosts(startAtGivenInDepth, startAtValue, plugSets);

        let numberOfShortTrips = 0;
        acitivities.map(a => {
            let currentDepth = a.depthStart;
            if (!a.isDepthStart) {
                currentDepth = SimulationCommon.getStartingDepthForPlugNumber(plugSets, a.depthStart);
            }
            if (currentDepth >= firstActivityDepth && a.activity === 'Short Trip') {
                numberOfShortTrips ++; }
        });

        return numberOfShortTrips;
    }

    static getDepthToStartCosts(startAtGivenInDepth: boolean, startAtValue: number, plugSets: PlugSet[] = []): number {
        let firstActivityDepth = 0;
        if (startAtGivenInDepth) {
            firstActivityDepth = startAtValue;
        } else {
            firstActivityDepth = SimulationCommon.getPlugDepth(0, plugSets, startAtValue);
        }
        return firstActivityDepth;
    }

    static getBblsPumpedDifference(firstActivity: string, secondActivity: string, activities: SimulationActivity[]) {
        if(activities.length < 2) {
            return 0;
        }
        let accBbls = 0;
        for(let i = 0; i < activities.length - 1; i++) {
            if((activities[i].activity === firstActivity || firstActivity === 'Any')
                && (activities[i + 1].activity === secondActivity || secondActivity === 'Any')) {
                accBbls = accBbls + Math.abs(activities[i + 1].calcPumpedBbls - activities[i].calcPumpedBbls);
            }
        }
        return accBbls;
    }

    static getSlwViscosity(fluidSummary: SimulationFluidSummary) : number {
        return fluidSummary.slwViscosity || 0;
    }

    static getTubingOuterDiameter(wellInfo: WellInfo) : number {
        if(wellInfo.stringType) {
            if(wellInfo.stringType == 'Coiled Tubing') {
                return wellInfo.ctWoTubingOd || 0;
            }
            else {
                return wellInfo.hozTubingOd || 0;
            }
        }
        return 0;
    }

    static calculateDuration(act: SimulationActivity): number {
        let duration = act && act.duration ? act.duration : 0.25; // Is default duration set somewhere already to lookup?
        if (this.hasDepthStart(act)) {
          if (act && act.pipeSpeed && act.depthEnd && (act.depthStart != null)) { // depthStart can be 0
            // Formula: duration [hr] = abs(depthEnd - depthStart) [ft] / pipeSpeed [ft/min] / 60 [min/hr]
            duration = Math.abs(act.depthEnd - act.depthStart) / act.pipeSpeed / 60;
          }
        }
        return duration;
    }

    static hasDepthStart(act: SimulationActivity): boolean {
        if (act && act.activity) {
          if (act.activity === InitialActivityName.FirstTrip
            || act.activity === InitialActivityName.FromKop
            || act.activity === InitialActivityName.From30Degree
            || act.activity === InitialActivityName.From60Degree
            || act.activity === OptionalActivityNames.Pickup
            || act.activity === OptionalActivityNames.WeightCheck
            || act.activity === OptionalActivityNames.Milling) {
              return true;
            }
        }
        return false;
    }

    static calcDepthStartForPoohLateral(seq: SimulationActivity[], plugSets: PlugSet[]): number {
        const deepestActivity = SimulationCalculations.getDeepestActivity(seq, plugSets);
        if (deepestActivity) {
            if (deepestActivity.isDepthStart) {
                return deepestActivity.depthEnd;
            } else {
                return SimulationCommon.getStartingDepthForPlugNumber(plugSets, deepestActivity.depthEnd);
            }
        }
        return 0;
    }

    static calcDepthEndForPoohLateral(act: SimulationActivity): number {
        if (act && act.depthStart && act.pipeSpeed && act.duration) {
            return Math.round(act.depthStart - (act.pipeSpeed * 60) * act.duration); // going in reverse direction
        }
        return 0;
    }

    static calcDepthStartForPoohVertical(seq: SimulationActivity[]): number {
        const poohLateral = seq.find(s => s.activity === InitialActivityName.PoohLateral);
        if (poohLateral) {
            return poohLateral.depthEnd;
        }
        return 0;
    }

    static calcDurationForPoohVertical(act: SimulationActivity): number {
        if (act && act.depthStart && (act.depthEnd != null) && act.pipeSpeed) {
            return (act.depthStart - act.depthEnd) / act.pipeSpeed / 60;
        }
        return 0;
    }

    static getDeepestActivity(seq: SimulationActivity[], plugSets: PlugSet[]): SimulationActivity {
        if (!seq || seq.length < 1) {
            return null;
        }
        let deepestActivity = seq[0];
        let index = 1;
        seq.map(s => {
            if (index > 1 && s.activity !== InitialActivityName.PoohLateral && s.activity !== InitialActivityName.PoohVertical) {
                if (SimulationCalculations.isActivityDeeper(s, deepestActivity, plugSets)) {
                    deepestActivity = s;
                }
            }
            index++;
        });
        return deepestActivity;
    }

    // Returns True if Activity 1 is deeper than Activity2
    static isActivityDeeper(act1: SimulationActivity, act2: SimulationActivity, plugSets: PlugSet[]): boolean {
        if (!act1 || !act2) {
            return false;
        }
        const act1Depth = act1.isDepthStart ? act1.depthStart : SimulationCommon.getStartingDepthForPlugNumber(plugSets, act1.depthStart);
        const act2Depth = act2.isDepthStart ? act2.depthStart : SimulationCommon.getStartingDepthForPlugNumber(plugSets, act2.depthStart);

        return act1Depth > act2Depth;
    }

    static calcPlugs(activity: SimulationActivity, seqActivities: SimulationActivity[]): number {
        if (!activity || activity.isDepthStart) {
            return SimulationCommon.getPlugNumberFromTagCount(activity, seqActivities);
        }
        return activity.depthStart;
    }

}
