import { Component, OnInit, forwardRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputMask } from 'primeng/inputmask';
import { Address } from 'src/app/models/models';
import { InputMaskModule } from 'primeng/inputmask';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-address',
  
  standalone: false,
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AddressComponent),
    multi: true
  }]
})
export class AddressComponent implements OnInit, ControlValueAccessor {

  private _onTouched: Function;
  public isDisabled: boolean;

  @Input() _address = new Address();
  get address() {
    return this._address;
  }
  set address(val) {
    this._address = val;
    this.propagateChange(this._address);
  }
  @Input() displayModelInvalid = false;

  @Output() emailValid = new EventEmitter();

  @ViewChild('zip', { static: true }) zipInput: InputMask;
  emailInputValid = true;
  
  constructor() { }

  ngOnInit() {
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    if (obj) {
      this.address = obj;
    } else {
      this.address = new Address();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validateZip(){
    if(!this.zipInput.filled) {
      this.address.zip = null;// = { attributeValue: '' };
    }
  }

  /************* Component Methods ********************/
  validateEmails() {
    if (this.address.email) {
      this.emailInputValid = this.validateEmail(this.address.email);
      this.emailValid.emit(this.emailInputValid);
    } else {
      this.emailInputValid = false;
      this.emailValid.emit(this.emailInputValid);
    }
  }

  validateEmail(email: string): boolean {
    // tslint:disable-next-line:max-line-length
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }

}
