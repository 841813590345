
  <div *ngIf="showCalcDialog" class="editor-border">
    <div class="col-12 md:col-2">
      <label>Calculation Name</label>
    </div>
    <div class="col-12 md:col-6">
      <input type="text" pInputText placeholder="Enter Name" [(ngModel)]="calculationDisplayName" />
      <div *ngIf="calculationInvalid && (!calculationDisplayName || calculationDisplayName == '')" class="grid field col-12">
        <label style="color:red; font-weight:bold;">Calculation Name is Required!</label>
      </div>
    </div>
    <div class="grid field col-12">
      <p>Add custom calculated values here. Use auto-complete to choose known measurement channels.
        Known measurement channels will be highlighted in green.
      </p>
      <p class="calc-notes">All measurements used in calculation are assumed to be in SI Units.
        Calculation must return value in SI units. Only use measurements in the calculation that are captured at the
        same time
        (Example: Do not use workbook activities measurements in combination with device reported measurements.)
      </p>
      <!-- <ngx-monaco-editor class="editor" [options]="editorOptions" [(ngModel)]="calculationFormula"></ngx-monaco-editor> -->
      <textarea [(ngModel)]="calculationFormula"></textarea>
      <div *ngIf="calculationInvalid && (!calculationFormula || calculationFormula == '')" class="grid field col-12">
        <label style="color:red; font-weight:bold;">Calculation Formula is Required!</label>
      </div>
    </div>
    <div class="grid field col-12">
      <button type="button" pButton (click)="showCalcDialog=false" label="Cancel" class="editor-button p-button-secondary"></button>
      <button type="button" pButton (click)="addOrUpdateCalculation()" label="Save" class="editor-button ui-button-primary"></button>
    </div>
  </div>
  <div class="col-12">
    <p-table #dt [columns]="calculationsCols" [value]="_companyCalculatedFieldConfiguration.calculatedFieldConfigurations"
      [scrollable]="true" [loading]="!calculationsTableReady" sortField="displayName">
      <ng-template pTemplate="colgroup" let-columns>
        <!-- <colgroup>
          <col *ngFor="let col of columns" [style.width]="col.width" />
        </colgroup> -->
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [style.width]="col.width" >
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-calculation let-index="rowIndex" appendTo="body">
        <tr class="calculations-row">
          <td>
            <label>{{calculation.displayName}}</label>
          </td>
          <td>
            <label>{{calculation.formula}}</label>
          </td>
          <td>
            <i class="far fa-edit icon-padding" (click)="editCalculation(calculation)"></i>
            <i class="far fa-trash-alt icon-padding" (click)="confirmDeleteCalculation(calculation)"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="grid field col-12">
        
        <button pButton type="button" label="Add calculation" (click)="openCalculationDialog()" icon="fa fa-plus"
          class="add-button"></button>
        </div>
      </ng-template>

    </p-table>
  </div>
