import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { VendorJob } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-job-fluid-information',
  standalone: false,
  templateUrl: './job-fluid-information.component.html',
  styleUrls: ['./job-fluid-information.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JobFluidInformationComponent),
    multi: true
  }]
})
export class JobFluidInformationComponent implements OnInit, ControlValueAccessor {

  private _onTouched: Function;

  @Input() _job: VendorJob;
  get job() {
    return this._job;
  }
  set job(val) {
    this._job = val;
    this.propagateChange(this._job);
  }
  @Input() modelInvalid: boolean;
  @Input() disabled = false;

  constructor() { }

  ngOnInit() {
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => { };

  writeValue(obj: any): void {
    if (obj) {
      this.job = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
