import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { Company, Features } from '../../../models/models';

import { ICompanyService,
         IUserService,
         IFeaturesService,
         IApplicationContextService,
         IOpsViewerMessagesService } from '../../../services/services';
import { Title } from '@angular/platform-browser';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { User } from 'src/app/services/nswag/nswagclient';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {

  cols: any[] = [];
  companiesReady = false;
  companies: Company[] = [];
  categories: string[] = [];
  messages: Message[] = [];
  isCompanyAdministrationEnabled = false;
  featuresReady = false;
  user: User = null;
  isUserReady = false;
  everythingReady = false;

  constructor(
    private _companyService: ICompanyService,
    private _userService: IUserService,
    private _router: Router,
    private _confirmationService: ConfirmationService,
    private _featureService: IFeaturesService,
    private _applicationContextService: IApplicationContextService,
    private _messageService: IOpsViewerMessagesService,
    private _userManagerService:IUserManagerService,
    private _titleService: Title ) { }

  ngOnInit() {
    this.setPageTitle();
    this.setColumns();
    this.getCompanies();
    this.getFeatures();
    this.getAuthenticatedUser();
  }

  setPageTitle() {
    this._titleService.setTitle('OpsViewer Companies');
  }

  setColumns() {
    this.cols = [
      {field: 'name', header: 'Name', width: '250px'},
      {field: 'prefix', header: 'Prefix', width: '150px'},
      // {field: 'category', header:'Category', width:'30%'},
      {field: '', header: '', width: '60px'}
    ];
  }

  getCompanies() {
    this.messages = [];
    this._companyService.getCompanies().subscribe(
      result => {
        this.companies = result;
        this.populateCategories();
        this.companiesReady = true;
        this.checkIfEverythingReady();
      },
      error => {
       this.handleError(error, 'Could not get companies');
      }
    );
  }

  getFeatures() {
    this._featureService.isFeatureEnabled(Features.CompanyAdministration).subscribe(
      result => {
        this.isCompanyAdministrationEnabled = result;
        this.featuresReady = true;
        this.checkIfEverythingReady();
      },
      error => { this.handleError(error, 'Could not get features'); }
    );
  }


  getAuthenticatedUser() {

    this._userManagerService.getCurrentUser().subscribe(u=>{
      this.user = u;
      this.isUserReady = true;
      this.checkIfEverythingReady();
    });
  }


  checkIfEverythingReady() {
    this.everythingReady = this.featuresReady && this.companiesReady && this.isUserReady;
  }

  isAdmin(company: Company): boolean {
    if (this.isCompanyAdministrationEnabled) {
      return this._userService.isUserAdmin(this.user, company.id, false);
    } else {
      return this._userService.isUserAdmin(this.user, company.id, true);
    }
  }

  populateCategories() {
    this.companies.forEach(company => {
      if (company.category && !this.categories.includes(company.category)) {
        this.categories.push(company.category);
      }
    });
  }

  navigateToAddCompany() {
    this._router.navigate(['/company/' + encodeURIComponent('new')]);
  }

  navigateToDetail(companyId: string) {
    this._router.navigate(['/company/' + encodeURIComponent(companyId)]);
  }

  refresh() {
    this.cols = [];
    this.companiesReady = false;
    this.companies = [];
    this.categories = [];
    this.messages = [];
    this.ngOnInit();
  }

  confirmDelete(company: Company) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete ' + company.name + '?' +
                 '  Deleting Company will also delete all related records to this company.',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteCompany(company.id); }// ,
        // reject: () => { }
      }
    );
  }

  deleteCompany(companyId: String) {
    this._companyService.deleteCompany(companyId).subscribe(
      result => {
        this.refresh();
        this._messageService.infoMessage('Company Deleted', '');
        this._applicationContextService.alertCompaniesListUpdated();
      },
      error => {this.handleError(error, 'Could not Delete Company'); }
    );
  }

  handleError(error: any, displayMessage: string) {
    this._messageService.errorMessage(error, displayMessage);
  }

}
