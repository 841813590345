import { v4 as uuidv4 } from 'uuid';
import { Simulation, SimulationActivity, SimulationDefault, SimulationHeader } from "src/app/models/simulation";
import { PerformanceSummary, PlugSet, WellInfo } from 'src/app/services/nswag/nswagclient';

export enum InitialActivityName {
  FirstTrip = 'First Trip in Hole Vertical',
  FromKop = 'From KOP to 30 degree',
  From30Degree = 'From 30 degree to 60 degree',
  From60Degree = 'From 60 degree to 90 degree',
  PoohLateral = 'POOH in lateral',
  PoohVertical = 'POOH in vertical',
}

export enum OptionalActivityNames {
  Circulation = 'Circulation',
  Delay = 'Delay/NPT',
  Milling = 'Milling',
  Pickup = 'Pickup',
  ShortTrip = 'Short Trip',
  Tag = 'Tag',
  ToolRun = 'Tool Run/BHA Check',
  Washing = 'Washing',
  WeightCheck = 'Weight Check',
  WiperTrip = 'Wiper Trip',
}

export class SimulationCommon {
  static cloneSimulation(sim: Simulation) : Simulation {
    let clone = { ...sim };
    clone.id = uuidv4();
    if(clone.header) {
      clone.header.name = 'Copy of ' + clone.header.name; 
      clone.header.simulationId = clone.id;
      clone.header.majorVersion = clone.header.majorVersion + 1;
      clone.header.minorVersion = 0;
      clone.header.revisionVersion = 0;
    }
    else {
      clone.header = <SimulationHeader> { simulationId: clone.id, name: 'New Simulation', majorVersion: 2, minorVersion: 0, revisionVersion: 0 };
    }
    return clone;
  }

  static getActivitiesFromPlugs(plugSets: PlugSet[], def: SimulationDefault) : SimulationActivity[] {
    if(!def) {
      def = SimulationCommon.getFactorySimulationDefaults();
    }
    let acts: SimulationActivity[] = [];
    let currentDepth = 0;
    let previousDepth = 0;
    for(let i = 0; i < plugSets.length; i++) {
      for(let j = 0; j < plugSets[i].plugs.length; j++) {
        let p = plugSets[i].plugs[j];
        if(p && p.number && p.depth) {
          acts.push(<SimulationActivity> {
            guid: uuidv4(),
            activity: 'Tag',
            isDepthStart: false,
            depthStart: p.number,
            isDepthEnd: false,
            depthEnd: p.number,
            duration: def.duration,
            pipeSpeed: def.pipeSpeed,
            circulationRate: def.circulationRate,
            returnRate: def.returnRate
          });
          currentDepth = SimulationCommon.getPlugDepth(previousDepth, plugSets, p.number);
          let millActivity = <SimulationActivity> {
            guid: uuidv4(),
            activity: 'Milling',
            isDepthStart: false,
            depthStart: p.number,
            isDepthEnd: false,
            depthEnd: p.number + 1,
            duration: def.duration,
            pipeSpeed: def.pipeSpeed,
            circulationRate: def.circulationRate,
            returnRate: def.returnRate
          };
          if(previousDepth > 0) {
            millActivity.duration = Number((Math.abs(currentDepth - previousDepth) / def.pipeSpeed / 60).toFixed(2));
          }
          acts.push(millActivity);
          previousDepth = currentDepth;
        }
      }
    }
    return acts;
  }
  
  static getInitialActivities(def: SimulationDefault, wellInfo: WellInfo): SimulationActivity[] {
      if(!def) {
          def = SimulationCommon.getFactorySimulationDefaults();
      }
      const xoEot = wellInfo && wellInfo.xoEot ? wellInfo.xoEot : 0;
      const kop = wellInfo && wellInfo.kop ? wellInfo.kop : 0;
      const thirtyDegree = wellInfo && wellInfo.thirtyDegree ? wellInfo.thirtyDegree : 0;
      const sixtyDegree = wellInfo && wellInfo.sixtyDegree ? wellInfo.sixtyDegree : 0;
      const ninetyDegree = wellInfo && wellInfo.tvd ? wellInfo.tvd : 0;
      return [
          <SimulationActivity> { guid: uuidv4(), activity: InitialActivityName.FirstTrip, duration: def.duration, isDepthStart: true, isDepthEnd: true, pipeSpeed: def.pipeSpeed, circulationRate: def.circulationRate, returnRate: def.returnRate, depthStart: 0, depthEnd: xoEot },
          <SimulationActivity> { guid: uuidv4(), activity: InitialActivityName.FromKop, duration: def.duration, isDepthStart: true, isDepthEnd: true, pipeSpeed: def.pipeSpeed, circulationRate: def.circulationRate, returnRate: def.returnRate, depthStart: kop, depthEnd: thirtyDegree },
          <SimulationActivity> { guid: uuidv4(), activity: InitialActivityName.From30Degree, duration: def.duration, isDepthStart: true, isDepthEnd: true, pipeSpeed: def.pipeSpeed, circulationRate: def.circulationRate, returnRate: def.returnRate, depthStart: thirtyDegree, depthEnd: sixtyDegree },
          <SimulationActivity> { guid: uuidv4(), activity: InitialActivityName.From60Degree, duration: def.duration, isDepthStart: true, isDepthEnd: true, pipeSpeed: def.pipeSpeed, circulationRate: def.circulationRate, returnRate: def.returnRate, depthStart: sixtyDegree, depthEnd: ninetyDegree },
          <SimulationActivity> { guid: uuidv4(), activity: InitialActivityName.PoohLateral, duration: def.duration, isDepthStart: true, isDepthEnd: true, pipeSpeed: def.pipeSpeed, circulationRate: def.circulationRate, returnRate: def.returnRate},
          <SimulationActivity> { guid: uuidv4(), activity: InitialActivityName.PoohVertical, duration: def.duration, isDepthStart: true, isDepthEnd: true, pipeSpeed: def.pipeSpeed, circulationRate: def.circulationRate, returnRate: def.returnRate },
      ];
  }

  static updateInitialActivityDepths(activities: SimulationActivity[], newWellInfo: WellInfo): SimulationActivity[] {
    let seqActivities = [...activities];
    if (newWellInfo) {
        if (newWellInfo.kop) {
          const firstTripInHole = seqActivities.find(a => a.activity === InitialActivityName.FirstTrip);
          if (firstTripInHole) {
            firstTripInHole.isDepthStart = true;
            firstTripInHole.depthStart = 0;
            firstTripInHole.isDepthEnd = true;
            firstTripInHole.depthEnd = newWellInfo.kop;
            firstTripInHole.calcDepth = firstTripInHole.depthEnd;
          }
        }
        const fromKopTo30Deg = seqActivities.find(a => a.activity === InitialActivityName.FromKop);
        if (fromKopTo30Deg ) {
          if (newWellInfo.kop) {
            fromKopTo30Deg.depthStart = newWellInfo.kop;
          }
          if (newWellInfo.thirtyDegree) {
            fromKopTo30Deg.depthEnd = newWellInfo.thirtyDegree;
            fromKopTo30Deg.calcDepth = fromKopTo30Deg.depthEnd;
          }
        }
        const from30to60Deg = seqActivities.find(a => a.activity === InitialActivityName.From30Degree);
        if (from30to60Deg ) {
          if (newWellInfo.thirtyDegree) {
            from30to60Deg.depthStart = newWellInfo.thirtyDegree;
          }
          if (newWellInfo.thirtyDegree) {
            from30to60Deg.depthEnd = newWellInfo.sixtyDegree;
            from30to60Deg.calcDepth = from30to60Deg.depthEnd;
          }
        }
        const from60to90deg = seqActivities.find(a => a.activity === InitialActivityName.From60Degree);
        if (from60to90deg ) {
          if (newWellInfo.sixtyDegree) {
            from60to90deg.depthStart = newWellInfo.sixtyDegree;
          }
          if (newWellInfo.tvd) {
            from60to90deg.depthEnd = newWellInfo.tvd;
            from60to90deg.calcDepth = from60to90deg.depthEnd;
          }
        }
      }
    return seqActivities;
}

  static getFactorySimulationDefaults() : SimulationDefault {
      return <SimulationDefault> { circulationRate: 4.5, duration: 0.25, pipeSpeed: 10.0, returnRate: 4.0 };
  }
  

  static getNewActivity(activityName: string, def: SimulationDefault) : SimulationActivity {
      if(!def) {
          def = SimulationCommon.getFactorySimulationDefaults();
      }
      return <SimulationActivity> {
      guid: uuidv4(),
      activity: activityName,
      circulationRate: def.circulationRate,
      duration: def.duration,
      isDepthStart: true,
      isDepthEnd: true,
      pipeSpeed: def.pipeSpeed,
      returnRate: def.returnRate, 
      }
  }

  static getPerformanceMetrics(seq: SimulationActivity[], plugCount: number, pbtd: number) : PerformanceSummary {
      const lastActivity = seq[seq.length - 1];
      const shortTripCount = seq.filter(i => i.activity === 'Short Trip').length;
      return <PerformanceSummary> { 
          jobTime: lastActivity.calcRelativeTime,
          plugPerShortTrip: shortTripCount > 0 ? plugCount / shortTripCount : 0,
          plugsDrilled: plugCount,
          productiveFtPerHour: lastActivity.calcRelativeTime > 0 ? pbtd / lastActivity.calcRelativeTime : 0,
          runningFtMdRatio: pbtd > 0 ? lastActivity.calcDepth / pbtd : 0,
          shortTrips: shortTripCount,
          timePerPlug: plugCount > 0 ? lastActivity.calcRelativeTime / plugCount : 0,
          totalRunningPipe: lastActivity.calcDepth,
        };
  }

  static getPlugDepth(currentDepth: number, sets: PlugSet[], plugNumber: number) : number {
      if(!sets) {
          return currentDepth;
      }
      for(var i = 0; i < sets.length; i++) {
          for(var j = 0; j < sets[i].plugs.length; j++) {
              if(sets[i].plugs[j].number === plugNumber) {
                  return sets[i].plugs[j].depth;
              }
          }
      }
      return currentDepth;
  }

  static getStartingDepthForPlugNumber(sets: PlugSet[], plugNumber: number): number {
    if (!sets || sets.length < 1 || plugNumber == null) {
      return 0;
    }

    let depth = 0;
    sets.map(s => {
      const foundPlug = s.plugs.find(p => p.number === plugNumber);
      if (foundPlug) {
        depth = foundPlug.depth;
      }
    });

    return depth;
  }

  static getPlugNumberFromDepth(currentDepth: number, sets: PlugSet[]): number {
    // Since plugs are not to be required to be ordered by depth
    // and depth ordering can vary across plug sets,
    // Scan through all plugs to find the plug that is closest to the target depth
    if (!sets || sets.length < 1) {
      return 0;
    }

    // Default plug to 0
    let bestFoundPlugNumber = 0;
    let bestFoundPlugDepth = 0;

    sets.map(s => {
      if (s.plugs) {
        s.plugs.map(p => {
          // Plug cannot be past current depth.
          // Looking for the last plug that is closest to current depth
          if (p.depth && p.depth < currentDepth) {
            if (Math.abs(p.depth - currentDepth) < Math.abs(bestFoundPlugDepth - currentDepth)) {
              bestFoundPlugNumber = p.number;
              bestFoundPlugDepth = p.depth;
            }
          }
        });
      }
    });
    return bestFoundPlugNumber;
  }

  static getPlugNumberFromTagCount(currentActivity: SimulationActivity, seqActivities: SimulationActivity[]): number {
    let numberOfTags = 0;
    if (currentActivity && seqActivities && seqActivities.length >= 1) {
      seqActivities.map(a => {
        if (a.activity === 'Tag' && a.calcDepth  < currentActivity.calcDepth) {
          numberOfTags++;
        }
      });
      if (currentActivity.activity === 'Tag') {
        numberOfTags++;
      }
    }
    return numberOfTags;
  }
  
  static getTotalPlugs(plugSets: PlugSet[]): number {
      let count = 0;
      plugSets.map( p => {
          if (p.plugs) {
          count = count + p.plugs.length;
          }
      });
      return count;
  }
}