import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { firstValueFrom, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { v4 as uuidv4 } from 'uuid';

import { ActivityDefaultsComponent } from '../activity-defaults/activity-defaults.component'
import { ChemistryComponent } from '../chemistry/chemistry.component';
import { ExpensesComponent } from '../expenses/expenses.component';
import { GoalsComponent } from '../goals/goals.component';
import { HeaderComponent } from '../header/header.component';
import { PerformanceComponent } from '../performance/performance.component';
import { SequenceComponent } from '../sequence/sequence.component'
import { SiteDetailComponent } from '../site-detail/site-detail.component';
import { WellInformationComponent } from '../well-information/well-information.component';

import { CompanyName } from 'src/app/models/models';
import {
  Simulation,
  SimulationActivity,
  SimulationFluidSummary,
  SimulationGoals,
  SimulationHeader,
  SimulationSiteDetails,
} from 'src/app/models/simulation';
import { SimulationCalculations } from '../functions/simulationCalculations';
import { SimulationCommon, InitialActivityName } from '../functions/simulationCommon';
import { SimulationExport } from '../functions/simulationExport';
import { SimulationGraphs } from '../functions/simulationGraphs';

import { IApplicationContextService, ICompanyService, ISimulationService, IUserService } from 'src/app/services/services';
import { first } from 'rxjs/operators';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { PerformanceSummary, UserClient, WellInfo } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-job-simulator',
  templateUrl: './job-simulator.component.html',
  styleUrls: ['./job-simulator.component.css']
})
export class JobSimulatorComponent implements AfterViewInit, OnDestroy, OnInit {
  private _simulation: Simulation;
  private _companyContextSubscription: Subscription;
  avData = [];
  avOptions: any;
  company: CompanyName;
  consumerCompanies: SelectItem[] = [];
  depthVsReData = [];
  depthVsReOptions: any;
  flowbackBblsData = [];
  flowbackBblsOptions: any;
  pumpedBblsData = [];
  pumpedBblsOptions: any;
  simulationIdParam: string;
  totalChemicalCost: number = 0;
  totalServiceCost: number = 0;

  @Input()
  set simulation(sim: Simulation){
    this._simulation = sim;
    if(!this.simulation.defaults) {
      this.simulation.defaults = SimulationCommon.getFactorySimulationDefaults();
    }
    //Components
    this.actDfltCom.defaults = this._simulation.defaults;
    this.seqCom.activities = this._simulation.activities;
    this.seqCom.defaults = this._simulation.defaults;
    this.chemistryCom.chemicals = this._simulation.chemicals;
    this.chemistryCom.fluidSummary = this.simulation.fluidSummary;
    this.expensesCom.expenses = this._simulation.expenses;
    this.goalCom.goals = this._simulation.goals;
    this.headerCom.header = this._simulation.header;
    this.perfCom.performanceSummary = this._simulation.performance;
    this.siteCom.siteDetails = this._simulation.siteDetails;
    this.wellCom.wellInfo = this._simulation.wellInfo;
    this.wellCom.setSelectedTubingODs();
  }
  get simulation(): Simulation{
    return this._simulation;
  }

  @ViewChild(ActivityDefaultsComponent, { static: true })
  private actDfltCom: ActivityDefaultsComponent;
  @ViewChild(ChemistryComponent, { static: true })
  private chemistryCom: ChemistryComponent;
  @ViewChild(ExpensesComponent, { static: true })
  private expensesCom: ExpensesComponent;
  @ViewChild(GoalsComponent, { static: true })
  private goalCom: GoalsComponent;
  @ViewChild(HeaderComponent, { static: true })
  private headerCom: HeaderComponent;
  @ViewChild(PerformanceComponent, { static: true })
  private perfCom: PerformanceComponent;
  @ViewChild(SequenceComponent, { static: true })
  private seqCom: SequenceComponent;
  @ViewChild(SiteDetailComponent, { static: true })
  private siteCom: SiteDetailComponent;
  @ViewChild(WellInformationComponent, { static: true })
  public wellCom: WellInformationComponent;

  constructor(
    private _applicationContextService: IApplicationContextService,
    private _companyService: ICompanyService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _simulationService: ISimulationService,
    private _titleService: Title,
    private _userService: IUserService,
    private _userClient : UserClient,
    private _currentUserService : ICurrentUserService
  ) { }

  ngOnInit() {
    this.avOptions = SimulationGraphs.buildBasicOptions('Time', 'AV', 'Relative Time', 'Velocity', 'AV vs Time');
    this.flowbackBblsOptions = SimulationGraphs.buildBasicOptions('Time', 'Flowback', 'Relative Time', 'BBLS', 'Flowback (BBLs) vs Time');
    this.pumpedBblsOptions = SimulationGraphs.buildBasicOptions('Time', 'Pumped', 'Relative Time', 'BBLS', 'Pumped (BBLs) vs Time');

    this.depthVsReOptions = {
      width: 'auto',
      labels: ['Time','Depth','RE'],
      xlabel: 'Relative Time',
      ylabel: 'Feet',
      series: {'RE': {axis: 'y2'}},
      title: 'Depth & RE vs Time',
      animatedZooms: true,
      pointSize: 4
    };
    this.listenToCompanyContext();
  }

  ngOnDestroy() {
    this._companyContextSubscription.unsubscribe();
  }

  async ngAfterViewInit() : Promise<void> {
    let params = await firstValueFrom(this._route.params.pipe(first()));
    this.simulationIdParam = params['id'];
    const sim = await this.getSimulationFromRoute(this.simulationIdParam);
    if(!sim) {
      this.navigateToSimulationList();
      return;
    }
    this.simulation = sim;
    this.consumerCompanies = await this.getConsumerCompanies();
    this.headerCom.consumerCompanies = this.consumerCompanies;
    this.setPageTitle(this.simulation.header.name);
    await this.updateCompany()
  }

  async cloneSimulation() {
    this.simulation = SimulationCommon.cloneSimulation(this.simulation);
    await this.saveSimulation(false);
  }

  createEmptySimulation() : Simulation {
    let simId = uuidv4();
    let def = SimulationCommon.getFactorySimulationDefaults();
    let well = <WellInfo> { xoEot: 0, casings: [], plugs: [], plugSets: [] };
    let acts = SimulationCommon.getInitialActivities(def, well);
    let fld = <SimulationFluidSummary> { fluidTypeId: 'SLW', loopTypeId: 'recirculate' };
    let goal = <SimulationGoals> { };
    let hdr = <SimulationHeader> { simulationId: simId, name: 'New Simulation', majorVersion: 1, minorVersion: 0, revisionVersion: 0 };
    let perf = <PerformanceSummary> { }
    let site = <SimulationSiteDetails> { };
    if(!hdr.customer && this.consumerCompanies && this.consumerCompanies.length > 0) {
      hdr.customer = this.consumerCompanies[0].value;
    }
    return <Simulation> {
      id: simId,
      activities: acts,
      chemicals: [],
      defaults: def,
      expenses: [],
      fluidSummary: fld,
      goals: goal,
      header: hdr,
      performance: perf,
      siteDetails: site,
      wellInfo: well,
    };
  }

  async generateActivitiesFromPlugs() {
    let initAct: SimulationActivity[] = SimulationCommon.getInitialActivities(this.simulation.defaults, this.wellCom.wellInfo);
    let plugActivities: SimulationActivity[] = SimulationCommon.getActivitiesFromPlugs(this.wellCom.wellInfo.plugSets, this.simulation.defaults);
    if(initAct.length === 6) {
      this.seqCom.activities = [initAct[0], initAct[1], initAct[2], initAct[3], ...plugActivities, initAct[4], initAct[5]];
    }
    else {
      this.seqCom.activities = [...initAct, ...plugActivities];
    }
    await this.runCalculatedFields();
  }

  async getConsumerCompanies() : Promise<SelectItem[]> {
    try{
      let cmpns = await firstValueFrom(this._companyService.getConsumerCompanyNames())
    return cmpns.map(r => <SelectItem> { label: r.companyName, value: r  })
    }
    catch(e) {
      console.error('Error encountered retrieving the Operator companies', [e]);
      return [];
    }
  }

  async getSimulation(simulationId: string) : Promise<Simulation> {
    return await firstValueFrom(this._simulationService.getSimulation(simulationId));
  }

  async getSimulationFromRoute(simulationId: string) : Promise<Simulation> {
    let resultSim: Simulation;
    if (simulationId) {
      try {
        resultSim = await this.getSimulation(simulationId);
        if(this.company && resultSim.header.companyId !== this.company.companyId) {
          this.navigateToSimulationList();
        }
      }
      catch(e) {
        console.error(`Could not retrieve simulation (id = ${simulationId})`, [e]);
      }
    }
    else {
      resultSim = this.createEmptySimulation();
    }
    return resultSim;
  }

  getTotalChemicalCost(): number {
    return this.chemistryCom.chemicals
      .reduce((acc, c) => {
        return acc + SimulationCalculations.calculateChemicalCost(
          c,
          this.seqCom.activities,
          this.chemistryCom.fluidSummary.loopTypeId === 'recirculate',
          this.chemistryCom.fluidSummary.barrelCount,
          this.wellCom.wellInfo.plugSets);
      }, 0);
  }

  getTotalServiceCost() : number {
    let act = this.seqCom.activities[this.seqCom.activities.length - 1];
    let plugCount = SimulationCommon.getTotalPlugs(this.wellCom.wellInfo.plugSets)
    return this.expensesCom.expenses.reduce((acc, e) => acc + SimulationCalculations.calculateServiceCost(e, act, plugCount), 0);
  }

  async handleTabViewChange(e: any) {
    const index: number = e.index;
    if(index > 0) {
      await this.runCalculatedFields();
      if(index > 1) {
        this.updateOutput();
      }
    }
  }

  async isEditorOrAdmin(companyId: string) : Promise<boolean> {
    try {
      const email = this._currentUserService.getUserEmail();
      const user = await firstValueFrom(this._userClient.getByEmail(email));
      return this._userService.isUserEditorOrAdmin(user, companyId, false);
    }
    catch(e) {
      console.error('Could not get user', [e]);
      this.navigateToSimulationList();
    }
  }

  listenToCompanyContext() {
    this._companyContextSubscription = this._applicationContextService.companyContextObserver.subscribe({
      next: async c => await this.updateCompany(c),
    });
  }

  navigateToSimulationList() {
    this._router.navigate(['/simulator']);
  }

  async runCalculatedFields() {
    this.seqCom.activities = SimulationCommon.updateInitialActivityDepths(this.seqCom.activities, this.wellCom.wellInfo);
    this.seqCom.activities = await SimulationCalculations.calculateFields(this.chemistryCom.fluidSummary, this.wellCom.wellInfo, this.seqCom.activities);
    this.avData = await SimulationGraphs.buildGraphData(this.seqCom.activities, SimulationGraphs.avDataMapping);
    this.depthVsReData = await SimulationGraphs.buildDepthGraphData(this.seqCom.activities);
    this.flowbackBblsData = await SimulationGraphs.buildGraphData(this.seqCom.activities, SimulationGraphs.flowbackBblsDataMapping);
    this.pumpedBblsData = await SimulationGraphs.buildGraphData(this.seqCom.activities, SimulationGraphs.pumpedBblsDataMapping);
  }

  setPageTitle(name: string) {
    if (!name || name === '') {
      this._titleService.setTitle('New Simulation');
    } else { this._titleService.setTitle(name); }
  }

  async updateCompany(cmpny: CompanyName = null)  {
    if(!cmpny) {
      cmpny = this._applicationContextService.getCompanyContext();
    }
    if (cmpny) {
      if (!this.company || cmpny.companyId === this.company.companyId) {
        this.company = cmpny;
        if(this.simulationIdParam && this.simulation && this.simulation.header.companyId !== this.company.companyId) {
          this.navigateToSimulationList();
        }
        const userCheck = await this.isEditorOrAdmin(this.company.companyId);
        if(!userCheck) {
          this._router.navigate(['/']);
        }
      }
      else {
        this.navigateToSimulationList();
      }
    }
  }

  updateOutput() {
    this.totalChemicalCost = this.getTotalChemicalCost();
    this.totalServiceCost = this.getTotalServiceCost();
    const plugCount = SimulationCommon.getTotalPlugs(this.wellCom.wellInfo.plugSets);
    this.perfCom.performanceSummary = SimulationCommon.getPerformanceMetrics(this.seqCom.activities, plugCount, this.wellCom.wellInfo.md);
  }

  async saveSimulation(navigateToList: boolean = true) {
    this.simulation.activities = this.seqCom.activities = await SimulationCalculations.calculateFields(this.chemistryCom.fluidSummary, this.wellCom.wellInfo, this.seqCom.activities);
    this.simulation.chemicals = this.chemistryCom.chemicals;
    this.simulation.defaults = this.actDfltCom.defaults;
    this.simulation.fluidSummary = this.chemistryCom.fluidSummary;
    this.simulation.expenses = this.expensesCom.expenses;
    this.simulation.goals = this.goalCom.goals;
    this.simulation.header = this.headerCom.header;
    const plugCount = SimulationCommon.getTotalPlugs(this.wellCom.wellInfo.plugSets);
    this.simulation.performance = this.perfCom.performanceSummary = SimulationCommon.getPerformanceMetrics(this.seqCom.activities, plugCount, this.wellCom.wellInfo.md); //Can be recalculated on load.
    this.simulation.siteDetails = this.siteCom.siteDetails;
    this.simulation.wellInfo = this.wellCom.wellInfo;

    if(this.company) {
      this.simulation.header.companyId = this.company.companyId;
    }
    this.simulation.header.simulationId = this.simulation.id;
    try{
      let saved = await firstValueFrom(this._simulationService.saveSimulation(this.simulation));
      console.log(`Saved Simulation ${ saved.header.simulationId }`);
      if(navigateToList) {
        this.navigateToSimulationList();
      }
    }
    catch(error) {
      console.error('An error occurred: ', [error]);
    }
  }

  async exportSimulation() {
    this.seqCom.activities = await SimulationCalculations.calculateFields(this.chemistryCom.fluidSummary, this.wellCom.wellInfo, this.seqCom.activities);
    this.updateOutput();
    var sections: SelectItem[] = [
      { label: this.headerCom.header.name, value: SimulationExport.headerCsvBlock(this.headerCom.header) },
      { label: 'Site Details', value: SimulationExport.siteDetailsCsvBlock(this.siteCom.siteDetails) },
      { label: 'Expenses', value: SimulationExport.expenseHeader + this.expensesCom.expenses.map(SimulationExport.expenseToCSVRow).join('\r\n') },
      { label: 'Chemicals', value: SimulationExport.chemicalHeader + this.chemistryCom.chemicals.map(SimulationExport.chemicalToCSVRow).join('\r\n') },
      { label: 'Activities', value: SimulationExport.activityHeader + this.seqCom.activities.map(SimulationExport.activityToCSVRow).join('\r\n') },
      { label: 'Metrics', value: SimulationExport.wellInfoToCsvBlock(this.wellCom.wellInfo) },
      { label: 'Job Totals', value: SimulationExport.jobTotalsCsvBlock(this.seqCom.activities[this.seqCom.activities.length - 1]) },
      { label: 'Statistics', value: SimulationExport.statisticsCsvBlock(this.perfCom.performanceSummary) },
      { label: 'Cost Estimates', value: SimulationExport.costEstimateCsvBlock(this.totalChemicalCost, this.totalServiceCost) },
    ];
    await SimulationExport.exportToCSV(sections);
  }
}
