import { Injectable } from '@angular/core';
import { IImportPlugDataService } from './iimportPlugData.service';
import { PlugSet, Plug } from './nswag/nswagclient';

@Injectable()
export class ImportPlugDataService extends IImportPlugDataService {

  constructor() {
    super();
  }

  autoChooseImportMethod(plugSets: PlugSet[], importData: string): PlugSet[] {
    // Split text into rows
    const rows = importData.split('\n');
    if (rows[rows.length - 1] === '') {
      rows.pop();
    }

    if (rows.length > 0) {
      // Test first row
      const firstRowFields = rows[0].split('\t');
      if (firstRowFields.length === 6) {
        return this.parseData(importData);
      } else if (firstRowFields.length === 3) {
        return this.parseDataNoImportGroups(plugSets, importData);
      } else {
        return plugSets;
      }
    } else {
      return plugSets;
    }
  }

  parseData(importData: string): PlugSet[] {
    const parsedPlugSets = [];

    // Split text into rows
    const rows = importData.split('\n');
    if (rows[rows.length - 1] === '') {
      rows.pop();
    }

    rows.map(r => {
      const singleRowFields = r.split('\t');

      const currentGroupName = this.buildGroupName(singleRowFields);

      // PlugSet exists, add to plugs, else create new plugset
      if (this.doesPlugSetAlreadyExist(parsedPlugSets, currentGroupName)) {
        const foundPlugSet = parsedPlugSets.find(p => this.getGroupName(p) === currentGroupName);
        if (!foundPlugSet.plugs) {
          foundPlugSet.plugs = [];
        }
        const newPlug = this.buildNewPlug(singleRowFields, this.getTotalPlugs(parsedPlugSets));
        const indexOfFoundPlugSet = parsedPlugSets.indexOf(foundPlugSet);
        foundPlugSet.plugs.push(newPlug);
        parsedPlugSets[indexOfFoundPlugSet] = foundPlugSet;
      } else {
        const newPlugSet = this.buildNewPlugSet(singleRowFields, parsedPlugSets);
        parsedPlugSets.push(newPlugSet);
      }
    });

    return parsedPlugSets;
  }

  parseDataNoImportGroups(plugSets: PlugSet[], importData: string): PlugSet[] {
    // Split text into rows
    const rows = importData.split('\n');
    if (rows[rows.length - 1] === '') {
      rows.pop();
    }

    // Add default plug group
    plugSets = [];
    plugSets.push(new PlugSet());
    plugSets[0].manufacturer = 'unkown';
    plugSets[0].model = 'unkown';
    plugSets[0].type = 'CFP';
    plugSets[0].plugs = [];

    let plugNumber = 1;
    rows.map(row => {
      // Split row into fields
      const singleRowFields = row.split('\t');

      // Removing logic to find target plugs to update since no plug info is given
      // and will overwrite any existing data
      // const indexes = this.getPlugSetAndPlugIndexOfTarget(plugSets, plugNumber);

      // if (indexes[0] >= 0 && indexes[1] >= 0) {
      //   plugSets[indexes[0]].plugs[indexes[1]].depth = singleRowFields.length >= 1 ? Number(singleRowFields[0]) : null;
      //   plugSets[indexes[0]].plugs[indexes[1]].perfTop = singleRowFields.length >= 2 ? Number(singleRowFields[1]) : null;
      //   plugSets[indexes[0]].plugs[indexes[1]].perfBottom = singleRowFields.length >= 3 ? Number(singleRowFields[2]) : null;
      // }
      const plug = new Plug();
      plug.number = plugNumber;
      plug.depth = singleRowFields.length >= 1 ? Number(singleRowFields[0]) : null;
      plug.perfTop = singleRowFields.length >= 2 ? Number(singleRowFields[1]).toString() : null;
      plug.perfBottom = singleRowFields.length >= 3 ? Number(singleRowFields[2]).toString() : null;
      plugSets[0].plugs.push(plug);

      plugNumber++;
    });

    return plugSets;
  }

  getGroupName(plugSet: PlugSet): string {
    let manufacturer = plugSet.manufacturer;
    if (!manufacturer) {
      manufacturer = 'Undefined';
    }
    let model = plugSet.model;
    if (!model) {
      model = 'Undefined';
    }
    let type = plugSet.type;
    if (!type) {
      type = 'Undefined';
    }
    return manufacturer + ' - ' + model + ' - ' + type;
  }

  buildGroupName(singleRowFields: string[]): string {
    const manufacturer = (singleRowFields[0] && singleRowFields[0] !== '') ? singleRowFields[0] : 'Undefined';
    const model = (singleRowFields[1] && singleRowFields[1] !== '') ? singleRowFields[1] : 'Undefined';
    const type = (singleRowFields[2] && singleRowFields[2] !== '') ? singleRowFields[2] : 'Undefined';

    return manufacturer + ' - ' + model + ' - ' + type;
  }

  doesPlugSetAlreadyExist(plugSets: PlugSet[], targetGroupName: string): boolean {
    const foundPlugSet = plugSets.find(p => this.getGroupName(p) === targetGroupName);
    if (foundPlugSet) {
      return true;
    }
    return false;
  }

  buildNewPlug(singleRowFields: string[], numberOffset: number): Plug {
    const newPlug = new Plug();
    newPlug.number = numberOffset + 1;
    newPlug.depth =  singleRowFields.length >= 4 ? Number(singleRowFields[3]) : null;
    newPlug.perfTop = singleRowFields.length >= 5 ? Number(singleRowFields[4]).toString() : null;
    newPlug.perfBottom = singleRowFields.length >= 6 ? Number(singleRowFields[5]).toString() : null;

    return newPlug;
  }

  buildNewPlugSet(singleRowFields: string[], currentPlugSets: PlugSet[]): PlugSet {
    const newPlugSet = new PlugSet();
    newPlugSet.manufacturer = (singleRowFields[0] && singleRowFields[0] !== '') ? singleRowFields[0] : 'Undefined';
    newPlugSet.model = (singleRowFields[1] && singleRowFields[1] !== '') ? singleRowFields[1] : 'Undefined';
    newPlugSet.type = (singleRowFields[2] && singleRowFields[2] !== '') ? singleRowFields[2] : 'Undefined';

    newPlugSet.plugs = [];
    newPlugSet.plugs.push(this.buildNewPlug(singleRowFields, this.getTotalPlugs(currentPlugSets)));

    return newPlugSet;
  }

  getTotalPlugs(plugSets: PlugSet[]): number {
    let count = 0;
    plugSets.map( p => {
      if (p.plugs) {
        count = count + p.plugs.length;
      }
    });
    return count;
  }

  // Return [PlugSetIndex, PlugIndex] of targeted plug number
  // Returns [-1, -1] if not found
  getPlugSetAndPlugIndexOfTarget(plugSets: PlugSet[], plugNumber: number): [number, number] {
    let plugSetIndex = 0;
    let plugIndex = -1;
    let foundIndexes = [-1, -1];
    plugSets.map(plugSet => {
      if (plugSet.plugs) {
        plugIndex = plugSet.plugs.findIndex(p => p.number === plugNumber);
        if (plugIndex >= 0) {
          foundIndexes = [plugSetIndex, plugIndex];
        }
      }
      plugSetIndex ++;
    });
    return [foundIndexes[0], foundIndexes[1]];
  }
}
