<app-job-linker-list *ngIf="showTable && jobLinkers"
  [jobLinkers]="jobLinkers"
  [showAdminControls]="forAdmin"
  [jobTypes]="jobTypes"
  [statuses]="statuses"
  [vendorJobSelectItems]="vendorJobSelectItems"
  (openJobLinkerToEdit)="openJobLinkerToEditClicked($event)"
  (jobLinkerDeleted)="jobLinkerDeleteClicked($event)"
  (showEdit)="toggleShowEditClicked($event)">
</app-job-linker-list>

<p-dialog *ngIf="showEdit"
  header="Add or Update Job Linking"
  [(visible)]="showEdit"
  (onHide)="showEdit=false"
  [width]="500" [minWidth]="350">
    <app-job-linker-edit
    [jobLinkerToEdit]="jobLinkerToEdit"
    [companySelectItems]="companySelectItems"
    [vendorJobSelectItems]="vendorJobSelectItems"
    [consumerJobs]="consumerJobs"
    [jobTypes]="jobTypes"
    [statuses]="statuses"
    (jobLinkerAdded)="addJobLinker($event)"
    (jobLinkerUpdated)="updateJobLinker($event)"
    (closeEdit)="showEdit=false">
  </app-job-linker-edit>
</p-dialog>
