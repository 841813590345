import { Injectable } from '@angular/core';
import { PlugSet } from './nswag/nswagclient';

@Injectable()
export abstract class IImportPlugDataService {
  abstract autoChooseImportMethod(plugSets: PlugSet[], importData: string): PlugSet[];
  abstract parseData(importData: string): PlugSet[];
  abstract parseDataNoImportGroups(plugSets: PlugSet[], importData: string): PlugSet[];
  abstract getGroupName(plugSet: PlugSet): string;
}
