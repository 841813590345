import { Component, OnInit, forwardRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { DeviceMeasurementChannelConfiguration, UnitSystemChoice, WellKnownMeasurementChoice, Company  } from 'src/app/models/models';
import { ICompanyService, ICompanyWorkbookConfigurationService } from 'src/app/services/services';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-device-configuration',
  
  standalone: false,
  templateUrl: './device-configuration.component.html',
  styleUrls: ['./device-configuration.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DeviceConfigurationComponent),
    multi: true
  }]
})
export class DeviceConfigurationComponent implements OnInit, ControlValueAccessor, OnChanges {

  private _onTouched: Function;
  public isDisabled: boolean;

  public unitSystemChoices: SelectItem[] = [
    {label: UnitSystemChoice.si, value: UnitSystemChoice.si  },
    {label: UnitSystemChoice.imperial, value: UnitSystemChoice.imperial  }
  ];

  @Input() wellKnownMeasurements: WellKnownMeasurementChoice[] = [];
  @Input() company: Company;
  @Input() measurements: DeviceMeasurementChannelConfiguration[] = [];
  get deviceMeasurementChannelConfigurations() {
    return this.measurements;
  }
  set deviceMeasurementChannelConfigurations(val) {
    this.measurements = val;
    this.measurements = this.prepareConfigurationForUI(this.measurements);
    this.propagateChange(this.measurements);
  }
  @Output() errors = new EventEmitter();

  unitSystem: string;

  wellKnownMeasurementOptions: SelectItem[] = [];
  measurementDataTypes: SelectItem[] = [
    { label: 'Number', value: 'Number' },
    { label: 'Text', value: 'Text' }
  ];
  measurementConfigurationCols: any[] = [];

  constructor(
    private _confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.setConfigurationCols();
  }

  ngOnChanges() {
    this.unitSystem = this.getUnitSystem();
    this.wellKnownMeasurementOptions = this.setWellKnownMeasurementOptions();
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates and other inputs ******/
  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
   if (obj) {
     this.deviceMeasurementChannelConfigurations = obj;
   } else {
     this.deviceMeasurementChannelConfigurations = [];
   }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /****** Componet UI control methods ******/

  getUnitSystem(): string {
    if (this.company && this.company.unitSystem) {
      return this.company.unitSystem;
    }
    return this.setDefaultUnitSystem();
  }

  setDefaultUnitSystem(): string {
    // Defualt Unit System to SI
    return UnitSystemChoice.si;
  }

  setWellKnownMeasurementOptions(): SelectItem[] {
    const items: SelectItem[] = [];
    this.wellKnownMeasurements.forEach(w => items.push({ label: w.displayName, value: w.name }));
    return items;
  }

  setConfigurationCols() {
    this.measurementConfigurationCols = [
      {field: 'measurementName', header: 'Measurement Name', width: '125px'},
      {field: 'wellKnownMeasurement', header: 'Well Known Measurement', width: '125px'},
      {field: 'dataType', header: 'Data Type', width: '70px'},
      {field: 'unitChoice', header: 'Unit', width: '70px'},
      {field: '', header: '', width: '55px'}
    ];
  }

  reportError(error: any, message: string) {
    if (error) {
     
    }
    const errorMessage = [{
      severity: 'error',
      summary: message,
      detail: ''
    }];
    this.errors.emit(errorMessage);
  }

  prepareConfigurationForUI(configuration: DeviceMeasurementChannelConfiguration[]): DeviceMeasurementChannelConfiguration[] {
    configuration.map(c => {
      c.unitOptions = this.setUnitSelectItems(c.wellKnownMeasurementName);
    });
    return configuration;
  }

  setUnitSelectItems(selectedWellKnownMeasurementName: string): SelectItem[] {
    const items: SelectItem[] = [];
    const selectedWellKnownMeasurement = this.wellKnownMeasurements.find(
      w => w.name === selectedWellKnownMeasurementName
    );

    if (selectedWellKnownMeasurement) {
      if (this.unitSystem === UnitSystemChoice.si) {
        selectedWellKnownMeasurement.siUnits.forEach(u => {
          items.push({ label: u.unitName, value: u.unitKey });
        });
      } else if (this.unitSystem === UnitSystemChoice.imperial) {
        selectedWellKnownMeasurement.imperialUnits.forEach(u => {
          items.push({ label: u.unitName, value: u.unitKey });
        });
      }
    }

    // Add dimensionless choice for text
    items.push({ label: 'N/A', value: 'Dimensionless' });

    return items;
  }

  setMeasurementType(selectedWellKnownMeasurementName: string): string {
    const selectedWellKnownMeasurement = this.wellKnownMeasurements.find(
      w => w.name === selectedWellKnownMeasurementName
    );
    if (selectedWellKnownMeasurement) {
      if (selectedWellKnownMeasurement.siUnits.length > 0) {
        if (selectedWellKnownMeasurement.siUnits.find(u => u.unitName === 'N/A')) {
          return 'Text';
        } else {
          return 'Number';
        }
      } else if (selectedWellKnownMeasurement.imperialUnits.length > 0) {
        if (selectedWellKnownMeasurement.imperialUnits.find(u => u.unitName === 'N/A')) {
          return 'Text';
        } else {
          return 'Number';
        }
      }
    }
    return null;
  }

  deleteMeasurementConfiguration(configuration: DeviceMeasurementChannelConfiguration) {
    this.deviceMeasurementChannelConfigurations = this.deviceMeasurementChannelConfigurations.filter(c =>
      c.measurementName !== configuration.measurementName
    );
  }

  /*********************** UI Triggered update methods ******************************/

  displayNameChanged(configuration: DeviceMeasurementChannelConfiguration) {
    configuration.setMeasurementName();
  }

  wellKnownMeasurementChanged(configuration: DeviceMeasurementChannelConfiguration) {
    configuration.unitOptions = this.setUnitSelectItems(configuration.wellKnownMeasurementName);
    const measurementType = this.setMeasurementType(configuration.wellKnownMeasurementName);
    if (measurementType) {
      configuration.measurementDataType = measurementType;
    }
  }

  measurementDataTypeChanged(configuration: DeviceMeasurementChannelConfiguration) {
    if (configuration.measurementDataType === 'Text') {
      configuration.unitOfMeasurementId = 'Dimensionless';
    }
  }

  unitsChanged(configuration: DeviceMeasurementChannelConfiguration) {}

  confirmDeleteConfiguration(configuration: DeviceMeasurementChannelConfiguration) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete ' + configuration.measurementName + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteMeasurementConfiguration(configuration); }// ,
        // reject: () => { }
      }
    );
  }

  addConfiguration() {

    this.deviceMeasurementChannelConfigurations.push(new DeviceMeasurementChannelConfiguration());
    this.deviceMeasurementChannelConfigurations = [...this.deviceMeasurementChannelConfigurations]
  }
}
