<div class="grid field col-12">
    <div class="grid field col-12">
        <!-- Fluid Density -->
        <div class="col-12 md:col-1">
            <label>Fluid Density</label>
        </div>
        <div class="col-12 md:col-3">
            <div >
                <input type="number" pInputText placeholder="Enter Fluid Density" [(ngModel)]="fluidSummary.fluidDensity" />
            </div>
        </div>

        <!-- Cost per Barrel -->
        <div class="col-12 md:col-1">
            <label>Cost per BBL</label>
        </div>
        <div class="col-12 md:col-3">
            $ <input type="number" pInputText placeholder="Enter Cost per BBL" [(ngModel)]="fluidSummary.costPerBbl" min="0" />
        </div>

        <!-- Type of Fluid -->
        <div class="col-12 md:col-1">
            <label>Type of Fluid</label>
        </div>
        <div class="col-12 md:col-3">
            <p-dropdown [options]="fluidTypes" [(ngModel)]="fluidSummary.fluidTypeId" placeholder="Select Fluid Type" [style]="{'width':'100%'}"></p-dropdown>
        </div>
    </div>
    <div class="grid field col-12">
        <!-- SLW Viscosity -->
        <div class="col-12 md:col-1">
            <label>SLW Viscosity</label>
        </div>
        <div class="col-12 md:col-3">
            <div >
                <input type="number" pInputText placeholder="Enter SLW Viscosity" [(ngModel)]="fluidSummary.slwViscosity" min="0" />
            </div>
        </div>

        <!-- Recirculate or Open Loop -->
        <div class="col-12 md:col-1">
            <label>Recirculate or Open Loop</label>
        </div>
        <div class="col-12 md:col-3">
            <p-selectButton [options]="loopTypes" [(ngModel)]="fluidSummary.loopTypeId"></p-selectButton>
        </div>

        <!-- Barrel Count -->
        <div class="col-12 md:col-1">
            <label>Barrel Count</label>
        </div>
        <div class="col-12 md:col-3">
            <input type="number" pInputText placeholder="Enter BBL Count" [(ngModel)]="fluidSummary.barrelCount" min="0" />
        </div>
    </div>
</div>

<div class="grid field col-12">
    <p-button 
        class="col-12 md:col-2 ui-lg-2"
        (click)="addChemical()"
        label="Add Chemical">
    </p-button>
</div>
<div class="grid field col-12">
    <p-table [value]="chemicals" dataKey="id" editMode="row" >
        <ng-template pTemplate="header">
            <tr>
                <th>Chemical</th>
                <th>System</th>
                <th>Dosage</th>
                <th>Cost</th>
                <th>Start at</th>
                <th>Depth or Plug</th>
                <th style="width:7em"></th>
                <th style="width:3em"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <span class="ui-column-title">Chemical</span>
                            <p-dropdown class="unbleed" [options]="chemicalTypes" [(ngModel)]="rowData.chemicalId" [style]="{'width':'100%'}"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ui-column-title">Chemical</span>
                            {{ lookupChemicalLabel(rowData.chemicalId) }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <span class="ui-column-title">System</span>
                            <p-dropdown class="unbleed" [options]="fluidSystems" [(ngModel)]="rowData.chemicalFluidSystemId" [style]="{'width':'100%'}"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ui-column-title">System</span>
                            {{ lookupChemicalFluidSystem(rowData.chemicalFluidSystemId) }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                                <span class="ui-column-title">Dosage</span>
                            <input type="number" pInputText size="5" [(ngModel)]="rowData.dosage" min="0">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ui-column-title">Dosage</span>
                            {{rowData.dosage}}
                            <span *ngIf="rowData.chemicalFluidSystemId == 'SLW'"> gal/10 bbl</span>
                            <span *ngIf="rowData.chemicalFluidSystemId != 'SLW'"> gal/swp</span>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <span class="ui-column-title">Cost</span>
                            $ <input type="number" pInputText size="10" [(ngModel)]="rowData.cost" min="0">
                        </ng-template>
                        <ng-template pTemplate="output">
                                <span class="ui-column-title">Cost</span>
                            $ {{rowData.cost}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <span class="ui-column-title">Start</span>
                            <p-dropdown class="unbleed" [options]="startAtOptions" [(ngModel)]="rowData.hasDepth" [style]="{'width':'100%'}"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                                <span class="ui-column-title">Start</span>
                            <span *ngIf="rowData.hasDepth">Depth</span>
                            <span *ngIf="!rowData.hasDepth">Plug</span>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <span class="ui-column-title">Depth or Plug</span>
                            <input type="number" pInputText [(ngModel)]="rowData.depthOrPlug" min="0">
                        </ng-template>
                        <ng-template pTemplate="output">
                                <span class="ui-column-title">Depth or Plug</span>
                            {{rowData.depthOrPlug}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td style="width:7em">
                    <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" (click)="onRowEditInit(rowData)"></button>
                    <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check" class="ui-button-success" style="margin-right: .5em" (click)="onRowEditSave(rowData)"></button>
                    <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times" class="p-button-danger" (click)="onRowEditCancel(rowData)"></button>
                </td>
                <td style="width:3em">
                    <p-button icon="pi pi-trash" (click)="remove(rowData)"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>