import { Component, OnInit, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { JobDetailsComponent } from '../job-details/job-details.component'
import { JobWellInformationComponent } from '../job-well-information/job-well-information.component'
import { JobFluidInformationComponent } from '../job-fluid-information/job-fluid-information.component'
import { JobPerformanceSummaryComponent } from '../job-performance-summary/job-performance-summary.component'
import { JobFluidSummaryComponent } from '../job-fluid-summary/job-fluid-summary.component'
import { FormsModule } from '@angular/forms';
import { VendorJob } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-vendor-job-common',
  
  standalone: false,
  templateUrl: './vendor-job-common.component.html',
  styleUrls: ['./vendor-job-common.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VendorJobCommonComponent),
    multi: true
  }]
})
export class VendorJobCommonComponent implements OnInit, ControlValueAccessor, OnChanges {

  private _onTouched: Function;

  @Input() _job: VendorJob;
  get job() {
    return this._job;
  }
  set job(val) {
    this._job = val;
    this.propagateChange(this._job);
  }
  @Input() supervisors: SelectItem[];
  @Input() modelInvalid: boolean;
  @Input() disabled = false;
  @Input() showWorkbookLink = true;
  @Input() consumerJobId: string;
  @Input() securityToken: string;

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  ngOnChanges() {
    const test = 'test';
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    if (obj) {
      this.job = obj;
    }
    // } else {
    //   this.job = new Job(null, null, null, [], null, null);
    // }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /****** Componet UI control methods ******/
  navigateToWorkbook() {
    // const decodedJobId = decodeURIComponent(this.job.id);
    if (this.job.useOldWorkbookInReadOnlyMode) {
      this._router.navigate(['/workbook/' + this.job.id]);
    } else if (this.consumerJobId) {
      this._router.navigate(['/workbook2/' + this.job.id + '/' + this.consumerJobId]);
    } else {
      this._router.navigate(['/workbook2/' + this.job.id]);
    }
  }

  downloadJobExport() {
    window.open('/api/vendorjob/export/' + this.job.id + '?token=' + this.securityToken, '_blank');
  }

  downloadJobSummaryExport() {
    window.open('/api/vendorjob/exportsummary/' + this.job.id + '?token=' + this.securityToken, '_blank');
  }
}
