import { Component, Inject, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { Title } from '@angular/platform-browser';
import moment from 'moment';

import { JobWorkbookConfiguration,
         WorkbookMeasurementChannnelConfiguration,
         Features } from '../../../models/models';
import { IJobWorkbookConfigurationService,
         ICompanyWorkbookConfigurationService,
         IWorkbookV2Service,
         IOpsViewerMessagesService,
         IUserService,
         IFeaturesService,
         IApplicationContextService} from '../../../services/services';
import { WorkbookLog, UnitChoice } from '../../../models/models';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { first } from 'rxjs/operators';
import { IVendorJobClient, User, UserClient, VendorJob } from 'src/app/services/nswag/nswagclient';
import { TelemetryService } from 'src/app/services/telemetry.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'offlineWorkbook-detail',
  templateUrl: './offlineWorkbook-detail.component.html',
  styleUrls: ['./offlineWorkbook-detail.component.css']
})
export class OfflineWorkbookDetailComponent implements OnInit {
    isNew = false;
    companyId: string;
    jobId: string;
    workbookLogId: string;
    allWorkbookLogs: WorkbookLog[];
    workbookLog: WorkbookLog;
    workbookLogReady = false;
    workbookLogCalendarTime: Date;
   // enableRoundTime = false;
    calendarTimeDisplay: string;

    configuration: JobWorkbookConfiguration;
    configurationReady = false;
    measurementsToShow: WorkbookMeasurementChannnelConfiguration[] = [];

    activities: SelectItem[] = [];
    activitiesReady = false;

    job: VendorJob;
    jobReady = false;

    user: User;
    userReady = false;

    isCompanyAdministrationEnabled = false;
    featuresReady = false;

    unitChoices: UnitChoice[] = [];

    modelInvalid = false;
    badTimeRange = false;
    timeExists = false;
    everythingReady = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private jobWorkbookConfigurationService: IJobWorkbookConfigurationService,
        private companyWorkbookConfigurationService: ICompanyWorkbookConfigurationService,
        private workbookService: IWorkbookV2Service,
        @Inject("IVendorJobClient") private jobService: IVendorJobClient,
        private featureService: IFeaturesService,
        private messageService: IOpsViewerMessagesService,
        private applicationContextService: IApplicationContextService,
        private _currentUserService : ICurrentUserService,
        private _userClient : UserClient,
        private _telemetry: TelemetryService
        ) {
            
        }

    ngOnInit() {
        this.getIdsFromRoute();
        this.getAuthenticatedUser();
        this.getFeatures();
    }

    getIdsFromRoute() {
        this.route.params.subscribe(params => {
            this.workbookLogId = decodeURIComponent(params['id']);
            this.companyId = decodeURIComponent(params['companyId']);
            this.jobId = decodeURIComponent(params['jobId']);
            if (!this.workbookLogId || this.workbookLogId.toUpperCase() === 'NEW') {
                this.isNew = true;
                this.getWorkbookLog(true);
            } else {
                this.getWorkbookLog(false);
            }
            this.getActivities();
            this.getUnitChoices();
            this.getWorkbookConfiguration();
            this.getJobDetails();
            this.setPageTitle(this.workbookLogId);
          });
    }

    setPageTitle(workbookLogId: string) {
        if (!workbookLogId || workbookLogId === 'New' || workbookLogId === '') {
            this.titleService.setTitle('New Workbook Log');
        } else { this.titleService.setTitle(workbookLogId); }
    }

    getActivities() {
        this.companyWorkbookConfigurationService.getActivities().subscribe(
            results => {
              this.activities = [];
              results.forEach(result => {
                this.activities.push({label: result, value: result});
              });
              this.activitiesReady = true;
              this.checkIfEverythingIsReady();
            },
            error => { this.messageService.errorMessage(error, 'Could not get Workbook Activities'); }
          );
    }

    getUnitChoices() {
        this.companyWorkbookConfigurationService.getUnitChoices().subscribe(
            result => {
                this.unitChoices = result;
            }
        );
    }

    getWorkbookConfiguration() {
        this.jobWorkbookConfigurationService.getJobWorkbookConfiguration(this.jobId).subscribe(
            result => {
              this.configuration = result;
              this.configuration.workbookMeasurementChannelConfigurations.forEach(c => {
                    c.setWorkbookPropertyName();
               });
              this.configurationReady = true;
              this.checkIfEverythingIsReady();
            },
            error => { this.messageService.errorMessage(error, 'Could not get Workbook Configuration'); }
          );
    }

    getWorkbookLog(createNew: boolean) {
        this.workbookService.get(this.jobId).subscribe(
            results => {
                if (results) {
                    this.allWorkbookLogs = results;
                    if (createNew) {
                        this.workbookLog = this.createNewWorkbookLog();
                        this.allWorkbookLogs.push(this.workbookLog);
                    } else {
                        this.workbookLog = results.find(r => r.id === this.workbookLogId);
                        if (!this.workbookLog){
                            this._telemetry.trackTrace( {message:'Workbook Log not found Workbook Log Id:' + this.workbookLogId, severityLevel:SeverityLevel.Warning});
                            this.messageService.warnMessage('Workbook Log not found', 'Workbook Log Id:' + this.workbookLogId);
                            return;
                        }
                    }
                    this.setCalendarDates();
                    this.workbookLogReady = true;
                    this.checkIfEverythingIsReady();
                } else {
                    this.messageService.warnMessage('Workbook Log not found', 'Workbook Log Id:' + this.workbookLogId);
                }
            },
            error => { this.messageService.errorMessage(error, 'Could not get Workbook Log'); }
        );
    }

    getJobDetails() {
        this.jobService.getVendorJobById(this.jobId).subscribe(
            result => {
                this.job = result;
                this.jobReady = true;
                this.checkIfEverythingIsReady();
            },
            error => { this.messageService.errorMessage(error, 'Could not get Job Details'); }
        );
    }

    getAuthenticatedUser() {
        let userEmail = this._currentUserService.getUserEmail();
        if (userEmail) {
            this._userClient.getByEmail(userEmail).subscribe(
            user => {
                this.user = user;
                this.userReady = true;
                this.checkIfEverythingIsReady();
            },
            error => { this.messageService.errorMessage(error, 'Could not get User'); }
            );
        }
    }
      

      getFeatures() {
        this.featureService.isFeatureEnabled(Features.CompanyAdministration).subscribe(
          result => {
            this.isCompanyAdministrationEnabled = result;
            this.featuresReady = true;
            this.checkIfEverythingIsReady();
          },
          error => { this.messageService.errorMessage(error, 'Could not get Company Administration Feature'); }
        );
      }

    createNewWorkbookLog(): WorkbookLog {
        const workbookLog = new WorkbookLog();
        workbookLog.id = uuidv4();
        workbookLog.jobId = this.jobId;
        workbookLog.Time = new Date();
        return workbookLog;
    }

    // To bind date to PrimeNg calendar, need to create a new JS Date from model date
    setCalendarDates() {
        this.workbookLogCalendarTime = new Date(this.workbookLog.Time);
    }

    // SetWorkbook Date from decoupled CalendarDate
    setWorkbookLogDates(): boolean {
        // In case calendar control was never opened and set time
        // is not repeated for activity
        if (!this.validateTime(false)) {
            return false;
        }

        // Check to make sure user input is a valid time input
        const momentDate = moment(this.workbookLogCalendarTime);
        if (!momentDate.isValid()) {
            return false;
        }
        this.workbookLog.Time = new Date(this.workbookLogCalendarTime);
        return true;
    }


    validateTime(alreadyRounded: boolean): boolean {
        let timeValid = false;
        // All Log times should be rounded to minute
        if (!alreadyRounded) {
            const m = moment(this.workbookLogCalendarTime);
            this.workbookLogCalendarTime = m.startOf('minute').toDate();
        }

        // Only one log per time per activity can be entered
        const existingLog = this.allWorkbookLogs.find(l => {
            if (l.id !== this.workbookLog.id && l.activity === this.workbookLog.activity) {
                const logTime = moment(l.Time);
                const calendarTime = moment(this.workbookLogCalendarTime);
                return logTime.isSame(calendarTime);
            } else {
                return false;
            }
        });
        if (existingLog) {
            timeValid = false;
            this.timeExists = true;
        } else {
            timeValid = true;
            this.timeExists = false;
        }

        return timeValid;
    }

    checkIfEverythingIsReady() {
        this.everythingReady = this.activitiesReady &&
                               this.configurationReady &&
                               this.workbookLogReady &&
                               this.jobReady &&
                               this.userReady &&
                               this.featuresReady;
        if (this.everythingReady) {
            this.activityChosen();


        }
    }

    activityChosen() {
        if (this.workbookLog.activity) {
            this.measurementsToShow = this.configuration.workbookMeasurementChannelConfigurations.filter(
                m => m.activities.includes(this.workbookLog.activity));
        }
    }

    isNumberConfig(configurationChannel: WorkbookMeasurementChannnelConfiguration) {
        return (configurationChannel.measurementDataType === 'Number');
    }

    isTextConfig(configurationChannel: WorkbookMeasurementChannnelConfiguration) {
        return (configurationChannel.measurementDataType === 'Text') &&
               (!configurationChannel.lookupChoices || configurationChannel.lookupChoices.length === 0);
    }

    isLookupConfig(configurationChannel: WorkbookMeasurementChannnelConfiguration) {
        const isLookup = (configurationChannel.measurementDataType === 'Text') &&
                       (configurationChannel.lookupChoices && configurationChannel.lookupChoices.length > 0);
        if (isLookup) {
            configurationChannel.lookupChoicesAsSelectItems = [];
            configurationChannel.lookupChoices.forEach(c => {
                configurationChannel.lookupChoicesAsSelectItems.push({ label: c, value: c });
            });
        }
        return isLookup;
    }

    isVendor(): boolean {
        if (this.userReady) {
          if (this.user.isGlobalAdmin) {
            return true;
          }
          if (this.isCompanyAdministrationEnabled) {
            if (this.user.companyRoles.some(r => r.companyId === this.companyId)) {
              return true;
            }
          } else {
            return true;
          }
        }

        this.messageService.warnMessage('User does not have permissions to Edit this Workbook', '');
        return false;
      }

    getUnitName(unitId: string): string {
        const unitChoice = this.unitChoices.find(u => u.unitKey === unitId);
        return (unitChoice && unitChoice.unitKey !== 'Dimensionless') ? '(' + unitChoice.unitName + ')' : '';
    }

    saveWorkbookLog(saveAndNew: boolean) {
        this.showSpinner();
        if (!this.checkIfModelIsInvalid()) {
            this.workbookService.addOrUpdate(this.workbookLog, this.allWorkbookLogs).subscribe(
                result => {
                    this.hideSpinner();
                    if (saveAndNew) {
                        this.loadNewWorkbookLog();
                    } else {
                        this.returnToWorkbook();
                    }
                },
                error => { this.messageService.errorMessage(error, 'Could not save Workbook'); }
            );
        } else {
            this.hideSpinner();
        }
    }

    checkIfModelIsInvalid(): boolean {
        this.modelInvalid = false;
        this.badTimeRange = false;

        if (!this.isVendor()) {
            this.modelInvalid = true;
            this.messageService.warnMessage('Validation Error', 'User does not have permissions to update the workbook for this job');
        }

        const couldSetTime = this.setWorkbookLogDates();
        if (couldSetTime) {
           // Check Time is Set and In Job Details range
            if (this.workbookLog.Time && this.workbookLogCalendarTime) {
                if (this.job.startTime) {
                    const jobStartTime = new Date(this.job.startTime);
                    if (jobStartTime > this.workbookLog.Time) {
                        this.modelInvalid = true;
                        this.badTimeRange = true;
                    }
                }
                if (this.job.endTime) {
                    const jobEndTime = new Date(this.job.endTime);
                    if (jobEndTime < this.workbookLog.Time) {
                        this.modelInvalid = true;
                        this.badTimeRange = true;
                    }
                }
            } else {
                this.modelInvalid = true;
            }
        } else {
            this.modelInvalid = true;
            this.messageService.warnMessage('Workbook Log Time not valid', '');
        }

        // Check Activity is set
        if (!this.workbookLog.activity) {
            this.modelInvalid = true;
        }

        // Validate no inputs out of range
        Object.keys(this.workbookLog).forEach( k => {
            const measurement = this.measurementsToShow.find(m => m.workbookPropertyName === k);
            if (measurement && this.isOutOfRange(measurement)) {
                this.modelInvalid = true;
            }
        });

        return this.modelInvalid;
    }

    loadNewWorkbookLog() {
        this.workbookLog = this.createNewWorkbookLog();
        // if (this.enableRoundTime) {
        //     this.roundTime();
        // }
        this.measurementsToShow = [];
        this.router.navigate(['/workbook2/detail/' + encodeURIComponent(this.companyId) + '/' + this.jobId + '/new']);
    }

    returnToWorkbook() {
        this.router.navigate(['/workbook2/' + this.jobId]);
    }

    showSpinner() {
        if (this.isNew) {
            this.applicationContextService.showApplicationSpinner(false);
        } else {
            this.applicationContextService.showApplicationSpinner(true);
        }
    }

    hideSpinner() {
        this.applicationContextService.hideApplicationSpinner();
    }

    isOutOfRange(measurement: WorkbookMeasurementChannnelConfiguration): boolean {
        if (measurement.mininumLimit) {
            const value = this.workbookLog[measurement.workbookPropertyName];
            if (typeof value === 'number' && value < measurement.mininumLimit) {
                return true;
            }
        }
        if (measurement.maximumLimit) {
            const value = this.workbookLog[measurement.workbookPropertyName];
            if (typeof value === 'number' && value > measurement.maximumLimit) {
                return true;
            }
        }
        return false;
    }
    getRangeError(measurement: WorkbookMeasurementChannnelConfiguration): string {
        return `Input out of range (${measurement.mininumLimit} to ${measurement.maximumLimit}).  If input is valid, adjust min and max limits in the job's workbook configuration.`
    }
}
