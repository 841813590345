import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FeatureGuard } from '../guards/feature.guard';
import { JobSimulatorComponent } from './job-simulator/job-simulator.component';
import { SimulationsComponent } from './simulations/simulations.component';
import { SimulationListComponent } from './simulation-list/simulation-list.component';

const routes: Routes = [
  {
    path: 'simulator',
    component: SimulationsComponent,
    canActivate: [FeatureGuard],
    children:[
      {
        path: '',
        canActivateChild: [FeatureGuard],
        children: [
          { path: 'jobsimulator/:id', component: JobSimulatorComponent },
          { path: 'jobsimulator', component: JobSimulatorComponent },
          { path: '', component: SimulationListComponent },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],

  exports: [RouterModule]
})
export class SimulatorRoutingModule { }
