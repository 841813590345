import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IJobLinkerService, IApplicationContextService, IOpsViewerMessagesService } from 'src/app/services/services';
import { JobLinker } from 'src/app/models/models';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { getWorkbookTypesSelection } from 'src/app/models/workbookJobTypes';
import { firstValueFrom } from 'rxjs';
import { ConsumerJob, VendorJob } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-job-linker',
  templateUrl: './job-linker.component.html',
  styleUrls: ['./job-linker.component.css']
})
export class JobLinkerComponent implements OnInit {

  private _job: VendorJob = null;
  get job(): VendorJob {
    return this._job;
  }
  @Input()
  set job(val: VendorJob) {
    // If first time loading the component
    //  then just set the value of job
    // else job details are changing and need to rebuild joblinking
    if (!this.initialized) {
      this._job = val;
    } else {
      this.initializeJobLinkers(val).then(r => this._job = val);
    }
  }
  @Input() jobLinkerId: string = null;
  @Input() vendorJobId: string = null;
  @Input() showActiveForDate: Date = null;
  @Input() forAdmin = false;
  @Input() companySelectItems: SelectItem[] = [];
  @Input() vendorJobSelectItems: SelectItem[] = [];
  @Input() consumerJobs: ConsumerJob[] = [];
  @Output() selectedJobLinker = new EventEmitter<JobLinker>();
  public jobLinkers: JobLinker[] = [];
  public showTable = true;
  public showEdit = false;
  public jobLinkerToEdit: JobLinker = null;
  public jobTypes: SelectItem[] = [];
  public statuses: SelectItem[] = [];
  private initialized = false;

  constructor(
    private _jobLinkerService: IJobLinkerService,
    private _applicationContextService: IApplicationContextService,
    private _messageService: IOpsViewerMessagesService,
    private _confirmationService: ConfirmationService) { }

  async ngOnInit() {
    await this.initializeJobLinkers(this._job);
    this.initialized = true;
  }

  async initializeJobLinkers(newJob: VendorJob): Promise<void> {
    this.jobTypes = getWorkbookTypesSelection();
    this.statuses = this.setupStatuses();
    if (newJob) {
      this.jobLinkers = await this.getJobLinkersToListFromJob(
        this.jobLinkers,
        newJob,
        this.companySelectItems,
        this.vendorJobSelectItems)
        .then(r => this.jobLinkers = r);
    } else {
      this.jobLinkers = await this.getJobLinkersToList(
        this.jobLinkerId,
        this.vendorJobId,
        this.showActiveForDate,
        this.forAdmin,
        this.companySelectItems,
        this.vendorJobSelectItems)
        .then(r => this.jobLinkers = r);
    }
    if (this.jobLinkers && this.jobLinkers.length > 0) {
      this.selectedJobLinker.emit(this.jobLinkers[0]);
    }
  }

  async getJobLinkersToList(
    jobLinkerId: string = null,
    vendorJobId: string = null,
    showActiveForDate: Date = null,
    forAdmin = null,
    companies: SelectItem[],
    vendorJobs: SelectItem[]): Promise<JobLinker[]> {
    if (jobLinkerId) {
      return await this.getKnownJobLinker(jobLinkerId, companies, vendorJobs);
    }
    if (vendorJobId) {
      return await this.getJobLinkerForVendorJob(vendorJobId, companies, vendorJobs);
    }
    if (showActiveForDate) {
      return await this.getActiveJobLinkers(showActiveForDate, companies, vendorJobs);
    }
    if (forAdmin) {
      return await this.getAllJobLinkers(companies, vendorJobs);
    }
    return[];
  }

  async getJobLinkersToListFromJob(
    currentJobLinkers: JobLinker[],
    newJob: VendorJob,
    companies: SelectItem[],
    vendorJobs: SelectItem[]): Promise<JobLinker[]> {
    let currentJobLinker: JobLinker = null;
    if (currentJobLinkers && currentJobLinkers.length > 0) {
      currentJobLinker = currentJobLinkers[0];
    }
    if (this.hasJobInfoChanged(currentJobLinker, newJob)) {
      return await this.getBestFitJobLinker(newJob, companies, vendorJobs);
    }
    return currentJobLinkers;
  }

  private hasJobInfoChanged(currentJobLinker: JobLinker, newJob: VendorJob): boolean {
    if (!currentJobLinker && !newJob) { return false; }
    if (!currentJobLinker && newJob) { return true; }
    if (currentJobLinker.consumerId !== newJob.consumerCompanyId) { return true; }
    if (currentJobLinker.apiNumber !== newJob.apiNumber) { return true; }
    if (this.hasWellInfoChanged(currentJobLinker, newJob)) { return true; }
    if (currentJobLinker.jobType !== newJob.workbookType) { return true; }
    if (currentJobLinker.consumerJobStartTime !== newJob.startTime) { return true; }
    return false;
  }

  private hasWellInfoChanged(currentJobLinker: JobLinker, newJob: VendorJob): boolean {
    if (currentJobLinker.wellName) {
      if (newJob.wellInfo) {
        if (currentJobLinker.wellName !== newJob.wellInfo.name) { return true; }
      } else { return true; }
    } else if (newJob.wellInfo.name) { return true; }
    return false;
  }

  private async getBestFitJobLinker(job: VendorJob, companies: SelectItem[], vendorJobs: SelectItem[]): Promise<JobLinker[]> {
    if (!job) { return []; }
    if (!job.workbookType) { return []; }
    if (!job.apiNumber) { return []; }
    
    try {
      const foundOrCreatedJobLinker = await firstValueFrom(this._jobLinkerService.findBestFitOrCreateNewJobLinker(job));
      if (foundOrCreatedJobLinker) {
        foundOrCreatedJobLinker.consumer = this.getCompanyNameForJobLinker(foundOrCreatedJobLinker, companies);
        foundOrCreatedJobLinker.consumerJobStatus = this.getConsumerJobStatusForJobLinker(foundOrCreatedJobLinker);
        foundOrCreatedJobLinker.vendorJobNames = this.getVendorJobNames(foundOrCreatedJobLinker, vendorJobs);
        return [foundOrCreatedJobLinker];
      }
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get best fit Job Linker for Job');
    }
    return [];
  }

  private async getKnownJobLinker(jobLinkerId: string, companies: SelectItem[], vendorJobs: SelectItem[]): Promise<JobLinker[]> {
    try {
      const foundJobLinker = await firstValueFrom(this._jobLinkerService.getJobLinkerById(jobLinkerId));
      if (foundJobLinker) {
        foundJobLinker.consumer = this.getCompanyNameForJobLinker(foundJobLinker, companies);
        foundJobLinker.consumerJobStatus = this.getConsumerJobStatusForJobLinker(foundJobLinker);
        foundJobLinker.vendorJobNames = this.getVendorJobNames(foundJobLinker, vendorJobs);
        return [foundJobLinker];
      }
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get requested Job Linking');
    }
    return [];
  }

  private async getJobLinkerForVendorJob(vendorJobId: string, companies: SelectItem[], vendorJobs: SelectItem[]): Promise<JobLinker[]> {
    try {
      const foundJobLinker = await firstValueFrom(this._jobLinkerService.getJobLinkerForVendorJobId(vendorJobId));
      if (foundJobLinker) {
        foundJobLinker.consumer = this.getCompanyNameForJobLinker(foundJobLinker, companies);
        foundJobLinker.consumerJobStatus = this.getConsumerJobStatusForJobLinker(foundJobLinker);
        foundJobLinker.vendorJobNames = this.getVendorJobNames(foundJobLinker, vendorJobs);
        return [foundJobLinker];
      }
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get existing Job Linking for Vendor Job');
    }
    return [];
  }

  private async getAllJobLinkers(companies: SelectItem[], vendorJobs: SelectItem[]): Promise<JobLinker[]> {
    try {
      const jobLinkers = await firstValueFrom(this._jobLinkerService.getJobLinkers());
      if (jobLinkers) {
        jobLinkers.forEach(j => {
          j.consumer = this.getCompanyNameForJobLinker(j, companies);
          j.consumerJobStatus = this.getConsumerJobStatusForJobLinker(j);
          j.vendorJobNames = this.getVendorJobNames(j, vendorJobs);
        });
      }
      return jobLinkers;
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get Job Linking');
    }
    return [];
  }

  private async getActiveJobLinkers(forDate: Date, companies: SelectItem[], vendorJobs: SelectItem[]): Promise<JobLinker[]> {
    try {
      const jobLinkers = await firstValueFrom(this._jobLinkerService.getActiveJobLinkers(forDate));
      if (jobLinkers) {
        jobLinkers.forEach(j => {
          j.consumer = this.getCompanyNameForJobLinker(j, companies);
          j.consumerJobStatus = this.getConsumerJobStatusForJobLinker(j);
          j.vendorJobNames = this.getVendorJobNames(j, vendorJobs);
        });
      }
      return jobLinkers;
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not get Active Job Linkings');
    }
    return [];
  }

  private getCompanyNameForJobLinker(jobLinker: JobLinker, companies: SelectItem[]): string {
    if (jobLinker && jobLinker.consumerId && companies) {
      const foundCompany = companies.find(c => c.value === jobLinker.consumerId);
      if (foundCompany) {
        return foundCompany.label;
      }
    }
    return '';
  }

  private getConsumerJobStatusForJobLinker(jobLinker: JobLinker): string {
    if (jobLinker && jobLinker.consumerJobId) {
      return 'In Progress';
    }
    return 'Not Started';
  }



  private setupStatuses(): SelectItem[] {
    return [
      { label: 'In Progress', value: 'In Progress' },
      { label: 'Not Started', value: 'Not Started' }
    ];
  }

  private getVendorJobNames(jobLinker: JobLinker, vendorJobSelectItems: SelectItem[]): string {
    const vendorJobNames: string[] = [];
    if (vendorJobSelectItems) {
      jobLinker.vendorJobIds.map(i => {
        const foundName = vendorJobSelectItems.find(v => v.value === i);
        if (foundName) {
          vendorJobNames.push(foundName.label);
        }
      });
    }
    return vendorJobNames.join(',');
  }

  // ***** Button Actions with side effects *****

  openJobLinkerToEditClicked(jobLinker: JobLinker) {
    this.jobLinkerToEdit = jobLinker;
    this.showEdit = true;
  }

  async addJobLinker(jobLinker: JobLinker) {
    try {
      this._applicationContextService.showApplicationSpinner(false);
      await firstValueFrom(this._jobLinkerService.createJobLinker(jobLinker));
      // Update UI
      jobLinker.consumer = this.getCompanyNameForJobLinker(jobLinker, this.companySelectItems);
      jobLinker.consumerJobStatus = this.getConsumerJobStatusForJobLinker(jobLinker);
      this.jobLinkers.push(jobLinker);
      this.showEdit = false;

      this._applicationContextService.hideApplicationSpinner();
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not create Job Linking');
    }
  }

  async updateJobLinker(jobLinker: JobLinker) {
    try {
      this._applicationContextService.showApplicationSpinner(false);
      await firstValueFrom(this._jobLinkerService.updateJobLinker(jobLinker));
      // Update UI
      jobLinker.consumer = this.getCompanyNameForJobLinker(jobLinker, this.companySelectItems);
      jobLinker.consumerJobStatus = this.getConsumerJobStatusForJobLinker(jobLinker);
      this.jobLinkers.forEach(j => {
        if (j.id === jobLinker.id) {
          j = jobLinker;
        }
      });
      this.showEdit = false;

      this._applicationContextService.hideApplicationSpinner();
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not update Job Linking');
    }
  }

  jobLinkerDeleteClicked(jobLinker: JobLinker) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete this Job Linking?',
        icon: 'fa fa-question-circle',
        accept: async () => { await this.deleteJobLinker(jobLinker); }// ,
        // reject: () => { }
      });
  }

  private async deleteJobLinker(jobLinker: JobLinker) {
    try {
      this._applicationContextService.showApplicationSpinner(false);
      await firstValueFrom(this._jobLinkerService.deleteJobLinker(jobLinker.id));
      this._applicationContextService.hideApplicationSpinner();
    } catch (error) {
      this._messageService.errorMessage(error, 'Could not Delete Job Linkings');
    }
    this.jobLinkers = this.jobLinkers.filter(j => j.id !== jobLinker.id);
  }

  toggleShowEditClicked(value: boolean) {
    this.showEdit = value;
  }
}

