import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IWorkbookV2Service } from './iWorkbookV2.service';
import { WorkbookLog } from '../models/models';
import { IOfflineService } from './iOffline.service';
import { IOfflineWorkbookService } from './iOfflineWorkbook.service';
import { Operation } from './offlineworkbook.service';
import moment from 'moment';

@Injectable()
export class WorkbookV2Service extends IWorkbookV2Service {
    constructor(private _http: HttpClient, private _offlineWorkbook: IOfflineWorkbookService, private _offlineService: IOfflineService) {
        super();
        this._offlineService.isOfflineObservable().subscribe(offline => {
            if (!offline) {
                this.syncOfflineQueue();
            }
        });
        // todo: merge this method and the one above
        this._offlineService.isOffline().subscribe(offline => {
            if (!offline) {
                this.syncOfflineQueue();
            }
        });
    }
    _operations = [];
    private syncOfflineQueue() {
        this._offlineWorkbook.getQueueEntries(null).then(entries => {
            entries.forEach(entry => {
                switch (entry.operation) {
                    case Operation.Update:
                        // tslint:disable-next-line:no-console
                        console.info(`updating offline item`, entry);
                        // we are adding to operations here because if don't hold a reference the obserable will not be called
                        this._operations.push(this._http.post(`/api/Workbook`, entry.workbookLog).subscribe(() => {
                            // tslint:disable-next-line:no-console
                            console.debug('updated');
                            this._offlineWorkbook.removeQueuedEntry(entry);
                        }
                        , e => console.error(e)));
                        break;
                    case Operation.Delete:
                        // tslint:disable-next-line:no-console
                        console.info(`updating offline item`, entry);
                        // we are adding to operations here because if don't hold a reference the obserable will not be called
                        this._operations.push(this._http.delete(`/api/Workbook/${entry.id}`).subscribe(() => {
                            // tslint:disable-next-line:no-console
                            console.debug('run offline delete');
                            this._offlineWorkbook.removeQueuedEntry(entry);
                        }
                        , e => console.error(e)));
                        
                        break;
                }
            });
        });
    }
    get(jobId: string): Observable<WorkbookLog[]> {
        // merge offline queue
        return this._http.get<WorkbookLog[]>(`/api/Workbook/GetByJobId/${jobId}`).pipe(switchMap(async logs => {
            const entires = await this._offlineWorkbook.getQueueEntries(jobId);
            const filteredLogs = logs.filter(l => entires.every(e => e.id !== l.id));
            entires.forEach(entry => {
                switch (entry.operation) {
                    case Operation.Insert:
                    case Operation.Update:
                        filteredLogs.push(entry.workbookLog);
                        break;
                    case Operation.Delete:
                        break;
                }
            });
            filteredLogs.sort((a, b) => b.Time.valueOf() - a.Time.valueOf());
            return filteredLogs;
        }
        ));
    }

    addOrUpdate(workbookLogToUpdate: WorkbookLog, allJobWorkbookLogs: WorkbookLog[]): Observable<any> {
        return new Observable<any>(observer => {
            // No longer storing the Relative time server side and only calculating the relative time on load
            // so that multiple updates do not need to happen when RIH-Start is changed.
            // const affectedLogs = this.calculateRelativeTime(workbookLogToUpdate, allJobWorkbookLogs);
            const affectedLogs: WorkbookLog[] = [ workbookLogToUpdate ];
            this.addOrUpdateBatch(affectedLogs).subscribe(() => {
                observer.next();
                observer.complete();
            });
        });
    }

    delete(log: WorkbookLog): Observable<any> {
        return this._offlineService.isOffline().pipe(switchMap(offline => {
            if (offline) {
                return from(this._offlineWorkbook.queueDelete(log));
            } else {
                return this._http.delete(`/api/Workbook/${log.id}`);
            }
        }));
    }

    calculateRelativeTime(workbookLogToUpdate: WorkbookLog, allJobWorkbookLogs: WorkbookLog[]): WorkbookLog[] {
        // If Workbook Log being added or modified has activity "RIH-Start"
        // Then the relativeTimes for each workbook row need to be updated
        if (workbookLogToUpdate.activity === 'RIH-Start') {
            const rihStartDate = moment(workbookLogToUpdate.Time);
            allJobWorkbookLogs.forEach(log => {
                const currentTime = moment(log.Time);
                log.relativeTime = moment.duration(currentTime.diff(rihStartDate)).asHours();
            });
            return allJobWorkbookLogs;
        }

        // If Workbook Log does not have "RIH-Start" actvity
        // Then calculate relative Time for this one row.
        //  - If there are not yet any "RIH-Start" events, relative Time is 0
        workbookLogToUpdate.relativeTime = 0;
        const foundRihStartEvent = allJobWorkbookLogs.find(log => log.activity === 'RIH-Start');
        if (foundRihStartEvent) {
            const rihStartDate = moment(foundRihStartEvent.Time);
            const currentTime = moment(workbookLogToUpdate.Time);
            workbookLogToUpdate.relativeTime = moment.duration(currentTime.diff(rihStartDate)).asHours();
        }
        return [workbookLogToUpdate];
    }

    calculateRelativeTimeValue(workbookLogToUpdate: WorkbookLog, allJobWorkbookLogs: WorkbookLog[]): number {
        if (workbookLogToUpdate.activity === 'RIH-Start') {
          return 0;
        } else {
          const foundRihStartEvent = allJobWorkbookLogs.find(log => log.activity === 'RIH-Start');
          if (foundRihStartEvent) {
            const rihStartDate = moment(foundRihStartEvent.Time);
            const currentTime = moment(workbookLogToUpdate.Time);
            return moment.duration(currentTime.diff(rihStartDate)).asHours();
        }
      }
    }

    addOrUpdateSingle(workbookLogToUpdate: WorkbookLog): Observable<any> {
        return this._offlineService.isOffline().pipe(switchMap(offline => {
            if (offline) {
                // tslint:disable-next-line:no-console
                console.info(`offline so queueing`, workbookLogToUpdate);
                return from(this._offlineWorkbook.queueUpdate(workbookLogToUpdate));
            } else {
                return this._http.post(`/api/Workbook`, workbookLogToUpdate);
            }
        }));
    }

    addOrUpdateBatch(workbookLogsToUpdate: WorkbookLog[]): Observable<any> {
        return new Observable<any>(observer => {
            const numberOfUpdatesRequired = workbookLogsToUpdate.length;
            let updatesCount = 0;
            workbookLogsToUpdate.forEach(log => {
                this.addOrUpdateSingle(log).subscribe(() => {
                    updatesCount++;
                    if (numberOfUpdatesRequired === updatesCount) {
                        observer.next();
                        observer.complete();
                    }
                });
            });
        });
    }

}
