import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { JobLinker} from '../models/models';
import { VendorJob } from './nswag/nswagclient';

@Injectable()
export abstract class IJobLinkerService {
  abstract getJobLinkers(): Observable<JobLinker[]>;
  abstract getJobLinkerById(id: string): Observable<JobLinker>;
  abstract createJobLinker(jobLinker: JobLinker): Observable<any>;
  abstract updateJobLinker(jobLinker: JobLinker): Observable<any>;
  abstract deleteJobLinker(id: string): Observable<any>;
  abstract findBestFitOrCreateNewJobLinker(job: VendorJob): Observable<JobLinker>;
  abstract getActiveJobLinkers(forDate: Date): Observable<JobLinker[]>;
  abstract associateVendorJobToJobLinker(vendorJobId: string, jobLinker: JobLinker): Observable<any>;
  abstract getJobLinkerForVendorJobId(vendorJobId: string): Observable<JobLinker>;
}
