import { Component, OnInit, LOCALE_ID, Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { JobWorkbookConfiguration, Features } from '../../models/models';
import { 
         IJobWorkbookConfigurationService,
         IUserService,
         IBootstrapWorkbookForOfflineService,
         ICompanyWorkbookConfigurationService,
         IFeaturesService,
         IOpsViewerMessagesService,
         IApplicationContextService} from '../../services/services';
import { Title } from '@angular/platform-browser';
import { WorkbookLog, UnitChoice } from '../../models/models';
import { IWorkbookV2Service } from 'src/app/services/iWorkbookV2.service';
import { first } from 'rxjs/operators';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { TableModule } from 'primeng/table';
import { IVendorJobClient, User } from 'src/app/services/nswag/nswagclient';
import { firstValueFrom } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'offlineWorkbook',
  
  standalone: false,
  templateUrl: './offlineWorkbook.component.html',
  styleUrls: ['./offlineWorkbook.component.scss']
})
export class OfflineWorkbookComponent implements OnInit {

    // static storedJobIds: Boolean[];

    jobId: string;
    companyId: string;
    configuration: JobWorkbookConfiguration;
    configurationReady = false;
    unitChoices: UnitChoice[] = [];
    workbookData: WorkbookLog[] = [];
    workbookDataReady = false;
    frozenCols: any[] = [];
    scrollableCols: any[] = [];
    everythingReady = false;
    isCompanyAdministrationEnabled = false;
    featuresReady = false;
    user: User;
    userReady = false;
    consumerJobOriginId: string = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private confirmationService: ConfirmationService,
        @Inject("IVendorJobClient") private jobService: IVendorJobClient,
        private jobWorkbookConfigurationService: IJobWorkbookConfigurationService,
        private companyWorkbookConfigurationService: ICompanyWorkbookConfigurationService,
        private workbookService: IWorkbookV2Service,
        private userService: IUserService,
        private featureService: IFeaturesService,
        private bootstrapWorkbookForOfflineService: IBootstrapWorkbookForOfflineService,
        private _messageService: IOpsViewerMessagesService,
        private _applicationContextService: IApplicationContextService,
        private _userManagerService: IUserManagerService,
        @Inject(LOCALE_ID) private locale: string
        ) {}

    ngOnInit() {
        this.getRouteParams();
        this.getFeatures();
        this.getAuthenticatedUser();
    }

    getRouteParams() {
        this.route.params.subscribe(params => {
            this.jobId = params['id'];
            this.consumerJobOriginId = params['consumerJobId'];
            if (this.jobId == null || this.jobId === '') {
              this._messageService.warnMessage('Job Id not found', 'Check URL path');
            } else {
              this.getCompanyId();
              this.getUnitChoices();
             // this.getWorkbookData(this.jobId);
              this.bootstrapWorkbookForOffline(this.jobId);
            }
            this.setPageTitle(this.jobId);
          });
    }


    getFeatures() {
      this.featureService.isFeatureEnabled(Features.CompanyAdministration).subscribe(
        result => {
          this.isCompanyAdministrationEnabled = result;
          this.featuresReady = true;
          this.checkIfEverythingReady();
        },
        error => { this.handleError(error, 'Could not get features'); }
      );
    }

    getAuthenticatedUser() {

      this._userManagerService.getCurrentUser().subscribe(u=>{
        this.user = u;
        this.userReady = true;
        this.checkIfEverythingReady();
      });
    }
  

    bootstrapWorkbookForOffline(jobId: string) {
        this.bootstrapWorkbookForOfflineService.cacheWorkbookDetailServices(jobId).subscribe(
            () => {},
            error => { this._messageService.errorMessage(error, 'Could not sync Workbook data for offline'); }
        );
    }

    setPageTitle(jobId: string) {
        if (!jobId || jobId === 'New' || jobId === '') {
            this.titleService.setTitle('New Job');
        } else { this.titleService.setTitle(jobId); }
    }

    getCompanyId() {
        this.jobService.getCompanyIdForJob(this.jobId).subscribe(
            result => {
                this.companyId = result;
                this.getJobWorkbookConfiguration();
            },
            error => { 
              this._messageService.errorMessage(error, 'Could not get Company Id');
              if (error && error.error && error.error.Message && error.error.Message.includes('Request document of wrong type')) {
                this._messageService.warnMessage('ID for wrong job type was passed: An Operator Job ID was attempted to query a Service Provider Job Workbook','Verify link from the dashboard is correct');
                this._messageService.errorNoUiMessage(
                  'WrongJobTypeRequestError',
                  'Attempted to access a Vendor Job Workbook with Consumer Job Id.  Job Id requested: (' +
                  this.jobId +
                  ').  ConsumerJobOriginId set as (' +
                  this.consumerJobOriginId +
                  ').  Company Id set as (' +
                  this.companyId +
                  '). Router snapshot: ' + JSON.stringify(this.route.snapshot.url));
              }
            }
        );
    }

    getJobWorkbookConfiguration() {
        this.jobWorkbookConfigurationService.getJobWorkbookConfiguration(this.jobId).subscribe(
            result => {
              if (result && result.workbookMeasurementChannelConfigurations.length >0) {
                this.configuration = result;
                this.configuration.workbookMeasurementChannelConfigurations.forEach(c => {
                    c.setWorkbookPropertyName();
               });
                this.setWorkbookColumns();
                this.getWorkbookData(this.jobId);
              } else {
               this._messageService.warnMessage(
                  'Could not get Workbook Configuration', 'Workbook Configuration found for this job is not defined');
              }
            },
            error => { this._messageService.errorMessage(error, 'Could not get Workbook Configuration'); }
        );
    }

    setWorkbookColumns() {
        if (this.configuration && this.unitChoices.length > 0) {
            const width = '200px';

            this.frozenCols = [
                {field: 'icons', header: '', unit: '', width: '60px', display: 'table-cell', frozen:true},
                {field: 'Time', header: 'Timestamp', unit: '', width: width, display: 'table-cell', frozen:true}
            ];
            this.scrollableCols = [
                {field: 'Time', header: 'Timestamp', unit: '', width: width, display: 'none'}, // Hidden, added just for export
                {field: 'relativeTime', header: 'Hours', unit: '', width: width, display: 'table-cell'},
                {field: 'activity', header: 'Activity', unit: '', width: width, display: 'table-cell'}
            ];
            
            this.configuration.workbookMeasurementChannelConfigurations.forEach(config => {
                const unitChoice = this.unitChoices.find(u => u.unitKey === config.unitOfMeasurementId);
                const unit = (unitChoice && unitChoice.unitKey !== 'Dimensionless') ? '(' + unitChoice.unitName + ')' : '';
                this.scrollableCols.push(
                    {
                        field: config.workbookPropertyName,
                        header: config.measurementDisplayName,
                        unit: unit,
                        width: width,
                        display: 'table-cell' });
            });

            this.scrollableCols.push({field: 'CreatedBy', header: 'Created By', unit: '', width: width, display: 'table-cell'});

            // Due to issue with PrimeNg and setting width property on target column
            // always setting column after the targeted column, adding logic to set
            // width on column before targeted column.
            // var activityColIndex = this.scrollableCols.findIndex(c => c.field == "activity");
            // this.scrollableCols[activityColIndex -1].width = '250px';

            const commentsColIndex = this.scrollableCols.findIndex(c => c.field === 'Comments');
            if (commentsColIndex > 0) {
              this.scrollableCols[commentsColIndex - 1].width = '250px';
            }
            this.configurationReady = true;
            this.checkIfEverythingReady();
        }
    }
    getAllColumns(){
      return this.frozenCols.concat(this.scrollableCols);
    }

    getUnitChoices() {
        this.companyWorkbookConfigurationService.getUnitChoices().subscribe(
            result => {
                this.unitChoices = result;
                this.setWorkbookColumns();
            }
        );
    }

    getWorkbookData(jobId: string) {
        this.workbookService.get(jobId).subscribe(
                results => {
                    this.workbookData = results;
                    if (this.workbookData) {
                      this.workbookData.map(log => {
                        log.relativeTime = this.workbookService.calculateRelativeTimeValue(log, this.workbookData);
                      });
                    }
                    this.workbookDataReady = true;
                    this.checkIfEverythingReady();
                },
                error => { this._messageService.errorMessage(error, 'Could not get Workbook Data'); }
            );
    }

    checkIfEverythingReady() {
        this.everythingReady = this.featuresReady && this.userReady && this.configurationReady && this.workbookDataReady;
    }

    isAdmin(): boolean {
        return this.userService.isUserAdmin(this.user, this.companyId, false);
     
    }

    isVendor(): boolean {
      if (this.userReady) {
        if (this.user.isGlobalAdmin) {
          return true;
        }
        if (this.isCompanyAdministrationEnabled) {
          if (this.user.companyRoles.some(r => r.companyId === this.companyId)) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    }

    addWorkbookLog() {
        this.router.navigate(['/workbook2/detail/' + encodeURIComponent(this.companyId) + '/' + this.jobId + '/new']);
    }

    navigateToDetail(workbookLog: WorkbookLog) {
        this.router.navigate(['/workbook2/detail/' + encodeURIComponent(this.companyId) + '/' + this.jobId + '/' + workbookLog.id]);
    }

    async navigateToDashboard() : Promise<void> {
       if (this.consumerJobOriginId) {
          await this.navigateToDashboardFromId(this.consumerJobOriginId);
        } else {
          await this.navigateToDashboardFromId(this.jobId);
        }
    }

    async navigateToDashboardFromId(id:string): Promise<void> {
      try {
      let link = await firstValueFrom(this.jobService.getDashboardLink(id));
      window.open(link,"_blank");
      } catch (error) {
        let errorstring = JSON.stringify(error);
        throw Error('Could not get dashboard link: ' + errorstring);  
      }
    }

    navigateToJobDetails() {
      if (this.isCompanyAdministrationEnabled) {
        if (this.consumerJobOriginId) {
          this.router.navigate(['/consumerjob/' + this.consumerJobOriginId]);
        } else {
          this.router.navigate(['/vendorjob/' + this.jobId]);
        }
      } else {
        this.router.navigate(['job/' + this.jobId]);
      }
    }

    confirmDelete(workbookLog: WorkbookLog) {
        this.confirmationService.confirm(
          {
            key: 'confirmationDialog',
            header: 'Delete Confirmation',
            message: 'Are you sure you want to delete Workbook Log ' + formatDate(workbookLog.Time, 'short', this.locale) + '?',
            icon: 'fa fa-question-circle',
            accept: () => { this.deleteWorkbookLog(workbookLog); }// ,
            // reject: () => { }
          }
        );
      }

    deleteWorkbookLog(workbookLog: WorkbookLog) {
      this._applicationContextService.showApplicationSpinner(true);
        this.workbookService.delete(workbookLog).subscribe(
          result => {
            this._applicationContextService.hideApplicationSpinner();
            this.workbookData = this.workbookData.filter(w => w.id !== workbookLog.id);
            this._messageService.successMessage('Workbook LogDeleted', '');
          },
          error => { this._messageService.errorMessage(error, 'Could not delete workbook log'); }
        );
    }

    handleError(error: any, displayMessage: string) {
      this._messageService.errorMessage(error, displayMessage);
    }
}
