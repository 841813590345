import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { StringTypes } from 'src/app/models/wellInfo';
import { CTWOTubingODs, CTWOTubingOD, CasingOrTubingId, CasingOrTubingDirection } from 'src/app/models/models';
import { WellInfo } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'simulation-well-information',
  templateUrl: './well-information.component.html',
  styleUrls: ['../job-simulator/job-simulator.component.css'],
})
export class WellInformationComponent implements OnInit {
  @Output() wellInfoChanged = new EventEmitter<WellInfo>();

  stringTypes: SelectItem[];
  ctwoTubingODs: SelectItem[];
  hozTubingODs: SelectItem[];
  selectedCTWOTubingOD: CTWOTubingOD;
  selectedHozTubingOD: CTWOTubingOD;
  public wellInfo: WellInfo;

  constructor() { }

  ngOnInit() {
    this.stringTypes = [
      { label: StringTypes.coilTubing, value: StringTypes.coilTubing },
      { label: StringTypes.jointTubing, value: StringTypes.jointTubing }
    ];
    this.ctwoTubingODs = this.getCTWOTubingODs();
    this.hozTubingODs = this.ctwoTubingODs;
  }

  getCTWOTubingODs(): SelectItem[] {
    const tubingODs: SelectItem[] = CTWOTubingODs;
    return tubingODs.sort((a, b) => {
      if (a.label < b.label) { return -1; }
      if (a.label > b.label) { return 1; }
      return 0;
    });
  }

  setLateralLengthEst() {
    if (this.wellInfo && this.wellInfo.tvd && this.wellInfo.md) {
      this.wellInfo.lateralLengthEst = this.wellInfo.md - this.wellInfo.tvd;
    }
    this.updateWellInfo();
  }

  setCTWOTubingOD() {
    this.wellInfo.ctWoTubingOd = this.selectedCTWOTubingOD.value;
    this.wellInfo.ctWoTubingOdId = this.selectedCTWOTubingOD.id;
  }

  setHozTubingOD() {
    this.wellInfo.hozTubingOd = this.selectedHozTubingOD.value;
    this.wellInfo.hozTubingOdId = this.selectedHozTubingOD.id;
  }

  setSelectedTubingODs() {
    this.selectedCTWOTubingOD = { id: this.wellInfo.ctWoTubingOdId, value: this.wellInfo.ctWoTubingOd };
    this.selectedHozTubingOD = { id: this.wellInfo.hozTubingOdId, value: this.wellInfo.hozTubingOd };
  }

  updateCsgId(csgId: any) {
    debugger;
    this.wellInfo.csgId = csgId;
  }

  updateWellInfo() {
    this.wellInfoChanged.emit(this.wellInfo);
  }
}
