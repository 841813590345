"use strict";

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.keyComparer = exports.composeComparers = exports.negate = exports.equal = exports.isObj = void 0;
/**
 * Checks if the argument passed is an object
 */
var isObj = function (x) {
  return !!x && typeof x === 'object';
};
exports.isObj = isObj;
/**
 * Determine if two objects are equal
 */
var equal = function (a, b) {
  return Object.entries(a).every(function (_a) {
    var _b = __read(_a, 2),
      key = _b[0],
      val = _b[1];
    return (0, exports.isObj)(val) ? (0, exports.equal)(b[key], val) : b[key] === val;
  });
};
exports.equal = equal;
/**
 * Creates a function that negates the result of the predicate
 */
var negate = function (pred) {
  return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return !pred.apply(void 0, __spreadArray([], __read(args), false));
  };
};
exports.negate = negate;
/**
 * Comparer helpers
 */
var composeComparers = function (previousComparer, currentComparer) {
  return function (a, b) {
    return previousComparer(a, b) || currentComparer(a, b);
  };
};
exports.composeComparers = composeComparers;
var keyComparer = function (_keySelector, descending) {
  return function (a, b) {
    var sortKeyA = _keySelector(a);
    var sortKeyB = _keySelector(b);
    if (sortKeyA > sortKeyB) {
      return !descending ? 1 : -1;
    } else if (sortKeyA < sortKeyB) {
      return !descending ? -1 : 1;
    } else {
      return 0;
    }
  };
};
exports.keyComparer = keyComparer;
