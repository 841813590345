import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  IUserService,
  INewCompanyRequestService,
  ICompanyAssociationRequestService,
  IOpsViewerMessagesService,
  ICompanyService,
  IFeaturesService,
  IReportService,
  IApplicationContextService
} from 'src/app/services/services';
import {
  NewCompanyRequest,
  CompanyAssociationRequest,
  CompanyName,
  UserRoles,
  Features,
  CompanyTypes
} from 'src/app/models/models';
import { SelectItem } from 'primeng/api';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { first, map } from 'rxjs/operators';
import { User } from 'src/app/services/nswag/nswagclient';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  user: User;
  userIntials: string;
  userReady = false;
  jobSimulatorFeatureEnabled = false;

  availableCompanies: SelectItem[] = [];
  companiesReady = false;
  set selectedCompany(value: CompanyName) {
    localStorage.setItem("profile:selectedCompany", value.companyId);
  };
  get selectedCompany(): CompanyName {
    let storedCompany = localStorage.getItem("profile:selectedCompany");
    
    if (storedCompany) {
      //this.trackOnce(`found selected company ${storedCompany}`)
     
      let previouslySelectedCompany = this.availableCompanies.find(c => storedCompany == c.value.companyId);
      if (previouslySelectedCompany != null) {
        return previouslySelectedCompany.value
      } else {
        this.trackOnce(`${storedCompany} was not in ${JSON.stringify(this.availableCompanies)}`);
      }
    }

    if (this.availableCompanies.length > 0) {
     
      //this.trackOnce(`returning default company ${JSON.stringify(this.availableCompanies[0].value)}`);
      
      return this.availableCompanies[0].value as CompanyName;
    }
    this.trackOnce(`cound not find any companies`);
      
    return null;
  }

  static _previousTracks: { [name: string]: number } = {};
  trackOnce(msg:string){
    if (UserProfileComponent._previousTracks[msg]){
      UserProfileComponent._previousTracks[msg] = UserProfileComponent._previousTracks[msg] + 1;
      return;
    }
    UserProfileComponent._previousTracks[msg] = 1;
  }

  selectedCompanyIsVendor = false;
  selectedCompanyIsVendor$ : Observable<boolean>;

  newCompanyRequests: NewCompanyRequest[] = [];
  newCompanyRequestsReady = false;

  companyAssociationRequests: CompanyAssociationRequest[] = [];
  companyAssociationRequestsReady = false;

  requestsCount = 0;

  everythingReady = false;

  private _companyContextSubscription: Subscription;
  private _userDetailChangesSubscription: Subscription;
  private _requestChangesSubscription: Subscription;

  constructor(
    private _userService: IUserService,
    private _newCompanyRequests: INewCompanyRequestService,
    private _companyAssociationRequests: ICompanyAssociationRequestService,
    private _companyService: ICompanyService,
    private _applicationContextService: IApplicationContextService,
    private _reportService: IReportService,
    private _messageService: IOpsViewerMessagesService,
    private _featuresService: IFeaturesService,
    private _router: Router,
    private _userManagerService: IUserManagerService
  ) { }

  ngOnInit() {
    
    this.selectedCompanyIsVendor$ = this._applicationContextService.companyContextObserver.pipe(map(c => this.isCompanyVendor(c)));
    
    this.getAuthenticatedUser();
    this.listenForCompaniesListChanges();
    this.listenForUserDetailsChanges();
    this.listenForRequestChanges();
    this.getFeatures();


  }

  ngOnDestroy() {
    this._companyContextSubscription.unsubscribe();
    this._userDetailChangesSubscription.unsubscribe();
    this._requestChangesSubscription.unsubscribe();
  }


  getAuthenticatedUser(updateCompaniesAndRequests: boolean = true) {

    this._userManagerService.getCurrentUser().subscribe(u => {
      this.user = u;
      if (this.user == null) {
        console.warn('User is null');
        return;
      }
      
      if (!this.user.contactNumber) {
        this.user.contactNumber = '000-000-0000';
      }
      if (updateCompaniesAndRequests) {
        this.getCompanies(true);
        this.checkIfUserCanGetRequests();
      }
      this.userReady = true;
      this.checkIfEverythingReady();
    }, error => { this._messageService.errorMessage(error, 'Could not get Current user companies for user');});
  }

  

  getCompanies(isInitialization: boolean) {
    this._companyService.getCreatedAfterOrMigratedCompanyNames().subscribe(
      async (results) => {
        if (results && results.length > 0) {
          this.availableCompanies = [];
          results.forEach(r => {
            this.availableCompanies.push({
              label: r.companyName,
              value: {
                id: r.companyId, // needs id property for primeNg SelectItem
                companyId: r.companyId,
                companyName: r.companyName,
                companyType: r.companyType
              }
            });
          });

          if (isInitialization) {
            //this.trackOnce(`Selected company ${this.selectedCompany?.companyId} - ${this.selectedCompany?.companyName} `);
         
            this._applicationContextService.setCompanyContext(this.selectedCompany);
          }
        } else {
          this.trackOnce(`Selected company - none `);
         
          this.availableCompanies = [];
          this._applicationContextService.setCompanyContext(null);
        }
        this.companiesReady = true;
        this.checkIfUserCanGetRequests();
      },
      error => { this._messageService.errorMessage(error, 'Could not get available companies for user'); }
    );
  }

  getFeatures() {
    this._featuresService.getEnabledFeatures().subscribe(
      result => {
        if (result === null) { return; }
        this.jobSimulatorFeatureEnabled = result.indexOf(Features.JobSimulator) >= 0;
      },
      error => {
        this.jobSimulatorFeatureEnabled = false;
      },
    );
  }

  checkIfUserCanGetRequests() {
    if (this.user.isGlobalAdmin) {
      this.getNewCompanyRequests();
      this.getCompanyAssociationRequests();
    } else if (this._userService.isUserAnyRoleAdmin(this.user, false)) {
      this.newCompanyRequestsReady = true;
      this.getCompanyAssociationRequests();
    } else {
      this.newCompanyRequestsReady = true;
      this.companyAssociationRequestsReady = true;
      this.checkIfEverythingReady();
    }
  }

  getNewCompanyRequests() {
    this._newCompanyRequests.getPendingNewCompanyRequestsForUser(this.user.id).subscribe(
      results => {
        this.newCompanyRequests = results;
        this.newCompanyRequestsReady = true;
        this.checkIfEverythingReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not get New Company Requests'); }
    );
  }

  getCompanyAssociationRequests() {
    this._companyAssociationRequests.getPendingCompanyAssoicationRequestsForUser(this.user.id).subscribe(
      results => {
        this.companyAssociationRequests = results;
        this.companyAssociationRequestsReady = true;
        this.checkIfEverythingReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not get Company Association Requests'); }
    );
  }

  async getCompanyUserReport() {
    let csvContent = 'data:text/csv;charset=utf-8,';
    const result = await firstValueFrom(this._reportService.getCompanyUserReport());
    csvContent += result;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'companyUsers.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  checkIfEverythingReady() {
    this.everythingReady = this.userReady && this.companiesReady && this.newCompanyRequestsReady && this.companyAssociationRequestsReady;
    if (this.everythingReady) {
      this.userIntials = this.getUserIntials();
      this.requestsCount = 0;
      if (this.newCompanyRequests != null) {
        this.requestsCount += this.newCompanyRequests.length;
      }
      if (this.companyAssociationRequests != null) {
        this.requestsCount += this.companyAssociationRequests.length;
      }
    }
  }

  private getUserIntials(){
    let first = "";
    if (this.user.firstName && this.user.firstName.length>0){
      first = this.user.firstName?.substring(0, 1).toUpperCase();
    }
    let last = "";
    if (this.user.lastName && this.user.lastName.length>0){
      last = this.user.lastName?.substring(0, 1).toUpperCase();
    }
    return `${first}${last}`;
  }


  async selectedCompanyChanged() {
    this.selectedCompanyIsVendor = this.isCompanyVendor(this.selectedCompany);
    if (document.location.href.indexOf("tickets")<0) {
       await this.navigateHome();
    }
    this._applicationContextService.setCompanyContext(this.selectedCompany);
  }

  editUserProfile() {
    this._router.navigate(['/user/' + this.user.id]);
  }

  listenForCompaniesListChanges() {
    this._companyContextSubscription = this._applicationContextService.companiesListModifiedObserver.subscribe(
      r => {
        if (r) {
          this.getCompanies(false);
          this._applicationContextService.acknowledgeCompaniesListUpdated();
        }
      });
  }

  listenForUserDetailsChanges() {
    this._userDetailChangesSubscription = this._applicationContextService.userDetailsModifiedObserver.subscribe(
      r => {
        if (r) {
          this.getAuthenticatedUser(false);
          this._applicationContextService.acknowledgeUserDetailsUpdated();
        }
      });
  }

  listenForRequestChanges() {
    this._requestChangesSubscription = this._applicationContextService.requestsModifiedObserver.subscribe(
      r => {
        if (r) {
          this.checkIfUserCanGetRequests();
          this._applicationContextService.acknowledgeRequestsUpdated();
        }
      });
  }

  navigateHome():Promise<boolean> {
    return this._router.navigate(['/home/']);
  }

  isUserAdmin(): boolean {
    if (this.user && this.user.isGlobalAdmin) {
      return true;
    }
    if (this.user && this.selectedCompany) {
      if (this.user.companyRoles.some(r => r.companyId === this.selectedCompany.companyId && r.role === UserRoles.admin)) {
        return true;
      }
    }
    return false;
  }

  isUserEditorOrAdmin(): boolean {
    if (this.user && this.selectedCompany) {
      if (this.user.isGlobalAdmin ||
        this.user.companyRoles.some(r =>
          r.companyId === this.selectedCompany.companyId && (r.role === UserRoles.admin || r.role === UserRoles.editor))) {
        return true;
      }
    }
    return false;
  }

  isUserSuperAdmin(): boolean {
    if (this.user && this.user.isGlobalAdmin && this.user.hasAllCompany) {
      return true;
    }
    return false;
  }

  isAEmeraldSurfUser(): boolean {
    if (!this.user) return false; //don't know why this could happen?
    if (this.user.isGlobalAdmin) return true;
    if (this.user.companyRoles.find(c=>c.companyId.toLocaleLowerCase() == "0a35d780-9984-4a97-a911-661b0910a4f6")) return true;
    if (this.user.hasAllCompany) return true;
    return false;
  }


  isCompanyVendor(selectedCompany: CompanyName): boolean {
    if (selectedCompany) {
      if (selectedCompany.companyType === CompanyTypes.Vendor) {
        return true;
      }
    }
    return false;
  }
}
