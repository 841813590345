import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDeviceService,
         ICompanyService,
         IFeaturesService,
         IOpsViewerMessagesService,
         IApplicationContextService, 
         ICompanyWorkbookConfigurationService} from '../../../services/services';
import { Device, DeviceCategories, CompanyName, Company, WellKnownMeasurementChoice } from '../../../models/models';
import { Message, SelectItem } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TcpDeviceConfiguration } from 'src/app/models/tcpDeviceConfiguration';
import { v4 as uuidv4 } from 'uuid';
import { DialogModule } from 'primeng/dialog';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'device-detail',
    standalone: false,
    
    templateUrl: './device-detail.component.html',
    styleUrls: ['./device-detail.component.css']
})

export class DeviceDetailComponent implements OnInit, OnDestroy {

    _deviceReady = false;
    private deviceId: string;
    public device: Device = null;
    public deviceCategories: SelectItem[] = [];
    public isNew = false;
    public modelInvalid = false;
    public showModelInvalidDialog = false;

    _featuresReady = false;
    public deviceConfigurationEnabled = false;
    public companyOptions: SelectItem[] = [];
    private _companies: Company[] = [];
    public selectedCompany: Company;
    _companiesReady = false;
    public everythingReady = false;

    public wellKnownMeasurements: WellKnownMeasurementChoice[] = [];
    public wellKnownMeasurementsReady = false;

    private _companyContextSubscription: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _deviceService: IDeviceService,
        private _titleService: Title,
        private _featureSerivce: IFeaturesService,
        private _companyService: ICompanyService,
        private _companyConfigurationService: ICompanyWorkbookConfigurationService,
        private _messageService: IOpsViewerMessagesService,
        private _applicationContextService: IApplicationContextService) { }

    ngOnInit() {
        this.getDeviceCategories();
        this.getDeviceFromRoute();
        this.getFeatures();
        this.getCompanyOptions();
        this.getWellKnownMeasurements();
    }

    ngOnDestroy() {
        if (this._companyContextSubscription) {
            this._companyContextSubscription.unsubscribe();
        }
    }

    getDeviceCategories() {
        this.deviceCategories = [];
        this.deviceCategories.push({ label: DeviceCategories.DPU, value: DeviceCategories.DPU });
        this.deviceCategories.push({ label: DeviceCategories.Ultralight, value: DeviceCategories.Ultralight });
        this.deviceCategories.push({ label: DeviceCategories.VisionDesktop, value: DeviceCategories.VisionDesktop });
        this.deviceCategories.push({ label: DeviceCategories.FlowBack, value: DeviceCategories.FlowBack });
        this.deviceCategories.push({ label: DeviceCategories.TCP, value: DeviceCategories.TCP });
    }

    getDeviceFromRoute() {
        this._route.params.subscribe(params => {
            this.deviceId = params['id'];
            if (!this.deviceId || this.deviceId.toUpperCase() === 'NEW') {
                this.isNew = true;
                this.createNewDevice();
            } else {
                this.getDevice();
            }
            this.setPageTitle(this.deviceId);
            // this.getCategories();
            // this.getDistricts();
        });
    }

    setPageTitle(deviceId: string) {
        if (!deviceId || deviceId === 'New' || deviceId === '') {
          this._titleService.setTitle('New Job');
        } else { this._titleService.setTitle(deviceId); }
      }

    getFeatures() {
        this._featureSerivce.isFeatureEnabled('DeviceConfiguration').subscribe(
            result => {
                this.deviceConfigurationEnabled = result;
                this._featuresReady = true;
                this.getCompanyOptions();
            },
            error => { this._messageService.errorMessage(error, 'Could not get Device Configuration Feature.'); }
        );
    }

    getWellKnownMeasurements() {
        this._companyConfigurationService.getWellKnownMeasurements().subscribe(
          results => {
            this.wellKnownMeasurements = results;
            this.wellKnownMeasurementsReady = true;
            this.checkIfEverythingIsReady();
          },
          error => {
            this._messageService.errorMessage(error, 'Unable to get Well Known Measurement Channel Options');
            this.wellKnownMeasurementsReady = true;
            this.checkIfEverythingIsReady();
          }
        );
      }

    listenToCompanyContext() {
        // Initialize value
        this.updateDeviceCompanyId();

        // Listen for changes
        this._companyContextSubscription = this._applicationContextService.companyContextObserver.subscribe(
            c => this.updateDeviceCompanyId(c));
    }

    updateDeviceCompanyId(company: CompanyName = null) {
        // If null passed in get current
        if (!company) {
            company = this._applicationContextService.getCompanyContext();
        }
        // Auto set if configuring new device-
        if (company && this.device && this.isNew) {
            this.device.companyId = company.companyId;
        }
        this.setSelectedCompany(this._companies, company.companyId);
    }

    setSelectedCompany(companies: Company[], companyId: string) {
        const selectedCompanyIndex = companies.findIndex(c => c.id === companyId);
        this.selectedCompany = companies[selectedCompanyIndex];
    }

    getCompanyOptions() {
        this.companyOptions = [];
        this._companyService.getCompanies().subscribe(
            result => {
                if (result) {
                    this._companies = result;
                    if (this.device && this.device.companyId) {
                        this.setSelectedCompany(this._companies, this.device.companyId);
                    }
                    result.map( r => {
                        this.companyOptions.push({ label: r.name, value: r.id });
                    });
                    // Auto-set if only one option
                    if (this.companyOptions.length === 1) {
                        this.device.companyId = this.companyOptions[0].value;
                    }
                }
                this._companiesReady = true;
                this.checkIfEverythingIsReady();
            },
            error => { this._messageService.errorMessage(error, 'Could not get Companies.'); }
        );
    }

    createNewDevice() {
        const id = uuidv4();
        this.device = new Device(id, null, this.deviceCategories[0].value);
        this.updateDeviceCompanyId();
        this._deviceReady = true;
        this.checkIfEverythingIsReady();
    }

    getDevice() {
        this._deviceService.getDevice(this.deviceId).subscribe(
            result => {
                if (result == null) {
                    this._messageService.warnMessage('Could not get Device', 'Device Id: ' + this.deviceId);
                } else {
                    this.device = result;
                    if (this.device.tcpDeviceConfiguration == null){
                        this.device.tcpDeviceConfiguration = new TcpDeviceConfiguration();
                    }
                    this._deviceReady = true;
                    this.checkIfEverythingIsReady();
                }
            },
            error => { this._messageService.errorMessage(error, 'Could not get Device.'); }
        );
    }

    checkIfEverythingIsReady() {
        this.everythingReady = this._deviceReady &&
                               this._featuresReady &&
                               ( (this.deviceConfigurationEnabled && this._companiesReady) ||
                                 !this.deviceConfigurationEnabled
                               ) &&
                               this.wellKnownMeasurementsReady;
    }

    validateDeviceModel() {
        if (!this.device.id || this.device.id === '') { return false; }
        if (!this.device.name || this.device.name === '') { return false; }
        if (!this.device.category) { return false; }
        if (this.deviceConfigurationEnabled && !this.device.companyId) { return false; }

        return true;
    }

    saveDevice() {
        this.showModelInvalidDialog = this.modelInvalid = !this.validateDeviceModel();

        if (!this.showModelInvalidDialog) {
            if (this.isNew) {
                this._deviceService.createDevice(this.device).subscribe(
                    result => { this.returnToDeviceList(); },
                    error => { this._messageService.errorMessage(error, 'Could not Create Device'); }
                );
            } else {
                this._deviceService.updateDevice(this.deviceId, this.device).subscribe(
                    result => { this.returnToDeviceList(); },
                    error => { this._messageService.errorMessage(error, 'Could not Update Device'); }
                );
            }
        }
    }

    closeInvalidModelDialog() {
        this.showModelInvalidDialog = false;
    }

    returnToDeviceList() {
        this._router.navigate(['/devices/']);
    }

    handleConfigurationErrors(errorEvent: any) {
        this._messageService.errorMessage(errorEvent, 'Device measurements configuration error');
    }
}
