import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ICalculatedFieldConfigurationService } from 'src/app/services/services';
import { CompanyCalculatedFieldConfiguration, CalculatedFieldConfiguration } from 'src/app/models/models';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-calculations',
  
  standalone: false,
  templateUrl: './calculations.component.html',
  styleUrls: ['./calculations.component.css']
})
export class CalculationsComponent implements OnInit, OnChanges {

  @Input() companyId: string;

  @Output() companyCalculations = new EventEmitter();
  @Output() errors = new EventEmitter();

  _companyCalculatedFieldConfiguration: CompanyCalculatedFieldConfiguration;

  calculationsCols: any[] = [];
  calculationsTableReady = false;

  editorOptions = {
    theme: 'wellKnownMeasurementsTheme',
    language: 'wellKnownMeasurementsLanguage',
    // scrollbarVisibility: 'Hidden'
  };

  showCalcDialog = false;
  calculationDisplayName: string;
  calculationFormula: string;
  calculationInvalid = false;

  constructor(private confirmationService: ConfirmationService, private calculatedFieldConfigurationService: ICalculatedFieldConfigurationService) { 
    this._companyCalculatedFieldConfiguration = new CompanyCalculatedFieldConfiguration(undefined, this.companyId);
   }

  // ---------- Initialization Methods --------------------------------------------
  ngOnInit() {
    this.initializeCompanyCalculations();
  }

  ngOnChanges() {
    this.initializeCompanyCalculations();
  }

  initializeCompanyCalculations() {
    this.setCalculationsTableCols();
    this.getCompanyCalculations();
  }

  getCompanyCalculations() {
    this.calculatedFieldConfigurationService.getConfigurationForCompany(this.companyId).subscribe(
      result => {
        if(result)
          this._companyCalculatedFieldConfiguration = result;
      },
      error => {
        console.log("Error", error);
        //
        const messages = [{
          severity: 'error',
          summary: 'Could not get Calculated Fields',
          detail: ''
        }];
        this.errors.emit(messages);
      },
      () => {
        this.calculationsTableReady = true;
        //this.checkIfReadyToGetConfiguration();
      }
    ); 
  }

  setCalculationsTableCols() {
    this.calculationsCols = [
      { field: 'displayName', header: 'Name', width: '125px'},
      { field: 'formula', header: 'Formula', width: '350px'},
      { field: '', header: 'Action', width: '50px' }
    ];
  }

  // -------- Methods from Template Actions --------------------------------------------------------------------

  openCalculationDialog() {
    this.calculationDisplayName = null;
    this.calculationFormula = null;
    this.showCalcDialog = true;
  }

  editCalculation(calculation: CalculatedFieldConfiguration) {
    this.calculationDisplayName = calculation.displayName;
    this.calculationFormula = calculation.formula;
    this.showCalcDialog = true;
  }

  addOrUpdateCalculation() {
    this.calculationInvalid = this.checkIfCalculationInvalid();
    if (!this.calculationInvalid) {
      const existing = this._companyCalculatedFieldConfiguration.calculatedFieldConfigurations.find(c => c.displayName === this.calculationDisplayName);
      if (existing) {
        existing.fieldName = this.setCalculationName(this.calculationDisplayName);
        existing.displayName = this.calculationDisplayName;
        existing.formula = this.calculationFormula;
      } else {
        const newCalc = new CalculatedFieldConfiguration();
        newCalc.fieldName = this.setCalculationName(this.calculationDisplayName);
        newCalc.displayName = this.calculationDisplayName;
        newCalc.formula = this.calculationFormula;
        this._companyCalculatedFieldConfiguration.calculatedFieldConfigurations.push(newCalc);
        let temp = [];
        temp.push(...this._companyCalculatedFieldConfiguration.calculatedFieldConfigurations);
        this._companyCalculatedFieldConfiguration.calculatedFieldConfigurations = temp;
      }
      this.showCalcDialog = false;
      this.updateEmitters();
    }
  }


  confirmDeleteCalculation(calcualtion: CalculatedFieldConfiguration) {
    this.confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete ' + calcualtion.displayName + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteCalculation(calcualtion); }// ,
        // reject: () => { }
      }
    );
  }

  // ----------- Component Helper Methods ----------------------------------------------------------

   checkIfCalculationInvalid(): boolean {
    if (!this.calculationDisplayName || this.calculationDisplayName === '') {
      return true;
    }
    if (!this.calculationFormula || this.calculationFormula === '') {
      return false;
    }

    return false;
  }

  setCalculationName(calculationDisplayName: string): string {
    if (calculationDisplayName) {
      return calculationDisplayName.replace(/ /g, '', );
    }
    return null;
  }

  deleteCalculation(calcualtion: CalculatedFieldConfiguration) {
    this._companyCalculatedFieldConfiguration.calculatedFieldConfigurations =
        this._companyCalculatedFieldConfiguration.calculatedFieldConfigurations.filter(c =>
          c.displayName !== calcualtion.displayName);
    this.updateEmitters();
  }

  updateEmitters() {
    if (this._companyCalculatedFieldConfiguration) {
      this.companyCalculations.emit(this._companyCalculatedFieldConfiguration);
    }
  }
}
