import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { StringTypes } from 'src/app/models/wellInfo';
import { CTWOTubingODs, CTWOTubingOD, CasingOrTubingId, CasingOrTubingDirection } from 'src/app/models/models';
import { DialogModule } from 'primeng/dialog';
import { VendorJob } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-job-well-information',
  
  standalone: false,
  templateUrl: './job-well-information.component.html',
  styleUrls: ['./job-well-information.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JobWellInformationComponent),
    multi: true
  }]
})
export class JobWellInformationComponent implements OnInit, ControlValueAccessor {

  private _onTouched: Function;

  @Input() _job: VendorJob;
  get job() {
    return this._job;
  }
  set job(val) {
    this._job = val;
    this.propagateChange(this._job);
  }
  @Input() modelInvalid: boolean;
  @Input() disabled = false;

  stringTypes: SelectItem[] = [];
  ctwoTubingODs: SelectItem[] = [];
  hozTubingODs: SelectItem[] = [];

  selectedCTWOTubingOD: CTWOTubingOD;
  selectedHozTubingOD: CTWOTubingOD;

  showCasingInvalidDialog = false;

  constructor() { }

  ngOnInit() {
    this.setupDrowDownOptions();
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    if (obj) {
      this.job = obj;
      this.setSelectedTubingODs();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setSelectedTubingODs() {
    this.selectedCTWOTubingOD = { id: this.job.wellInfo.ctWoTubingOdId, value: this.job.wellInfo.ctWoTubingOd };
    this.selectedHozTubingOD = { id: this.job.wellInfo.hozTubingOdId, value: this.job.wellInfo.hozTubingOd };
  }

  /****** Componet UI control methods ******/
  setupDrowDownOptions() {
    this.stringTypes = [
      { label: StringTypes.coilTubing, value: StringTypes.coilTubing },
      { label: StringTypes.jointTubing, value: StringTypes.jointTubing }
    ];
    this.ctwoTubingODs = this.getCTWOTubingODs();
    this.hozTubingODs = this.ctwoTubingODs;
  }

  getCTWOTubingODs(): SelectItem[] {
    const tubingODs: SelectItem[] = CTWOTubingODs;
    return tubingODs.sort((a, b) => {
      if (a.label < b.label) { return -1; }
      if (a.label > b.label) { return 1; }
      return 0;
    });
  }

  setLateralLengthEst() {
    if (this.job.wellInfo && this.job.wellInfo.tvd && this.job.wellInfo.md) {
      this.job.wellInfo.lateralLengthEst = this.job.wellInfo.md - this.job.wellInfo.tvd;
    }
  }

  setCTWOTubingOD() {
    this.job.wellInfo.ctWoTubingOd = this.selectedCTWOTubingOD.value;
    this.job.wellInfo.ctWoTubingOdId = this.selectedCTWOTubingOD.id;
  }

  setHozTubingOD() {
    this.job.wellInfo.hozTubingOd = this.selectedHozTubingOD.value;
    this.job.wellInfo.hozTubingOdId = this.selectedHozTubingOD.id;
  }

  updateCsgId(csgId: any) {
    this.job.wellInfo.csgId = csgId;
  }

  validateIdSelected(casingOrTubingId: CasingOrTubingId) {
    // Coil Tubing selection: all casingOrTubing ID's must be
    // larger than selectedCtWoTubing ODs
    // Jointed Tubing selection: selectedCtWoTubingOd must be
    // smaller than the vertical casing's ID and hozTubingOd must
    // be smaller than the horizontal casing's ID.
    // If Production Tubing is the casingOrTubingId selected,
    // it must be larger than selectedCtWoTubingOD in either Coiled or Jointed
    if ((!this.job.wellInfo.stringType || (this.job.wellInfo.stringType === StringTypes.coilTubing)) &&
        this.selectedCTWOTubingOD && casingOrTubingId) {
        if (this.selectedCTWOTubingOD.value >= casingOrTubingId.value) {
            this.showCasingInvalidDialog = true;
        }
    } else if (this.job.wellInfo.stringType === StringTypes.jointTubing &&
               casingOrTubingId) {
                 switch (casingOrTubingId.casingOrTubingType) {
                   case CasingOrTubingDirection.vertical: {
                    if (this.selectedCTWOTubingOD && this.selectedCTWOTubingOD.value >= casingOrTubingId.value) {
                      this.showCasingInvalidDialog = true;
                    }
                    break;
                   }
                   case CasingOrTubingDirection.horizontal: {
                    if (this.selectedHozTubingOD && this.selectedHozTubingOD.value >= casingOrTubingId.value) {
                      this.showCasingInvalidDialog = true;
                    }
                    break;
                   }
                   case CasingOrTubingDirection.tubing: {
                    if (this.selectedCTWOTubingOD && this.selectedCTWOTubingOD.value >= casingOrTubingId.value) {
                      this.showCasingInvalidDialog = true;
                    }
                    break;
                   }
                   default: { break; }
                 }
    }
  }

  closeCasingInvalidDialog() {
    this.showCasingInvalidDialog = false;
  }
}
