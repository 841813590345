import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CasingTubingTypes, CasingOrTubingId, CasingOrTubingDirection } from 'src/app/models/models';
import { ConfirmationService } from 'primeng/api';
import { CasingTubings, CasingTubingDetail } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-casing-tubing',
  templateUrl: './casing-tubing.component.html',
  styleUrls: ['./casing-tubing.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CasingTubingComponent),
    multi: true
  }]
})
export class CasingTubingComponent implements OnInit, ControlValueAccessor {

  private _onTouched: Function;
  private _gallonsPerCubicFeet = 7.48052;
  private _feetPerInch = 0.0833333;
  private _gallonsPerBarrel = 42;

  public casingTubingTypes = CasingTubingTypes;

  @Input() _casingTubings: CasingTubings;
  get casingTubings() {
    return this._casingTubings;
  }
  set casingTubings(val) {
    this._casingTubings = val;
    this.propagateChange(this._casingTubings);
  }
  @Input() disabled = false;
  // Because the value of csgId is changed when Casing 1 Horizontal changes
  // but not part of the CasingTubigns model, emit this value change to the
  // parent component so they may act on it.
  @Output() csgIdChanged = new EventEmitter();
  private _csgId: number;
  get csgId() {
    return this._csgId;
  }
  set csgId(val) {
    this._csgId = val;
    this.csgIdChanged.emit(this._csgId);
  }
  @Output() casingOrTubingIdChanged = new EventEmitter();
  private _casingOrTubingId: CasingOrTubingId;
  get casingOrTubingId() {
    return this._casingOrTubingId;
  }
  set casingOrTubingId(val) {
    this._casingOrTubingId = val;
    this.casingOrTubingIdChanged.emit(this._casingOrTubingId);
  }

  constructor(private _confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.setDefaults();
  }

  /****** ControlValueAccessor methods to handle ngModel read and updates ******/
  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    if (obj) {
      this.casingTubings = obj;
    } else {
      this.casingTubings = new CasingTubings();
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /****** Componet UI Button Click Methods ******/

  setDefaults() {
    if (!this.casingTubings) {
      this.casingTubings = new CasingTubings();
      this.casingTubings.casing1Vertical = new CasingTubingDetail();
      this.casingTubings.casing1Horizontal = new CasingTubingDetail();
    }
  }

  addTubing() {
    if (!this.casingTubings.tubing1) {
      this.casingTubings.tubing1 = new CasingTubingDetail();
    } else if (!this.casingTubings.tubing2) {
      this.casingTubings.tubing2 = new CasingTubingDetail();
    }
  }

  addVerticalCasing() {
    if (!this._casingTubings.casing2Vertical) {
      this.casingTubings.casing2Vertical = new CasingTubingDetail();
    }
  }

  addHorizontalCasing() {
    if (!this._casingTubings.casing2Horizontal) {
      this.casingTubings.casing2Horizontal = new CasingTubingDetail();
    }
  }

  confirmDelete(casingTubing: string) {
    if (!this.disabled) {
      let message = '';
      if (casingTubing === 'tubing1') { message = 'Tubing 1'; }
      if (casingTubing === 'tubing2') { message = 'Tubing 2'; }
      if (casingTubing === 'casingVertical1') { message = 'Casing Vertical 1'; }
      if (casingTubing === 'casingVertical2') { message = 'Casing Vertical 2'; }
      if (casingTubing === 'casingHorizontal1') { message = 'Casing Horizontal 1'; }
      if (casingTubing === 'casingHorizontal2') { message = 'Casing Horizontal 2'; }
      this._confirmationService.confirm(
        {
          key: 'confirmationDialog',
          header: 'Delete Confirmation',
          message: 'Are you sure you want to delete ' + message + '?',
          icon: 'fa fa-question-circle',
          accept: () => { this.delete(casingTubing); }// ,
          // reject: () => { }
        }
      );
    }
  }

  setCasingTubingVolume(casingTubingDetail: CasingTubingDetail, isCasing1Horizontal: boolean = false, isTubing: boolean = false) {
    if (casingTubingDetail) {
      const innerDiameter = this.getInnerDiameterFromCasingType(casingTubingDetail.type);
      if (innerDiameter) {
        // Set csgId and output to parent component
        if (isCasing1Horizontal) {
          this.csgId = innerDiameter;
        }

        // Set Inner Diameter and type and output to parent component
        const casingOrTubingId = new CasingOrTubingId();
        casingOrTubingId.value = innerDiameter;
        if (isCasing1Horizontal) {
          casingOrTubingId.casingOrTubingType = CasingOrTubingDirection.horizontal;
        } else if (isTubing) {
          casingOrTubingId.casingOrTubingType = CasingOrTubingDirection.tubing;
        } else {
          casingOrTubingId.casingOrTubingType = CasingOrTubingDirection.vertical;
        }
        this.casingOrTubingId = casingOrTubingId;

        // calculate volume of cylinder and convert to gallons
        if (casingTubingDetail.type && casingTubingDetail.length) {
          casingTubingDetail.volume =
          Math.PI *
          Math.pow((innerDiameter / 2 * this._feetPerInch), 2) *
          casingTubingDetail.length *
          this._gallonsPerCubicFeet *
          (1 / this._gallonsPerBarrel);
        }
      }
    }
  }

  /************Disable Control Methods *****************/

  isAddTubingDisabled(): boolean {
    return (this.casingTubings.tubing1 && this.casingTubings.tubing2) || this.disabled ? true : false;
  }

  isTubingCollapsed(): boolean {
    return (this.casingTubings.tubing1 || this.casingTubings.tubing2) || this.disabled ? false : true;
  }

  isAddVerticalCasingDisabled(): boolean {
    return (this.casingTubings.casing2Vertical) || this.disabled ? true : false;
  }

  isAddHorizontalCasingDisabled(): boolean {
    return (this.casingTubings.casing2Horizontal) || this.disabled ? true : false;
  }

  /*********** Internal Component Methods ***************/

  delete(casingTubing: string) {
    // Casing 1 Vertical and Horizontal are never fully deleted, but their data is reset
    if (casingTubing === 'tubing1') { this.casingTubings.tubing1 = null; }
    if (casingTubing === 'tubing2') { this.casingTubings.tubing2 = null; }
    if (casingTubing === 'casingVertical1') { this.casingTubings.casing1Vertical = new CasingTubingDetail(); }
    if (casingTubing === 'casingVertical2') { this.casingTubings.casing2Vertical = null; }
    if (casingTubing === 'casingHorizontal1') { this.casingTubings.casing1Horizontal = new CasingTubingDetail(); }
    if (casingTubing === 'casingHorizontal2') { this.casingTubings.casing2Horizontal = null; }
  }

  getInnerDiameterFromCasingType(casingType: string): number {
    // Parse out Inner Diameter from string in format:
    // 4.5in OD, 9.5 lbs/ft, 4.09 ID, 3.965 in Drift
    // Number and decimal places of ID can change, so using comma separators
    if (!casingType) { return null; }
    const casingTypeArray = casingType.split(',');
    if (!casingTypeArray[2]) { return null; }
    const innerDiameter = casingTypeArray[2];
    innerDiameter.trim();
    innerDiameter.replace(' ID', '');
    innerDiameter.replace(' id', '');
    innerDiameter.replace('ID', '');
    innerDiameter.replace('id', '');
    return parseFloat(innerDiameter);
  }

  getOuterDiameterFromCasingType(casingType: string): number {
    // Parse Outer Diamert value from string in format:
    // 4.5in OD, 9.5 lbs/ft, 4.09 ID, 3.965 in Drift
    // Outer Diameter is first value before space or 'in'
    if (!casingType) { return null; }
    const firstSpaceIndex = casingType.indexOf(' ');
    const firstInchesIndex = casingType.indexOf('in');
    const lowestIndex = (firstSpaceIndex < firstInchesIndex) ? firstSpaceIndex : firstInchesIndex;
    return parseFloat(casingType.substring(0, lowestIndex));
  }
}
