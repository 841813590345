"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Enumerable = exports.List = void 0;
/**
 * LinQ to TypeScript
 *
 * Documentation from LinQ .NET specification (https://msdn.microsoft.com/en-us/library/system.linq.enumerable.aspx)
 *
 * Created by Flavio Corpa (@kutyel)
 * Copyright © 2016 Flavio Corpa. All rights reserved.
 *
 */
var list_1 = require("./list");
Object.defineProperty(exports, "List", {
  enumerable: true,
  get: function () {
    return list_1.default;
  }
});
var enumerable_1 = require("./enumerable");
Object.defineProperty(exports, "Enumerable", {
  enumerable: true,
  get: function () {
    return enumerable_1.default;
  }
});
