<div *ngIf="job != null && job.wellInfo != null" class="grid field col-12">
  <div class="grid field col-12">
      <!-- XO/EOT -->
      <div class="rl6">
          <label>XO/EOT</label>
      </div>
      <div class="rl6">
          <input type="text" pInputText class="w-full" placeholder="Enter Amount" [(ngModel)]="job.wellInfo.xoEot" (change)="setLateralLengthEst()"  [disabled]="disabled"/>
      </div>
      <!-- KOP -->
      <div class="rl6">
          <label>KOP</label>
      </div>
      <div class="rl6">
          <input type="text" pInputText class="w-full" placeholder="Enter Amount" [(ngModel)]="job.wellInfo.kop" (change)="setLateralLengthEst()"  [disabled]="disabled"/>
      </div>
      <!-- 30 Degree -->
      <div class="rl6">
          <label>30 Degree</label>
      </div>
      <div class="rl6">
          <input type="text" pInputText class="w-full" placeholder="Enter Amount" [(ngModel)]="job.wellInfo.thirtyDegree" (change)="setLateralLengthEst()" [disabled]="disabled" class="w-full" />
      </div>
      <!-- 60 Degree -->
      <div class="rl6">
          <label>60 Degree</label>
      </div>
      <div class="rl6">
          <input type="text" pInputText class="w-full" placeholder="Enter Amount" [(ngModel)]="job.wellInfo.sixtyDegree" (change)="setLateralLengthEst()" [disabled]="disabled"/>
      </div>
      <!-- 90 Degree (used be calleded TVD) -->
      <div class="rl6">
          <label>90 Deg</label>
      </div>
      <div class="rl6">
          <input type="text" pInputText class="w-full"placeholder="Enter Amount" [(ngModel)]="job.wellInfo.tvd" (change)="setLateralLengthEst()" [disabled]="disabled"/>
      </div>
      <!-- PBTD (Used to be called MD) -->
      <div class="rl6">
          <label>PBTD</label>
      </div>
      <div class="rl6">
          <input type="text" pInputText class="w-full" placeholder="Enter Amount" [(ngModel)]="job.wellInfo.md" (change)="setLateralLengthEst()" [disabled]="disabled"/>
      </div>
  </div>
  <div class="grid field col-12">
      <!--Lateral Length Est -->
      <div class="col-3">
          <label>Lateral Length Est.</label>
      </div>
      <div class="col-1">
          <label class="readOnly-label">{{job.wellInfo.lateralLengthEst}}</label>
      </div>
  </div>
  <div class="grid field col-12">
      <!-- String Type -->
      <div class="rl3">
          <label>Workstring Type</label>
      </div>
      <div class="rf3">
          <p-dropdown [options]="stringTypes" [(ngModel)]="job.wellInfo.stringType" placeholder="Select String Type" [style]="{'width':'100%'}"  [disabled]="disabled"></p-dropdown>
      </div>
      <div class="rl3" *ngIf="job.wellInfo.stringType != 'Jointed Tubing'">
          <label>Tubing OD (in)</label>
      </div>
      <div class="rl3" *ngIf="job.wellInfo.stringType == 'Jointed Tubing'">
          <label>VIR Tubing OD</label>
      </div>
      <div class="rf3">
          <p-dropdown [options]="ctwoTubingODs" [(ngModel)]="selectedCTWOTubingOD" (onChange)="setCTWOTubingOD()" placeholder="Select Size" [style]="{'width':'100%'}"  [disabled]="disabled"></p-dropdown>
      </div>
      <!-- Coil Capacity -->
      <div class="rl3">
          <label>Capacity (BBLS)</label>
      </div>
      <div class="rf3">
          <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.coilCapacity"  [disabled]="disabled"/>
      </div>
      <!-- CSG ID -->
      <!-- <div class="rl6">
          <label>CSG ID (in)</label>
      </div>
      <div class="rl6">
          <label class="readOnly-label">{{job.wellInfo.csgId}}</label>
      </div> -->
  </div>
  <div class="grid field col-12" *ngIf="job.wellInfo.stringType == 'Jointed Tubing'">
      <div class="rl3">
          <label>HOZ Tubing OD</label>
      </div>
      <div class="rf3">
          <p-dropdown [options]="hozTubingODs" [(ngModel)]="selectedHozTubingOD" (onChange)="setHozTubingOD()" placeholder="Select Size" [style]="{'width':'100%'}" [disabled]="disabled"></p-dropdown>
      </div>
  </div>
  <div class="grid field col-12">
      <div class="rl3">
          <label># Plugs in Well</label>
      </div>
      <div class="rf3">
          <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.fracPlugStages" [disabled]="disabled"/>
      </div>
      <!--SITP Est -->
      <div class="rl3">
              <label>SITP</label>
          </div>
          <div class="rf3">
              <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.sitp" [disabled]="disabled"/>
          </div>
      <!--SICP Est -->
      <div class="rl3">
              <label>SICP</label>
          </div>
          <div class="rf3">
              <input type="text" pInputText placeholder="Enter Amount" [(ngModel)]="job.wellInfo.sicp" [disabled]="disabled"/>
          </div>
  </div>

  <p-fieldset legend="Production Casing/Tubing" [toggleable]="true" class="col-12" [collapsed]="true" >
    <app-casing-tubing [(ngModel)]="job.wellInfo.casingTubings" (csgIdChanged)="updateCsgId($event)" (casingOrTubingIdChanged)="validateIdSelected($event)" [disabled]="disabled"></app-casing-tubing>
  </p-fieldset>
  <p-fieldset legend="Plugs" class="col-12" [toggleable]="true" [collapsed]="true" >
    <app-plugs [(ngModel)]="job.wellInfo.plugSets" [disabled]="disabled"></app-plugs>
  </p-fieldset>
</div>

<p-dialog header="Casing Invalid" [(visible)]="showCasingInvalidDialog" [modal]="true" [style]="{width: '350px', minWidth: '200px'}" [baseZIndex]="10000" [dismissableMask]="true"> 
    <p>
        Selected Casing Outer Diameter must be larger than selected Tubing Outer Diameter.
    </p>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="closeCasingInvalidDialog()" label="Ok"></button>
    </p-footer>
</p-dialog>
