<p-table *ngIf="jobLinkerCols && jobLinkers" class="config-table" #dt
  [columns]="jobLinkerCols"
  [value]="jobLinkers"
  [scrollable]="true">
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{col.header}}
        <p-sortIcon *ngIf="showAdminControls && col.field != ''" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="showAdminControls">
        <th *ngFor="let col of columns" [ngSwitch]="col.field" pResizableColumn>
            <div *ngSwitchCase="'consumer'">
                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"
                />
            </div>
            <div *ngSwitchCase="'wellName'">
                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"
                />
            </div>
            <div *ngSwitchCase="'apiNumber'">
                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"
                />
            </div>
            <div *ngSwitchCase="'jobType'" style="width: 100%;">
                <p-multiSelect [options]="jobTypes" placeholder="All Job Types" (onChange)="dt.filter($event.value, col.field, 'in')" appendTo="body"></p-multiSelect>
            </div>
            <div *ngSwitchCase="'vendorJobNames'" style="width: 100%;">
              <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;"
                />
          </div>
            <div *ngSwitchCase="'consumerJobStatus'" style="width: 100%;">
                <p-multiSelect [options]="statuses" placeholder="All Statuses" (onChange)="dt.filter($event.value, col.field, 'in')" appendTo="body"></p-multiSelect>
            </div>
        </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-jobLinker>
    <tr>
      <td>{{jobLinker.consumer}}</td>
      <td>{{jobLinker.wellName}}</td>
      <td>{{jobLinker.apiNumber}}</td>
      <td>{{jobLinker.jobType}}</td>
      <td>{{jobLinker.consumerJobStartTime | date:'medium'}}</td>
      <td>{{jobLinker.consumerJobStatus}}</td>
      <td *ngIf="showAdminControls" class="wbCell" 
        [pTooltip]="jobLinker.vendorJobNames" tooltipPosition="top" tooltipEvent="hover">{{jobLinker.vendorJobNames}}</td>
      <td *ngIf="showAdminControls">
        <i class="far fa-edit" style="margin-right:2px;" (click)="editJobLinker(jobLinker)"></i>
        <!-- <i class="far fa-trash-alt" (click)="deleteJobLinker(jobLinker)"></i> -->
      </td>
    </tr>
  </ng-template>
  <ng-template *ngIf="showAdminControls" pTemplate="paginatorleft">
      Showing {{dt.totalRecords < 1 ? 0 : dt.first + 1}} to 
      {{dt.totalRecords > 0 ? ((dt.rows + dt.first) <= dt.totalRecords ? (dt.rows + dt.first) : 
      dt.totalRecords) : 0}} of {{dt.totalRecords ? dt.totalRecords : 0}} entries
  </ng-template>
  <ng-template *ngIf="showAdminControls" pTemplate="summary">
      <button pButton type="button" label="Add" (click)="addJobLinker()" icon="fa fa-plus"
          class="add-button"></button>
  </ng-template>
</p-table>
<p *ngIf="!jobLinkers">No Job Linking Data to show</p>
