import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-navigation-top-bar',
  
  standalone: false,
  templateUrl: './navigation-top-bar.component.html',
  styleUrls: ['./navigation-top-bar.component.css']
})

// Note: This component was once used across the whole app in the top bar part of the layout
//       but since moved to only be in certain parts of the app, so NavigationTopBarComponent
//       isn't the best description for it anymore.
export class NavigationTopBarComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  navigateToNewCompanyRequest() {
    this._router.navigate(['requests/newcompany']);
  }

  navigateToCompanyAssociationRequest() {
    this._router.navigate(['requests/companyassociation']);
  }
}
