import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { SimulationActivity, SimulationDefault } from 'src/app/models/simulation';
import { trigger,state,style,transition,animate } from '@angular/animations';
import { SimulationCommon, InitialActivityName, OptionalActivityNames } from '../functions/simulationCommon';
import { SimulationCalculations } from '../functions/simulationCalculations';
import { WellInfo } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'simulation-sequence',
  templateUrl: './sequence.component.html',
  styleUrls: ['../job-simulator/job-simulator.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
        state('void', style({
            transform: 'translateX(-10%)',
            opacity: 0
        })),
        state('active', style({
            transform: 'translateX(0)',
            opacity: 1
        })),
        transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class SequenceComponent implements OnInit {
  @Input() wellInfo: WellInfo;
  @Output() sequenceChanged = new EventEmitter();

  activities: SimulationActivity[];
  defaults: SimulationDefault;
  depthSelector: SelectItem[];
  initialActivities: SimulationActivity[];
  optionalActivities: SelectItem[];

  constructor() { }

  ngOnInit() {
    this.depthSelector = [
      {label: 'Depth', value: true},
      {label: 'Plug', value: false},
    ];
    const def = SimulationCommon.getFactorySimulationDefaults();
    this.initialActivities = SimulationCommon.getInitialActivities(def, this.wellInfo);
    this.optionalActivities = [
      { label: OptionalActivityNames.Circulation, value: OptionalActivityNames.Circulation},
      { label: OptionalActivityNames.Delay, value: OptionalActivityNames.Delay},
      { label: OptionalActivityNames.Milling, value: OptionalActivityNames.Milling},
      { label: OptionalActivityNames.Pickup, value: OptionalActivityNames.Pickup},
      { label: OptionalActivityNames.ShortTrip, value: OptionalActivityNames.ShortTrip},
      { label: OptionalActivityNames.Tag, value: OptionalActivityNames.Tag},
      { label: OptionalActivityNames.ToolRun, value: OptionalActivityNames.ToolRun},
      { label: OptionalActivityNames.Washing, value: OptionalActivityNames.Washing},
      { label: OptionalActivityNames.WeightCheck, value: OptionalActivityNames.WeightCheck},
      { label: OptionalActivityNames.WiperTrip, value: OptionalActivityNames.WiperTrip},
    ];
  }

  /* Activity Methods */

  addActivityBelow(act: SimulationActivity) {
    const newAct = SimulationCommon.getNewActivity(this.optionalActivities[0].value, this.defaults);
    const idx = this.activities.findIndex(v => v.guid === act.guid) || 0;
    this.activities.splice(idx + 1, 0, newAct);
  }

  hasDepthStart(act: SimulationActivity): boolean {
    return SimulationCalculations.hasDepthStart(act);
  }

  isInitialActivity(act: SimulationActivity) : boolean {
    const idx = this.initialActivities.findIndex(v => v.activity == act.activity);
    if(idx >= 0) {
      return true;
    }
    return false;
  }

  isNotInitialActivity(act: SimulationActivity) : boolean {
    return !this.isInitialActivity(act);
  }

  removeActivity(act: SimulationActivity) {
    const initialActivities = this.initialActivities.map(v => v.activity);
    this.activities = this.activities.filter(v => v.guid != act.guid || initialActivities.indexOf(v.activity) >= 0);
  }

  /* Send a request o compute the calculated fields */
  requestCalculatedFields() {
    this.sequenceChanged.emit();
  }

}
