import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message, SelectItem } from 'primeng/api';

import { WorkbookConfiguration, Configuration } from '../../models/models';
import { IWorkbookConfigurationService, IWorkbookLogService, IUserService } from '../../services/services';
import { Title } from '@angular/platform-browser';
import { Table } from 'primeng/table';
import { v4 as uuidv4 } from 'uuid';
import { IVendorJobClient } from 'src/app/services/nswag/nswagclient';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'workbook',
  templateUrl: './workbook.component.html',
  styleUrls: ['./workbook.component.css']
})
export class WorkbookComponent implements OnInit {
  @ViewChild(Table) dataTable: Table;

  dashboardLink: string;
  jobNumber: string;
  isEditorOrAdmin = false;
  workbookConfigurationReady = false;
  workbookLogsReady = false;
  workbookReady = false;
  everythingReady = false;
  workbookConfiguration: WorkbookConfiguration;
  workbookLogs: any[] = [];
  scrollableCols: any[] = [];
  frozenCols: any[] = [];
  scrollHeight = '500px';
  messages: Message[] = [];
  showWorkbookConfigurationDialog = false;
  availableFieldOptions: SelectItem[] = [];
  selectedAvailableField: string = null;
  createdField: string = null;
  createFieldIsText = false;
  createFieldWidth = '60%';
  createFieldIsTextWidth = '15%';
  createFieldButtonWidth = '25%';
  fieldNameWidth = '75%';
  buttonWidth = '25%';
  inputDate: Date;
  changesMade = false;
  changedClientIds: string[] = [];
  showSavingProgress = false;
  savedRows = 0;
  fluidSystems: SelectItem[] = [
    { 'label': 'SLW', value: 'SLW' },
    { 'label': 'SWP', value: 'SWP' }
  ];
  activities: SelectItem[] = [
    { 'label': 'Actvity', value: 'Activity' },
    { 'label': 'Circulating', value: 'Circulating' },
    { 'label': 'Milling', value: 'Milling' },
    { 'label': 'OOH', value: 'OOH' },
    { 'label': 'OOH-END', value: 'OOH-END' },
    { 'label': 'Pickup', value: 'Pickup' },
    { 'label': 'POOH', value: 'POOH' },
    { 'label': 'RIH', value: 'RIH' },
    { 'label': 'RIH-Start', value: 'RIH-Start' },
    { 'label': 'Short Trip', value: 'Short Trip' },
    { 'label': 'Start POOH', value: 'Start POOH' },
    { 'label': 'Start ST', value: 'Start ST' }
  ];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject("IVendorJobClient") private _jobService: IVendorJobClient,
    private _workbookConfigurationService: IWorkbookConfigurationService,
    private _workbookLogService: IWorkbookLogService,
    private _titleService: Title
  ) {}

  ngOnInit() {
    this.getJobNumberFromRoute();
  }

  navigateToDashboard() {
    if (!this.jobNumber || this.jobNumber === 'New' || this.jobNumber === '') return;
    window.open(this.dashboardLink);
  }

  navigateToJobDetails() {
    if (!this.jobNumber || this.jobNumber === 'New' || this.jobNumber === '') return;
    const decodedJobId = decodeURIComponent(this.jobNumber);
    this._router.navigate(['/vendorjob/' + encodeURIComponent(decodedJobId)]);
  }

  getJobNumberFromRoute() {
    this._route.params.subscribe(params => {
      this.jobNumber = params['id'];
      if (this.jobNumber == null || this.jobNumber === '') {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Invalid JobNumber',
          detail: 'A valid JobNumber must be passed as Url arguement'
        });
        console.error('Invalid JobNumber');
      } else {
        this.getWorkbookConfiguration();
        this.getWorkbookLogData();
        this._jobService.getDashboardLink(this.jobNumber).subscribe(r => this.dashboardLink = r);
      }
      this.setPageTitle(this.jobNumber);
    });
  }

  setPageTitle(jobId: string) {
    if (!jobId || jobId === 'New' || jobId === '') {
      this._titleService.setTitle('New Job');
    } else { this._titleService.setTitle(jobId); }
  }

  getWorkbookConfiguration() {
    this._workbookConfigurationService.getWorkbookConfiguration(this.jobNumber).subscribe(
      result => {
        if (result == null) {
          // If no workbook configuration data set, use default
          this.workbookConfiguration = new WorkbookConfiguration(uuidv4(), this.jobNumber);
          this.workbookConfiguration.setAsDefaultConfiguration();
          this.updateWorkbookConfiguration();
        } else {
          this.workbookConfiguration = result;
        }
        this.workbookConfigurationReady = true;
        this.checkIfCanBuildWorkbook();
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get Workbook Configuration',
          detail: 'Workbook Configuration data for JobNumber: ' + this.jobNumber + ' could not be loaded.'
        });
        console.error(error);
      }
    );
  }

  getWorkbookLogData() {
    this._workbookLogService.getWorkbookLogs(this.jobNumber).subscribe(
      result => {
        this.workbookLogs = result;
        if (this.workbookLogs == null) { this.workbookLogs = []; }

        // make sure data is sorted by date descending
        this.workbookLogs.sort((a, b) => {
          if (a.time == null || b.time == null) { return 0; }
          if (a.time < b.time) { return -1; } else if (a.time > b.time) { return 1; } else { return 0; }
        });

        this.workbookLogs.forEach(log => {
          log.calendarTime = new Date(log.time);
        });

        this.workbookLogsReady = true;
        this.checkIfCanBuildWorkbook();
      },
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not get Workbook Logs',
          detail: 'Workbook Log data for JobNumber: ' + this.jobNumber + ' could not be loaded.'
        });
        console.error(error);
      }
    );
  }

  setScrollHeight(): string  {
    if (!this.workbookLogs || this.workbookLogs.length < 1) {
      return '0px';
    }

    if (this.workbookLogs.length < 10) {
      const scrollHeightNumber = Math.floor(this.workbookLogs.length / 2) * 100;
      if (scrollHeightNumber === 0) {
        return this.scrollHeight = '100px';
      }
      return (scrollHeightNumber).toString() + 'px';
    }

    return '500px';
  }

  checkIfCanBuildWorkbook() {
    if (this.workbookConfigurationReady && this.workbookLogsReady) {
      this.buildWorkbook();
    }
  }

  buildWorkbook() {
    this.frozenCols = [];
    this.scrollableCols = [];
    // Always show time and relative_time in that order
    this.frozenCols.push({ field: 'time', header: 'Time', display: 'table-cell' });
    // this.scrollableCols.push({ field: "time", header: "Time" });
    // The scrollable 'time' column will be hidden in table but used for export
    this.scrollableCols.push({ field: 'time', header: 'Timestamp', display: 'none' });
    this.scrollableCols.push({ field: 'relative_time', header: 'Hours', display: 'table-cell' });
    this.scrollableCols.push({ field: 'comments', header: 'Comments', display: 'table-cell' });
    this.scrollableCols.push({ field: 'plug_number', header: 'Plug #', display: 'table-cell' });
    this.scrollableCols.push({ field: 'activity', header: 'Activity', display: 'table-cell' });
    this.scrollableCols.push({ field: 'depth', header: 'Depth (ft)', display: 'table-cell' });
    this.scrollableCols.push({ field: 'funnel_vis', header: 'Funnel Visc (s)', display: 'table-cell' });
    this.scrollableCols.push({ field: 'fluid_system', header: 'Fluid System', display: 'table-cell' });
    this.scrollableCols.push({ field: 'swp_vol_bbls', header: 'SWP Vol (bbl)', display: 'table-cell' });
    this.scrollableCols.push({ field: 'circulating_psi', header: 'Circulating PSI', display: 'table-cell' });
    this.scrollableCols.push({ field: 'wh_psi', header: 'WH PSI', display: 'table-cell' });
    this.scrollableCols.push({ field: 'called_pumped_bpm', header: 'Pump Rate-Called (BPM)', display: 'table-cell' });
    this.scrollableCols.push({ field: 'called_fb_bpm', header: 'Return Rate-Called (BPM)', display: 'table-cell' });

    // Show additional Fields to display based on configuration
    this.workbookConfiguration.displayFields.forEach(fieldToDisplay => {
      // if(!this.cols.includes(fieldToDisplay)) {
      //   this.cols.push({ field: fieldToDisplay, header: fieldToDisplay });
      // }
      // Above doesn't work since default header columns have uppercase leading character
      if (fieldToDisplay !== 'time' && fieldToDisplay !== 'relative_time' && fieldToDisplay !== 'comments' &&
         fieldToDisplay !== 'plug_number' && fieldToDisplay !== 'activity' && fieldToDisplay !== 'depth' &&
         fieldToDisplay !== 'funnel_vis' && fieldToDisplay !== 'fluid_system' && fieldToDisplay !== 'swp_vol_bbls' &&
         fieldToDisplay !== 'circulating_psi' && fieldToDisplay !== 'wh_psi' && fieldToDisplay !== 'called_pumped_bpm' &&
         fieldToDisplay !== 'called_fb_bpm'
        ) {
        this.scrollableCols.push({ field: fieldToDisplay, header: fieldToDisplay, display: 'table-cell' });
      }
    });

    this.scrollHeight = this.setScrollHeight();

    // Add available fields to add to columns if they are not already displayed
    // (time, relative_time, and comments always shown.)
    this.availableFieldOptions = [];
    this.workbookConfiguration.availableFields.forEach(field => {
      if (
        field !== 'time' &&
        field !== 'relative_time' &&
        field !== 'comments' &&
        field !== 'plug_number' &&
        field !== 'activity' &&
        field !== 'depth' &&
        field !== 'funnel_vis' &&
        field !== 'fluid_system' &&
        field !== 'swp_vol_bbls' &&
        field !== 'circulating_psi' &&
        field !== 'wh_psi' &&
        field !== 'called_pumped_bpm' &&
        field !== 'called_fb_bpm' &&
        !this.workbookConfiguration.displayFields.includes(field)) {
          this.availableFieldOptions.push({ label: field, value: field });
        }
    });
    this.selectedAvailableField = null;

    this.createdField = null;
    this.createFieldIsText = false;
    this.workbookReady = true;
    this.checkIfEvertythingIsReady();
  }

  checkIfEvertythingIsReady() {
    this.everythingReady = this.workbookConfigurationReady && this.workbookLogsReady && this.workbookReady;
  }

  setWidth(col: any): string {
    const width = '75px';
    if (!col || !col.field) { return width; }

    // For some reason column width style applies to
    // column after the one being compared here???
    // if(col.field === "comments") return '250px';
    // if(col.field == "actvity") return '200px';
    if (col.field === 'relative_time') { return '215px'; }
    if (col.field === 'plug_number') { return '115px'; }

    return width;
  }

  openWorkbookConfiguration() {
    this.showWorkbookConfigurationDialog = true;
  }

  hideDisplayedField(fieldName: string) {
    this.workbookConfiguration.displayFields = this.workbookConfiguration.displayFields.filter(field => field !== fieldName);
    this.buildWorkbook();
    this.updateWorkbookConfiguration();
  }

  displayField() {
    if (
        this.selectedAvailableField != null &&
        this.selectedAvailableField !== '' &&
        !(this.workbookConfiguration.displayFields.includes(this.selectedAvailableField))
      ) {
          this.workbookConfiguration.displayFields.push(this.selectedAvailableField);
          this.buildWorkbook();
          this.updateWorkbookConfiguration();
    } else {
      this.messages = [];
      this.messages.push({
        severity: 'error',
        summary: 'Could not add ' + this.selectedAvailableField,
        detail: 'Field is either invalid or already displayed.'
      });
    }
  }

  createField() {
    if (
        this.createdField != null &&
        this.createdField !== '' &&
        !(this.workbookConfiguration.availableFields.includes(this.createdField))
      ) {
        this.workbookConfiguration.availableFields.push(this.createdField);
        this.workbookConfiguration.displayFields.push(this.createdField);
        this.workbookConfiguration.customFields.push(new Configuration(this.createdField, true, this.createFieldIsText));
        this.buildWorkbook();
        this.updateWorkbookConfiguration();
      } else {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not create ' + this.createdField,
          detail: 'Field is either invalid or already exists.'
        });
      }
  }

  updateWorkbookConfiguration() {
    this._workbookConfigurationService.updateWorkbookConfiguration(this.jobNumber, this.workbookConfiguration).subscribe(
      result => {},
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not save Workbook Configuration changes.',
          detail: ''
        });
        console.error(error);
      }
    );
  }

  // When updating after cell has onEditComplete event is fired
  tableDataChanged(event: any) {
    this.updateWorkbookLog(event.data);
    this.buildWorkbook();
  }

  // When updating using Save button, this is called when a cell's
  // onEditInit event is fired
  notifyChangesMade(event: any) {
    if (event || event.data) {
      if (!(this.changedClientIds.includes(event.data.client_id))) {
        this.changedClientIds.push(event.data.client_id);
      }
    }
    this.changesMade = true;
  }

  // For updating single workbook row
  updateWorkbookLog(workbookLog: any, targetRows: number= null) {
    this._workbookLogService.updateWorkBookLog(workbookLog.client_id, workbookLog).subscribe(
      result => {},
      error => {
        this.messages = [];
        this.messages.push({
          severity: 'error',
          summary: 'Could not save Workbook Log update.',
          detail: ''
        });
        console.error(error);
      },
      () => {
        if (targetRows) {
          this.savedRows = this.savedRows + 1;
          this.setSaveProgress(targetRows);
        }
      });
  }

  addWorkbookLogRow() {
    this.workbookLogs.push(this.createNewWorkbookLog());
    this.dataTable.reset();
    this.buildWorkbook();
  }

  createNewWorkbookLog(): any {
    const time = new Date(Date.now());
    const workbookLog = {
      'client_id' : uuidv4(),
      'job_number' : this.jobNumber,
      'time': time,
      'calendarTime': time,
      'relative_time': null // calculate here or done server side?
    };
    this.workbookConfiguration.availableFields.forEach(field => {
      if (field !== 'time' && field !== 'relative_time') {
        workbookLog[field] = null;
      }
    });
    return workbookLog;
  }

  // setInputDate(currentTime: string) {
  //   this.inputDate = new Date(currentTime);
  // }

  // setTime(clientId: string) {
  //   const workBookLogToUpdate = this.workbookLogs.find(wl => wl.client_id === clientId);
  //   if (workBookLogToUpdate != null) {
  //     workBookLogToUpdate.time = this.inputDate;
  //   }
  // }

  // Called from savebutton, updates every row that had a changed recorded
  saveWorkbookChanges() {
    if (this.changedClientIds) {
      const targetRows = this.changedClientIds.length;
      this.changedClientIds.forEach(clientId => {
        const workbookLog = this.workbookLogs.find(w => w.client_id === clientId);
        if (workbookLog) {
          workbookLog.time = workbookLog.calendarTime;
          this.updateWorkbookLog(workbookLog, targetRows);
        }
      });
    }
    this.changedClientIds = [];
    this.changesMade = false;
  }

  setSaveProgress(targetRows: number) {
    if (this.savedRows < targetRows) {
      this.showSavingProgress = true;
    } else {
      this.showSavingProgress = false;
      this.savedRows = 0;
    }
  }
}
