import { Component, Inject, inject, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { 
         ICompanyService,
         IUserService,
         IOpsViewerMessagesService,
         
         IApplicationContextService} from 'src/app/services/services';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { firstValueFrom } from 'rxjs';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { ConsumerJob, IConsumerJobClient, IVendorJobClient, User, VendorJob } from 'src/app/services/nswag/nswagclient';

@Component({
  selector: 'app-consumer-job',
  templateUrl: './consumer-job.component.html',
  styleUrls: ['./consumer-job.component.css']
})
export class ConsumerJobComponent implements OnInit {

  //#region Component Properties
  // UI State properties
  loading = true;
  saving = false;

  // Job Properties
  jobId: string;
  job: ConsumerJob;
  jobReady = false;
  vendorJobs: VendorJob[] = [];
  vendorJobsReady = false;

  // Company properties
  vendorCompanies: SelectItem[] = [];
  vendorCompaniesReady = false;
  consumerCompanies: SelectItem[] = [];
  consumerCompaniesReady = false;
  serviceProviderOptions: SelectItem[] = [];
  serviceProvidersReady = false;
  selectedVendorCompanyIds: string[] = [];

  // User properties
  user: User;
  isUserServiceProvider = false;
  userReady = false;
  securityToken: string;

  // Calendar control properties
  sandReportBeginTime: Date;
  sandReportEndTime: Date;
  dataSheetReportBeginTime: Date;
  dataSheetReportEndTime: Date;
  
  showManageFiles = false;

  //#endregion

  constructor(
    private _route: ActivatedRoute,
    @Inject("IConsumerJobClient") private _jobService: IConsumerJobClient,
    @Inject("IVendorJobClient") private _vendorJobService: IVendorJobClient,
    private _companyService: ICompanyService,
    private _userService: IUserService,
    private _applicationContextService: IApplicationContextService,
    private _messageService: IOpsViewerMessagesService,
    private _userManagerService: IUserManagerService,
    private _titleService: Title,
    private _currentUserService: ICurrentUserService
  ) { }

  ngOnInit() {
    this.getJobNameFromRoute();
    this.getAuthenticatedUser();
    this.getVendorCompanies();
    this.getConsumerCompanies();
    this.setSecurityToken();
  }
  setSecurityToken(){
    this._currentUserService.getAccessToken().subscribe(s=>{
      //console.warn(s);
      this.securityToken = s;
    });


  }
  //#region Initialization Methods

  getJobNameFromRoute() {
    this._route.params.subscribe(
      params => {
        this.jobId = params['id'];
        if (!this.jobId || this.jobId.toUpperCase() === 'NEW') {
          this._messageService.errorMessage(new Error('Consumer Job Id missing in route'), 'Could not get Operator Job Id from route');
        } else {
          this.getJob();
        }
        this.setPageTitle(this.jobId);
        // this._jobService.getDashboardLink(this.jobId).subscribe(r => this.dashboardLink = r);
      });
  }

  setPageTitle(jobId: string) {
    this._titleService.setTitle('Operator Job: ' + jobId);
  }

  getAuthenticatedUser() {

    this._userManagerService.getCurrentUser().subscribe(u=>{
      this.user = u;
      this.userReady = true;
      this.checkIfEverythingReady();
    });
  }
  // getAuthenticatedUser() {
  //   this._msalService.getUserEmail().subscribe(
  //     userEmail => {
  //       if (userEmail) {
  //         this._userService.getUserByEmail(userEmail).subscribe(
  //           userResult => {
  //             this.user = userResult;
  //             this.userReady = true;
  //             this.checkIfEverythingReady();
  //           },
  //           error => { this._messageService.errorMessage(error, 'Could not get user'); }
  //         );
  //       }
  //     },
  //     error => { this._messageService.errorMessage(error, 'Could not get user'); }
  //   );
  // }

  getVendorCompanies () {
    this._companyService.getVendorCompanyNames().subscribe(
      results => {
        results.map(r => {
          this.vendorCompanies.push({ label: r.companyName, value: r.companyId });
        });
        this.vendorCompaniesReady = true;
        this.checkIfEverythingReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not get Service Provider Companies'); }
    );
  }

  getConsumerCompanies () {
    this._companyService.getConsumerCompanyNames().subscribe(
      results => {
        results.map(r => {
          this.consumerCompanies.push({ label: r.companyName, value: r.companyId });
        });
        this.consumerCompaniesReady = true;
        this.checkIfEverythingReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not get Operator Companies'); }
    );
  }

  getJob() {
    this._jobService.getConsumerJobById(this.jobId).subscribe(
      result => {
        this.job = result;
        
        if (!this.job.vendorJobIds || this.job.vendorJobIds.length === 0) {
          this.vendorJobsReady = true;
          this._messageService.warnMessage ('No Vendor Jobs associated with this Consumer Job', '');
        } else {
          this.job.vendorJobIds.map(v => {
            this.getVendorJob(v);
          });
        }
        this.selectedVendorCompanyIds = this.job.vendorCompanyIds;
        this.jobReady = true;
        this.checkIfEverythingReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not Operator Job'); }
    );
  }

  getVendorJob(vendorJobId: string) {
    this._vendorJobService.getVendorJobById(vendorJobId).subscribe(
      result => {
        this.vendorJobs.push(result);
        this.checkIfAllVendorJobsReady();
      },
      error => { this._messageService.errorMessage(error, 'Could not get Service Provider Job'); }
    );
  }

  checkIfAllVendorJobsReady() {
    if (this.vendorJobs && (this.vendorJobs.length === this.job.vendorJobIds.length)) {
      this.vendorJobsReady = true;
      this.checkIfEverythingReady();
    }
  }

  checkIfEverythingReady() {
    const readyToSetJobProperties = this.jobReady &&
                                    this.vendorCompaniesReady &&
                                    this.consumerCompaniesReady &&
                                    this.userReady &&
                                    this.vendorJobsReady;
    if (readyToSetJobProperties) {
      this.setJobCompanyNames();
      this.loading = false;
    }
  }

  setJobCompanyNames() {
    const consumerCompany = this.consumerCompanies.find(c => c.value === this.job.consumerCompanyId);
    if (consumerCompany) {
      (this.job as any).consumerCompanyName = consumerCompany.label;
    }
    const vendorCompanyNames: string[] = [];
    this.job.vendorCompanyIds.map(v => {
      const vendorCompany = this.vendorCompanies.find(vc => vc.value === v);
      if (vendorCompany) {
        vendorCompanyNames.push(vendorCompany.label);
      }
    });
    (this.job as any).vendorCompanyNames = vendorCompanyNames.join(',');
  }

  //#endregion

  getVendorCompanyName(vendorJob: VendorJob) {
    if (vendorJob && vendorJob.companyId) {
      const company = this.vendorCompanies.find(c => c.value === vendorJob.companyId);
      if (company) {
        return company.label;
      }
    }
    return 'Undefined';
  }

  vendorCompanyIdsChanged() {
    const removedVendorCompanyIds: string[] = [];
    const addedVendorCompanyIds: string[] = [];

    this.selectedVendorCompanyIds.map(id => {
      if (!this.job.vendorCompanyIds.includes(id)) {
        addedVendorCompanyIds.push(id);
      }
    });
    this.job.vendorCompanyIds.map(id => {
      if (!this.selectedVendorCompanyIds.includes(id)) {
        removedVendorCompanyIds.push(id);
      }
    });

    addedVendorCompanyIds.map(id => {
     this.associateVendorCompany(id).then(success => {
       if (success) {
         // Add to job list
        this.job.vendorCompanyIds.push(id);
        // In case this is the first consumer job with this vendor company
        // the company list in the user profile needs to update
        this._applicationContextService.alertCompaniesListUpdated();
        } else {
          // Restore selected list
          // Due to change detection issue with PrimeNg Mutliselect, PrimeNg requires
          // setting value to new array instead of updating existing array value
          const tempSelectedIds = Array.from(this.selectedVendorCompanyIds.filter(s => s !== id));
          this.selectedVendorCompanyIds = tempSelectedIds;
        }
     });
    });

    removedVendorCompanyIds.map(id => {
      this.deassociateVendorCompany(id).then(success => {
        if (success) {
          // Removed from Job List
          this.job.vendorCompanyIds = this.job.vendorCompanyIds.filter(j => j !== id);
          // In case this is the only consumer job with this vendor company
          // the company list in the user profile needs to update
          this._applicationContextService.alertCompaniesListUpdated();
        } else {
          // Restore Selected list
          // Due to change detection issue with PrimeNg Mutliselect, PrimeNg requires
          // setting value to new array instead of updating existing array value
          const tempSelectedIds = Array.from(this.selectedVendorCompanyIds);
          tempSelectedIds.push(id);
          this.selectedVendorCompanyIds = tempSelectedIds;
        }
      });
    });
  }

  async associateVendorCompany(vendorCompanyId: string): Promise<boolean> {
    // let success = false;
    return await firstValueFrom(this._jobService.associateVendorToConsumerJob(this.jobId, vendorCompanyId))
      .then(() => true)
      .catch(error => {
        this._messageService.errorMessage(error, 'Could not Associate Vendor Company to Consumer Job');
        return false;
      });
  }

  async deassociateVendorCompany(vendorCompanyId: string): Promise<boolean> {
    return await firstValueFrom(this._jobService.deassociateVendorToConsumerJob(this.jobId, vendorCompanyId))
      .then(() => true)
      .catch(error => {
        this._messageService.errorMessage(error, 'Could not Deassociate Vendor Company to Consumer Job');
        return false;
      });
  }

  isAdmin(): boolean {
    return this._userService.isUserAdmin(this.user, this.job.consumerCompanyId, false);
  }

  isEditorOrAdmin(): boolean {
    return this._userService.isUserEditorOrAdmin(this.user, this.job.consumerCompanyId, false);
  }

  // TODO: How do these report endpoints change? Are they done per vednorJob?
  downloadJobExport() {
    window.open('/api/vendorjob/export/' + this.job.id + '?token=' + this.securityToken, '_blank');
  }

  downloadJobSummaryExport() {
    window.open('/api/vendorjob/exportsummary/' + this.job.id + '?token=' + this.securityToken, '_blank');
  }

  downloadSandReport() {
    const beginTime = moment(this.sandReportBeginTime).format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment(this.sandReportEndTime).format('YYYY-MM-DDTHH:mm:ss');
    window.open('/api/reports/sand/' +
    this.job.id +
    '?beginTime=' + beginTime +
    '&endTime=' + endTime +
    '&token=' + this.securityToken, '_blank');
  }

  downloadDataSheetReport() {
    const beginTime = moment(this.dataSheetReportBeginTime).format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment(this.dataSheetReportEndTime).format('YYYY-MM-DDTHH:mm:ss');
    window.open('/api/reports/datasheet/' +
    this.job.id +
    '?beginTime=' + beginTime +
    '&endTime=' + endTime +
    '&token=' + this.securityToken, '_blank');
  }

  navigateToDashboard() {
    if (this.job.isMigratedFromBeforeConsumerJobs) {
      if (this.job.vendorJobIds && this.job.vendorJobIds.length > 0) {
        this._vendorJobService.getDashboardLink(this.job.vendorJobIds[0])
        .subscribe(l =>  {
          window.open(l,"_blank");
        });
      } else {
        this._messageService.warnMessage('Cannot navigate to Consumer Job Dashboard', '');
      }
    } else {
      this._jobService.getDashboardLink(this.jobId)
      .subscribe(l =>  {
        window.open(l,"_blank");
      });
    }
  }
}
