import { SimulationActivity } from "src/app/models/simulation";

export class SimulationGraphs {
    
    /* 
    Actual Fields in the old simulation module:
        E-time (hr) (also: Runtime (hr))
        Depth (ft)

        E-time (hr)
        Pumped (BBLs)

        E-time (hr)
        Flowback (BBLs)
    */
    static buildBasicOptions(serie1Name: string, serie2Name: string, label_x: string, label_y: string, header:string) : any {
        return {width: 'auto', labels: [serie1Name,serie2Name], xlabel: label_x, ylabel: label_y, title: header, animatedZooms: true, pointSize: 4};
    }
    
    /* Assumes that the fields have been recalculated */
    static async buildGraphData(activities: SimulationActivity[] = [], mappingFun: (a: SimulationActivity) => [number, number]) : Promise<[number, number][]> {
        return activities.map(mappingFun);
    }

    static async buildDepthGraphData(activities: SimulationActivity[] = []) : Promise<[number, number, number][]> {
        let graphData: [number, number, number][] = []; // [RelativeTime, Depth, RE#]
        for(let i = 0; i < activities.length; i++) {
            if(activities[i].activity === 'Short Trip') {
                let stepRelTime = activities[i].calcRelativeTime - activities[i].duration / 2;
                graphData.push([stepRelTime, activities[i].calcDepth - activities[i].depthStart, activities[i].calcRe]);
                graphData.push([activities[i].calcRelativeTime, activities[i].calcDepth, activities[i].calcRe]);
            }
            else {
                if(activities[i].duration && activities[i].depthStart && activities[i].isDepthStart) {
                    graphData.push([activities[i].calcRelativeTime - activities[i].duration, activities[i].depthStart, activities[i].calcRe]);
                }
                graphData.push([activities[i].calcRelativeTime, activities[i].calcDepth, activities[i].calcRe]);
            }
        }
        return graphData;
    }

    static avDataMapping(activity: SimulationActivity) : [number, number] {
        return [activity.calcRelativeTime, activity.calcAv];
    }

    static flowbackBblsDataMapping(activity: SimulationActivity) : [number, number] {
        return [activity.calcRelativeTime, activity.calcFlowbackBbls];
    }

    static pumpedBblsDataMapping(activity: SimulationActivity) : [number, number] {
        return [activity.calcRelativeTime, activity.calcPumpedBbls];
    }
}