import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { 
         IOpsViewerMessagesService,
         IUserService,
         ICompanyService,
         IApplicationContextService } from 'src/app/services/services';
import { firstValueFrom, Subscription } from 'rxjs';
import { IUserManagerService } from 'src/app/services/iUserManager.service';
import { MsalService } from '@azure/msal-angular';
import { ICurrentUserService } from 'src/app/services/currentUserService/icurrentuser.service';
import { ConsumerJob, Device, DeviceClient, IConsumerJobClient, IDeviceClient, IVendorJobClient, User, VendorJob, VendorJobClient } from 'src/app/services/nswag/nswagclient';
import { JobCacheService } from 'src/app/services/job-cache.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilePopUpComponent } from '../../files/file-pop-up/file-pop-up.component';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-vendor-consumer-jobs',
  
  standalone: false,
  templateUrl: './vendor-consumer-jobs.component.html',
  styleUrls: ['./vendor-consumer-jobs.component.scss']
})
export class VendorConsumerJobsComponent implements OnInit, OnDestroy {

  vendorJobCols: any[] = [];
  loadingVendorJobs = false;
  vendorJobsReady = false;
  vendorJobs: VendorJob[] = [];
  vendorDevices: Device[] = [];

  consumerJobCols: any[] = [];
  consumerJobsReady = false;
  consumerJobs: ConsumerJob[] = [];

  vendorCompaniesReady = false;
  vendorCompanies: SelectItem[] = [];

  consumerCompaniesReady = false;
  consumerCompanies: SelectItem[] = [];

  userReady = false;
  user: User;
  userCompanyId: string;
  isUserCompanyVendor = false;
  isUserCompanyConsumer = false;

  vendorTableLoading = true;
  consumerTableLoading = true;

  securityToken: string;
  ref: DynamicDialogRef | undefined;
  private _companyContextSubscription: Subscription;

  constructor(
    @Inject("IVendorJobClient") private _vendorJobService: IVendorJobClient,
    @Inject("IConsumerJobClient") private _consumerJobService: IConsumerJobClient,
    private _userService: IUserService,
    private _companyService: ICompanyService,
    private _applicationContextService: IApplicationContextService,
    private _messageService: IOpsViewerMessagesService,
    private _userManagerService: IUserManagerService,
    private _titleService: Title,
    private _router: Router,
    private _confirmationService: ConfirmationService,
    //private _deviceService: IDeviceService,
    private _deviceClient: DeviceClient,
    private _currentUserService: ICurrentUserService,
    private _jobsCacheService : JobCacheService,
    private _dialogService: DialogService 
  ) { }

  ngOnInit() {
    this.listenToCompanyContext();
    this.setPageTitle();
    this.setColumns();
    this.getAuthenticatedUser();
    this.getVendorCompanies();
    this.getConsumerCompanies();
    // this.getVendorJobs();
    // this.getConsumerJobs();
    this.setSecurityToken();
  }
  setSecurityToken(){

    this._currentUserService.getAccessToken().subscribe(s=>{
      //console.warn(s);
      this.securityToken = s;
    });
    
  }

  ngOnDestroy() {
    this._applicationContextService.hideApplicationRequestsBar();
    this._companyContextSubscription.unsubscribe();
  }

  //#region Initialization Methods
  listenToCompanyContext() {
    this._companyContextSubscription = this._applicationContextService.companyContextObserver.subscribe(
      async () => 
        {
          console.info('Company context changed. Updating company id and the jobs.');
          await this.updateCompanyId();
        }
      );
  }

  setPageTitle() {
    this._titleService.setTitle('OpsViewer Jobs');
  }

  setColumns() {
    // TODO: Should Vendor Jobs show devices in a column?
    //       Should Consumer Jobs show Vendors in a column?
    this.vendorJobCols = [
      { field: 'name', header: 'Name', width: '160px', filterMatchMode: 'contains' },
      { field: 'apiNumber', header: 'Api Number', width: '160px', filterMatchMode: 'contains' },
      { field: 'wellInfo.name', header: 'Well Name', width: '180px', filterMatchMode: 'contains' },
      { field: 'consumerCompanyName', header: 'Operator', width: '180px', filterMatchMode: 'contains' },
      { field: 'startTime', header: 'Start', width: '120px' },
      { field: 'endTime', header: 'End', width: '120px' },
      { field: 'deviceNamesAsString', header: 'Devices', width: '180px', filterMatchMode: 'contains' },
      // tslint:disable-next-line:max-line-length
      // { field: 'devices', header: 'Devices', width: '150px', filterMatchMode: 'contains', alternativeFilterField: 'deviceNamesAsString' },
      { field: '', header: '', width: '175px' }
    ];

    this.consumerJobCols = [
      { field: 'number', header: 'Job Number', width: '160px', filterMatchMode: 'contains' },
      { field: 'wellApiNumber', header: 'Api Number', width: '160px', filterMatchMode: 'contains' },
      { field: 'wellName', header: 'Well Name', width: '200px', filterMatchMode: 'contains' },
      { field: 'vendorCompanyNames', header: 'Service Providers', width: '200px', filterMatchMode: 'contains' },
      { field: 'startTime', header: 'Start', width: '150px' },
      { field: 'endTime', header: 'End', width: '150px' },
      { field: '', header: '', width: '175px' }
    ];
  }

  getAuthenticatedUser() {

    this._userManagerService.getCurrentUser().subscribe(u=>{
      this.user = u;
      this.userReady = true;
      this.checkIfEverythingIsReady();
    });
  }


  getVendorCompanies () {
    this._companyService.getVendorCompanyNames().subscribe(
      async results => {
        results.map(r => {
          this.vendorCompanies.push({ label: r.companyName, value: r.companyId });
        });
        this.vendorCompaniesReady = true;
        await this.updateCompanyId(true); // In case companyId was set before this ran
      },
      error => { this._messageService.errorMessage(error, 'Could not get Service Provider Companies'); }
    );
  }

  getConsumerCompanies () {
    this._companyService.getConsumerCompanyNames().subscribe(
      async results => {
        results.map(r => {
          this.consumerCompanies.push({ label: r.companyName, value: r.companyId });
        });
        this.consumerCompaniesReady = true;
        await this.updateCompanyId(true); // In case companyId was set before this ran
      },
      error => { this._messageService.errorMessage(error, 'Could not get Operator Companies'); }
    );
  }

  async updateCompanyId(forceUpdate = false) {
    this.isUserCompanyVendor = false;
    this.isUserCompanyConsumer = false;
    const company = this._applicationContextService.getCompanyContext();
    if (company) {
      if (company.companyId !== this.userCompanyId || forceUpdate) {
        this.userCompanyId = company.companyId;
        if (this.vendorCompanies) {
          if (this.vendorCompanies.find(v => v.value === this.userCompanyId)) {
            this.isUserCompanyVendor = true;
            if (!this.loadingVendorJobs) {
              this.loadingVendorJobs = true;
              this.vendorDevices = await this.getVendorDevices();
              this.vendorJobs = await this.getVendorJobs(company.companyId); //TODO: this is getting called twice. Need to rewrite this whole component with better rxjs use
              this.vendorJobsReady = true;
              this.checkIfVendorTableReady();
            }
          }
        }
        if (this.consumerCompanies) {
          if (this.consumerCompanies.find(c => c.value === this.userCompanyId)) {
            this.isUserCompanyConsumer = true;
            this.getConsumerJobs();
          }
        }
      }
    }
    if (!this.isUserCompanyVendor && !this.isUserCompanyConsumer) {
      this._applicationContextService.showApplicationRequestsBar();
    } else {
      this._applicationContextService.hideApplicationRequestsBar();
    }
  }




  async getVendorJobs(userCompanyId:string): Promise<VendorJob[]> {
    const servicefoundVendorJobs = await this._jobsCacheService.getVendorJobsForVendorCompany(userCompanyId);
    const foundVendorJobs = servicefoundVendorJobs.map(j=>VendorJob.fromJS(j));
    if (foundVendorJobs) {
      foundVendorJobs.map(j => { //TODO:map should not be changing the object
        const company = this.consumerCompanies.find(c => c.value === j.consumerCompanyId);
        if (company) {
          (j as any).consumerCompanyName = company.label;
        }
        (j as any).deviceNamesAsString = this.getDeviceNames(j.deviceList);
      });
      foundVendorJobs.sort((a, b) => {
        if (a.startTime > b.startTime) {
          return -1;
        } else if (a.startTime < b.startTime) {
          return 1;
        }
        return 0;
      });
    }
    return foundVendorJobs;
  }

  async getConsumerJobs() {

    let cachedJobs = await this._jobsCacheService.getConsumerJobs();
    const results = cachedJobs.map(j=>Object.assign(new ConsumerJob(),j) as ConsumerJob);
    

    // this._consumerJobService.getJobs().subscribe(
    //   results => {
        this.consumerJobs = results;
        this.consumerJobs.map(j => {
          const company = this.consumerCompanies.find(c => c.value === j.consumerCompanyId);
          if (company) {
            (j as any).consumerCompanyName = company.label;
          }
          // Get VendorCompany Names
          if (j.vendorCompanyIds) {
            const foundVendorCompanies = this.vendorCompanies.filter(v => j.vendorCompanyIds.includes(v.value));
            if (foundVendorCompanies) {
              (j as any).vendorCompanyNames = foundVendorCompanies.map(v => v.label).join(',');
            }
          }
        });
        this.consumerJobs = this.consumerJobs.filter(j =>
          j.consumerCompanyId === this.userCompanyId || j.vendorCompanyIds.includes(this.userCompanyId));
          this.consumerJobs.sort((a, b) => {
            if (a.startTime > b.startTime) {
              return -1;
            } else if (a.startTime < b.startTime) {
              return 1;
            }
            return 0;
          });
        this.consumerJobsReady = true;
        this.checkIfConsumerTableReady();
    //   },
    //   error => { this._messageService.errorMessage(error, 'Could not get Operator Jobs'); }
    // );
  }

  getVendorDevices(): Promise<Device[]> {
    return firstValueFrom(this._deviceClient.getDevicesForCompanyIncludingDeleted(this.userCompanyId));
  }

  getDeviceNames(deviceIds: string[]): string {
    let deviceNames = '';
    if (!deviceIds) {
      return deviceNames;
    }
    deviceIds.map(id => {
      const foundDeviceName = this.vendorDevices.find(d => d.id === id);
      if (foundDeviceName) {
        deviceNames = deviceNames.length > 0 ? deviceNames.concat(', ' + foundDeviceName.name) : deviceNames.concat(foundDeviceName.name);
      }
    });
    return deviceNames;
  }

  checkIfVendorTableReady() {
    const ready = this.vendorJobsReady && this.vendorCompaniesReady && this.userReady;
    
    this.vendorTableLoading = !ready;
  }

  checkIfConsumerTableReady() {
    this.consumerTableLoading = !(this.consumerJobsReady && this.consumerCompaniesReady && this.userReady);
  }

  checkIfEverythingIsReady() {
    this.checkIfVendorTableReady();
    this.checkIfConsumerTableReady();
  }

  isCompanyTypeNotDefined(): boolean {
    return !this.isUserCompanyVendor && !this.isUserCompanyConsumer && this.vendorCompaniesReady && this.consumerCompaniesReady;
  }

  //#endregion

  //#region Navigation and Download Methods

  navigateToAddVendorJob() {
    this._router.navigate(['/vendorjob/' + encodeURIComponent('new')]);
  }

  navigateToVendorDetail(jobId: string) {
    this._router.navigate(['/vendorjob/' + encodeURIComponent(jobId)]);
  }

  navigateToConsumerDetail(jobId: string) {
    this._router.navigate(['/consumerjob/' + encodeURIComponent(jobId)]);
  }

  navigateToVendorWorkbook(job: VendorJob) {
    if (job.useOldWorkbookInReadOnlyMode) {
      this._router.navigate(['/workbook/' + job.id]);
    } else {
      this._router.navigate(['/workbook2/' + job.id]);
    }
  }

  // navigateToVendorDashboard(jobId: string) {
  //   const newwindow = window.open();
  //   this._vendorJobService.getDashboardLink(jobId).subscribe(l => newwindow.location.href = l);
  // }

  navigateToVendorDashboard(jobId: string) {
   
    this._vendorJobService.getDashboardLink(jobId)
        .subscribe(l =>  {
          window.open(l,"_blank");
        });
  }

  navigateToConsumerDashboard(job: ConsumerJob) {
    if (job.isMigratedFromBeforeConsumerJobs) {
      if (job.vendorJobIds && job.vendorJobIds.length > 0) {
        this._vendorJobService.getDashboardLink(job.vendorJobIds[0])
        .subscribe(l =>  {
          window.open(l,"_blank");
        });;
      } else {
        this._messageService.warnMessage('Cannot navigate to Consumer Job Dashboard', '');
      }
    } else {
      this._consumerJobService.getDashboardLink(job.id)
      .subscribe(l =>  {
        window.open(l,"_blank");
      });;
    }
  }

  downloadJobExport(jobId: string) {
    window.open('/api/vendorjob/export/' + jobId + '?token=' + this.securityToken, '_blank');
  }

  openFilePopUp(jobId: string) {
    this.ref = this._dialogService.open(FilePopUpComponent, { 
      data: {
          jobId: jobId
      },
      header: 'Manage Files',
      width: '50vw',
      baseZIndex: 10000,
      resizable:true,
      maximizable: true
  });
  }

  downloadJobSummaryExport(jobId: string) {
    window.open('/api/vendorjob/exportsummary/' + jobId + '?token=' + this.securityToken, '_blank');
  }

  //#endregion

  //#region Deleting Jobs

  async confirmDeleteVendorJob(job: VendorJob): Promise<void> {
    if (this.isSuperAdmin()) {
      if (await this.doesJobHaveData(job.id)) {
        this.confirmDeleteVendorJobWithData(job);
      } else {
        this.confirmDeleteVendorJobWithoutData(job);
      }
    } else {
      this.confirmDeleteVendorJobWithoutData(job);
    }
  }

  confirmDeleteVendorJobWithoutData(job: VendorJob) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete ' + job.name + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteVendorJob(job.id); }// ,
        // reject: () => { }
      }
    );
  }

  confirmDeleteVendorJobWithData(job: VendorJob) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'This job has data associated with it, are you sure you want to delete ' + job.name + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteVendorJob(job.id); }// ,
        // reject: () => { }
      }
    );
  }

  confirmDeleteConsumerJob(job: ConsumerJob) {
    this._confirmationService.confirm(
      {
        key: 'confirmationDialog',
        header: 'Delete Confirmation',
        message: 'Are you sure you want to delete job on well ' + job.wellApiNumber + '?',
        icon: 'fa fa-question-circle',
        accept: () => { this.deleteConsumerJob(job.id); }// ,
        // reject: () => { }
      }
    );
  }

  deleteVendorJob(jobId: string) {
    this.showSpinner();
    this._vendorJobService.deleteVendorJob(jobId).subscribe(
      result => {
        this.hideSpinner();
        this.vendorJobs = this.vendorJobs.filter(j => j.id !== jobId);
        this._messageService.infoMessage('Service Provider Job deleted', '');
      },
      error => {
        this.hideSpinner();
        this._messageService.errorMessage(error, 'Unable to Delete Service Proivder Job');
      }
    );
  }

  deleteConsumerJob(jobId: string) {
    this.showSpinner();
    this._consumerJobService.deleteConsumerJob(jobId).subscribe(
      result => {
        this.hideSpinner();
        this.consumerJobs = this.consumerJobs.filter(j => j.id !== jobId);
        this._messageService.infoMessage('Operator Job deleted', '');
        // In case this is the only consumer job with a vendor company
        // the company list in the user profile needs to update
        this._applicationContextService.alertCompaniesListUpdated();
      },
      error => {
        this.hideSpinner();
        this._messageService.errorMessage(error, 'Unable to Delete Operator Job');
      }
    );
  }

  //#endregion

  //#region Exports

  //#endregion

  //#region *********** Other Helper methods ***********/
  isAdmin(jobCompanyId: string): boolean {
    return this._userService.isUserAdmin(this.user, jobCompanyId, false);
  }

  isEditorOrAdmin(jobCompanyId: string): boolean {
    return this._userService.isUserEditorOrAdmin(this.user, jobCompanyId, false);
  }

  isSuperAdmin(): boolean {
    return this.user.isGlobalAdmin;
  }

  async doesJobHaveData(jobId: string): Promise<boolean> {
    return firstValueFrom(this._vendorJobService.doesCompanyHaveData(jobId));
  }

  //#endregion

  showSpinner() {
    this._applicationContextService.showApplicationSpinner(true);
  }

  hideSpinner() {
    this._applicationContextService.hideApplicationSpinner();
  }
}
