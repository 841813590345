import { Inject, Injectable } from '@angular/core';
import { IBootstrapWorkbookForOfflineService } from './iBootstrapWorkbookForOffline.service';
import { ICompanyWorkbookConfigurationService } from './iCompanyWorkbookConfiguration.service';
import { IUserService } from './iUser.service';
import { Observable, empty } from 'rxjs';
import { IOpsViewerMessagesService } from './iOpsViewerMessages.service';
import { IVendorJobClient } from './nswag/nswagclient';

@Injectable()
export class BootstrapWorkbookForOfflineService extends IBootstrapWorkbookForOfflineService {

  private static storedJobIds: string[] = [];

  constructor(
    @Inject("IVendorJobClient") private jobService: IVendorJobClient,
    private companyWorkbookConfigurationService: ICompanyWorkbookConfigurationService,
    private userService: IUserService,
    private messageService: IOpsViewerMessagesService) {
      super();
    }

    public isCacheWorkbookDetailServicesRequired(jobId: string): Boolean {
      if (!BootstrapWorkbookForOfflineService.storedJobIds.includes(jobId)) {
        BootstrapWorkbookForOfflineService.storedJobIds.push(jobId);
        return true;
      }
      return false;
    }

    public cacheWorkbookDetailServices(jobId: string): Observable<any> {
      // The following needs to be cached when loading the workbook table since
      // these calls are made from the workbook-detail and the user is only required
      // to load the Workbook for a Job to allow for that workbook to be useable offline.
      //  1. Job (so user can navigate to Job Details if needed)
      //  2. Workbook Activities
      //  3. CompanyId from JobId (done in workbook already)
      //  4. CompanyConfiguration (done in workbook already)
      //  5. Supervisors List for Job Details
      if (this.isCacheWorkbookDetailServicesRequired(jobId)) {
        return new Observable<any>(observer => {
          let jobReady = false;
          let activitiesReady = false;
          let usersReady = false;

          this.jobService.getVendorJobById(jobId).subscribe(
            () => {
              jobReady = true;
              if (this.checkIfCacheWorkbookDetailIsComplete(jobReady, activitiesReady, usersReady)) {
                observer.next();
                observer.complete();
              }
            },
            error => {
              this.messageService.errorNoUiMessage(error, 'Could not sync Job for Bootstrapping Workbook for Offline');
            }
          );

          this.companyWorkbookConfigurationService.getActivities().subscribe(
            () => {
              activitiesReady = true;
              if (this.checkIfCacheWorkbookDetailIsComplete(jobReady, activitiesReady, usersReady)) {
                observer.next();
                observer.complete();
              }
            },
            error => {
              this.messageService.errorNoUiMessage(error,
                'Could not sync Activities for Bootstrapping Workbook for Offline');
            }
          );

          this.userService.getUsers().subscribe(
            () => {
              usersReady = true;
              if (this.checkIfCacheWorkbookDetailIsComplete(jobReady, activitiesReady, usersReady)) {
                observer.next();
                observer.complete();
              }
            },
            error => {
              this.messageService.errorNoUiMessage(error, 'Could not sync Users for Bootstrapping Workbook for Offline');
            }
         );
        });
      } else {
        return empty();
      }
    }

    private checkIfCacheWorkbookDetailIsComplete(jobReady: boolean, activitiesReady: boolean, usersReady: boolean): boolean {
      return jobReady && activitiesReady && usersReady;
    }
}
